import Devises from '@/views/config/devise/pages/Devise';

export default [
  {
    path: '/config/devise',
    name: 'config-devise',
    component: Devises,
    meta: {
      auth: true,
    },
  },
];
