<template>
  <div class="auth-wrapper d-flex flex-column justify-center">
    <v-row>
        <v-col cols="12" lg="4" offset-lg="8" class="auth-bg d-flex align-center justify-center">
          <div class="auth-inner pa-4">
            <v-card class="auth-card">
              <!-- logo -->
              <v-card-title class="d-flex align-center justify-center py-7">
                <router-link to="/" class="d-flex align-center">
                  <v-img
                    :src="`${URL_FILE}/images/logo.png`"
                    alt="Logo"
                    max-height="140px"
                    max-width="250px"
                    contain
                    class="me-3"
                  ></v-img>
                </router-link>
              </v-card-title>

              <!-- title -->
              <v-card-text>
              <p class="mb-3">
                <h5 class="text-h6 font-weight-semibold mb-1">
                  {{ $t('login.intro') }} {{ commonConfig.getTitle() }}! 👋🏻
                </h5>
              </p>
              <p class="mb-4 pt-2">
                {{ $t('login.description') }}
              </p>
            </v-card-text>

            <form @submit.prevent="onSubmit" @keydown="form.onKeydown($event)">
              <v-card-text>
                <v-text-field
                  v-model="form.email"
                  :label="$t('login.form.email')"
                  type="text"
                  outlined
                  dense
                  placeholder="john.doe@example.com"
                  :error-messages="form.errors.get('email')"
                  class="mb-5"
                  autofocus
                  autocomplete="off"
                  aria-autocomplete="off"
                ></v-text-field>

                <v-text-field
                  v-model="form.password"
                  outlined
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :label="$t('login.form.password')"
                  placeholder="············"
                  :append-icon="
                    isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline
                  "
                  dense
                  @click:append="isPasswordVisible = !isPasswordVisible"
                  :error-messages="form.errors.get('password')"
                  autocomplete="off"
                  aria-autocomplete="off"
                ></v-text-field>

                <div class="d-flex justify-end flex-wrap mt-4 mb-10">
                  <router-link
                    to="/forget-password"
                  >
                  {{ $t('login.forgetPassword') }}
                  </router-link>
                </div>

                <ButtonLoader
                  :busy="form.busy"
                  type="submit"
                  block
                  :normal-text="$t('login.btnConnexion')"
                  :loader-text="$t('common.btnLoaderText')"
                  color="primary"
                  class="mt-10"
                ></ButtonLoader>
              </v-card-text>
            </form>
          </v-card>
          </div>
        </v-col>
      </v-row>
  </div>
</template>

<script>
import { useAuthStore } from '@/store'
import { useCommon } from '@/utils/Common'
import { mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'

export default {
  setup() {
    const store = useAuthStore()
    const router = useRouter()
    const route = useRoute()
    const commonConfig = useCommon()

    const form = store.form
    form.email = null
    form.password = null
    form.reset()
    form.clear()

    const isPasswordVisible = ref(false)

    const onSubmit = () => {
      store
        .login()
        .then(() => {
          router.push(route.query.redirectFrom || { name: 'dashboard' })
        })
        .catch(() => {})
    }

    return {
      title: 'login.pageTitre',
      commonConfig,
      URL_FILE: process.env.VUE_APP_URL_FILE,
      isPasswordVisible,
      form,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      onSubmit,
    }
  },
  metaInfo() {
    return {
      title: this.$t(this.title),
      titleTemplate: `${this.commonConfig.getTitle()} | %s`,
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
