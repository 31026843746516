import "@fortawesome/fontawesome-free/css/all.css";
import "@mdi/font/css/materialdesignicons.css";
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import preset from './default-preset/preset';

Vue.use(Vuetify)

export default new Vuetify({
  preset,
  icons: {
    iconfont: "fa" || "md",
  },
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
  },
})
