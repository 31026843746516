
import Create from '@/views/config/user/pages/Create.vue';
import Edit from '@/views/config/user/pages/Edit.vue';
import List from "@/views/config/user/pages/List.vue";
import Show from '@/views/config/user/pages/Show.vue';

export default [
  {
    path: '/config/user',
    name: 'config-user',
    component: List,
    meta: {
      auth: true,
    },
  },
  {
    path: '/config/user/create',
    name: 'config-user-create',
    component: Create,
    meta: {
      auth: true,
    },
  },
  {
    path: '/config/user/edit/:id',
    name: 'config-user-edit',
    component: Edit,
    meta: {
      auth: true,
    },
  },
  {
    path: '/config/user/show/:id',
    name: 'config-user-show',
    component: Show,
    meta: {
      auth: true,
    },
  },
];
