<template>
  <div>
    <VRow>
      <VCol cols="12" md="3" sm="6" class="d-flex flex-column align-self-end">
        <VCard class="overflow-visible">
          <VCardTitle class="card-title"> Total des ventes de tickets journalier </VCardTitle>
          <VDivider class="mx-0" />
          <VCardText>
            <div class="d-flex justify-center align-center">
              <h1 class="text-4xl font-weight-semibold">$24,895</h1>

              <!-- <div class="d-flex align-center mb-n3">
                <v-icon size="40" color="success"> mdi-menu-up </v-icon>
                <span class="text-base font-weight-medium success--text ms-n2">10%</span>
              </div> -->
            </div>

            <!-- <h4 class="mt-2 font-weight-medium">Compared to $84,325 last year</h4> -->
          </VCardText>
          <div class="d-flex justify-end">
            <v-card-actions>
              <v-btn small outlined color="primary"> Voir la liste </v-btn>
            </v-card-actions>
          </div>
        </VCard>
      </VCol>

      <VCol cols="12" md="3" sm="6" class="d-flex flex-column align-self-end">
        <VCard class="overflow-visible">
          <VCardTitle class="card-title"> Nombre des réservations journalier </VCardTitle>
          <VDivider class="mx-0" />
          <VCardText>
            <div class="d-flex justify-center align-center">
              <h1 class="text-4xl font-weight-semibold">200</h1>

              <!-- <div class="d-flex align-center mb-n3">
                <v-icon size="40" color="success"> mdi-menu-up </v-icon>
                <span class="text-base font-weight-medium success--text ms-n2">10%</span>
              </div> -->
            </div>

            <!-- <h4 class="mt-2 font-weight-medium">Compared to $84,325 last year</h4> -->
          </VCardText>
          <div class="d-flex justify-end">
            <v-card-actions>
              <v-btn small outlined color="primary"> Voir la liste </v-btn>
            </v-card-actions>
          </div>
        </VCard>
      </VCol>

      <VCol cols="12" md="3" sm="6" class="d-flex flex-column align-self-end">
        <VCard class="overflow-visible">
          <VCardTitle class="card-title"> Nombre des voyages journalier </VCardTitle>
          <VDivider class="mx-0" />
          <VCardText>
            <div class="d-flex justify-center align-center">
              <h1 class="text-4xl font-weight-semibold">7</h1>

              <!-- <div class="d-flex align-center mb-n3">
                <v-icon size="40" color="success"> mdi-menu-up </v-icon>
                <span class="text-base font-weight-medium success--text ms-n2">10%</span>
              </div> -->
            </div>

            <!-- <h4 class="mt-2 font-weight-medium">Compared to $84,325 last year</h4> -->
          </VCardText>
          <div class="d-flex justify-end">
            <v-card-actions>
              <v-btn small outlined color="primary"> Voir la liste </v-btn>
            </v-card-actions>
          </div>
        </VCard>
      </VCol>

      <VCol cols="12" md="3" sm="6" class="d-flex flex-column align-self-end">
        <VCard class="overflow-visible">
          <VCardTitle class="card-title"> Nombre de passager journalier </VCardTitle>
          <VDivider class="mx-0" />
          <VCardText>
            <div class="d-flex justify-center align-center">
              <h1 class="text-4xl font-weight-semibold">400</h1>

              <!-- <div class="d-flex align-center mb-n3">
                <v-icon size="40" color="success"> mdi-menu-up </v-icon>
                <span class="text-base font-weight-medium success--text ms-n2">10%</span>
              </div> -->
            </div>

            <!-- <h4 class="mt-2 font-weight-medium">Compared to $84,325 last year</h4> -->
          </VCardText>
          <div class="d-flex justify-end">
            <v-card-actions>
              <v-btn small outlined color="primary"> Voir la liste </v-btn>
            </v-card-actions>
          </div>
        </VCard>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="12" md="6" sm="12" class="d-flex flex-column align-self-end">
        <VCard class="overflow-visible">
          <VCardTitle class="card-title"> Etat des ventes journalier de tickets / véhicule </VCardTitle>
          <VDivider class="mx-0" />
          <VCardText>
            <apexchart type="pie" height="380" :options="chartOptions" :series="series"></apexchart>
          </VCardText>
          <div class="d-flex justify-end">
            <v-card-actions>
              <v-btn small outlined color="primary"> Exporter </v-btn>
            </v-card-actions>
          </div>
        </VCard>
      </VCol>
      <VCol cols="12" md="6" sm="12" class="d-flex flex-column align-self-end">
        <VCard class="overflow-visible">
          <VCardTitle class="card-title"> Etat des réservations journalière / véhicule </VCardTitle>
          <VDivider class="mx-0" />
          <VCardText>
            <apexchart type="pie" height="380" :options="chartOptions" :series="series"></apexchart>
          </VCardText>
          <div class="d-flex justify-end">
            <v-card-actions>
              <v-btn small outlined color="primary"> Exporter </v-btn>
            </v-card-actions>
          </div>
        </VCard>
      </VCol>
      <VCol cols="12" md="6" sm="12" class="d-flex flex-column align-self-end">
        <VCard class="overflow-visible">
          <VCardTitle class="card-title"> Etat d'occupation journalier / véhicule </VCardTitle>
          <VDivider class="mx-0" />
          <VCardText>
            <apexchart type="pie" height="380" :options="chartOptions" :series="series"></apexchart>
          </VCardText>
          <div class="d-flex justify-end">
            <v-card-actions>
              <v-btn small outlined color="primary"> Exporter </v-btn>
            </v-card-actions>
          </div>
        </VCard>
      </VCol>
      <VCol cols="12" md="6" sm="12" class="d-flex flex-column align-self-end">
        <VCard class="overflow-visible">
          <VCardTitle class="card-title"> Rentabilité hebdomadaire / trajet </VCardTitle>
          <VDivider class="mx-0" />
          <VCardText>
            <apexchart type="pie" height="380" :options="chartOptions" :series="series"></apexchart>
          </VCardText>
          <div class="d-flex justify-end">
            <v-card-actions>
              <v-btn small outlined color="primary"> Exporter </v-btn>
            </v-card-actions>
          </div>
        </VCard>
      </VCol>
    </VRow>
    <!-- <v-row>
      <v-col cols="12" md="4">
        <dashboard-congratulation-john></dashboard-congratulation-john>
      </v-col>
      <v-col cols="12" md="8">
        <dashboard-statistics-card></dashboard-statistics-card>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <dashboard-weekly-overview></dashboard-weekly-overview>
      </v-col>

      <v-col cols="12" md="4" sm="6">
        <dashboard-card-total-earning></dashboard-card-total-earning>
      </v-col>

      <v-col cols="12" md="4">
        <v-row class="match-height">
          <v-col cols="12" sm="6">
            <statistics-card-vertical
              :change="totalProfit.change"
              :color="totalProfit.color"
              :icon="totalProfit.icon"
              :statistics="totalProfit.statistics"
              :stat-title="totalProfit.statTitle"
              :subtitle="totalProfit.subtitle"
            ></statistics-card-vertical>
          </v-col>
          <v-col cols="12" sm="6">
            <statistics-card-vertical
              :change="totalSales.change"
              :color="totalSales.color"
              :icon="totalSales.icon"
              :statistics="totalSales.statistics"
              :stat-title="totalSales.statTitle"
              :subtitle="totalSales.subtitle"
            ></statistics-card-vertical>
          </v-col>
          <v-col cols="12" sm="6">
            <statistics-card-vertical
              :change="newProject.change"
              :color="newProject.color"
              :icon="newProject.icon"
              :statistics="newProject.statistics"
              :stat-title="newProject.statTitle"
              :subtitle="newProject.subtitle"
            ></statistics-card-vertical>
          </v-col>

          <v-col cols="12" sm="6">
            <statistics-card-vertical
              :change="salesQueries.change"
              :color="salesQueries.color"
              :icon="salesQueries.icon"
              :statistics="salesQueries.statistics"
              :stat-title="salesQueries.statTitle"
              :subtitle="salesQueries.subtitle"
            ></statistics-card-vertical>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="4">
        <dashboard-card-sales-by-countries></dashboard-card-sales-by-countries>
      </v-col>
      <v-col cols="12" md="8">
        <dashboard-card-deposit-and-withdraw></dashboard-card-deposit-and-withdraw>
      </v-col>
      <v-col cols="12">
        <dashboard-datatable></dashboard-datatable>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'
import { mdiCurrencyUsd, mdiHelpCircleOutline, mdiLabelVariantOutline, mdiPoll } from '@mdi/js'

// demos
import DashboardCardDepositAndWithdraw from './DashboardCardDepositAndWithdraw.vue'
import DashboardCardSalesByCountries from './DashboardCardSalesByCountries.vue'
import DashboardCardTotalEarning from './DashboardCardTotalEarning.vue'
import DashboardCongratulationJohn from './DashboardCongratulationJohn.vue'
import DashboardDatatable from './DashboardDatatable.vue'
import DashboardStatisticsCard from './DashboardStatisticsCard.vue'
import DashboardWeeklyOverview from './DashboardWeeklyOverview.vue'

export default {
  components: {
    StatisticsCardVertical,
    DashboardCongratulationJohn,
    DashboardStatisticsCard,
    DashboardCardTotalEarning,
    DashboardCardDepositAndWithdraw,
    DashboardCardSalesByCountries,
    DashboardWeeklyOverview,
    DashboardDatatable,
  },
  setup() {
    const totalProfit = {
      statTitle: 'Total Profit',
      icon: mdiPoll,
      color: 'success',
      subtitle: 'Weekly Project',
      statistics: '$25.6k',
      change: '+42%',
    }

    const series = [44, 55, 13, 43, 22]
    const chartOptions = {
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    }
    const totalSales = {
      statTitle: 'Refunds',
      icon: mdiCurrencyUsd,
      color: 'secondary',
      subtitle: 'Past Month',
      statistics: '$78',
      change: '-15%',
    }

    // vertical card options
    const newProject = {
      statTitle: 'New Project',
      icon: mdiLabelVariantOutline,
      color: 'primary',
      subtitle: 'Yearly Project',
      statistics: '862',
      change: '-18%',
    }

    const salesQueries = {
      statTitle: 'Sales Quries',
      icon: mdiHelpCircleOutline,
      color: 'warning',
      subtitle: 'Last week',
      statistics: '15',
      change: '-18%',
    }

    return {
      totalProfit,
      totalSales,
      newProject,
      salesQueries,
      series,
      chartOptions,
    }
  },
}
</script>
<style lang="scss">
.card {
  background-color: rgba(255, 255, 255, 0.6) !important;
  border-color: white !important;
}
.card-title {
  background: white;
}
</style>
