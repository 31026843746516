<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="isDialogVisible" persistent max-width="1000px">
        <form @submit.prevent="onSubmit" @keydown="form.onKeydown($event)">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ titleDialog }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" lg="6">
                  <v-autocomplete
                    v-if="mode !== 'show'"
                    v-model="form.agence"
                    :items="store.agences"
                    item-text="nom"
                    item-value="uuid"
                    :label="$t('config.paiement_mode.form.agence') + ' *'"
                    :error-messages="form.errors.get('agence')"
                    :no-data-text="$t('common.autocompleteSearchNoResult')"
                    clearable
                  ></v-autocomplete>
                  <v-text-field
                    v-else
                    type="text"
                    v-model="form.agence"
                    :label="$t('config.paiement_mode.form.agence') + ' *'"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" lg="6">
                  <v-text-field
                    type="text"
                    v-model="form.nom"
                    :error-messages="form.errors.get('nom')"
                    :label="$t('config.paiement_mode.form.nom') + ' *'"
                    :counter="mode !== 'show'"
                    maxlength="50"
                    :readonly="mode === 'show'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" lg="6">
                  <v-switch
                    v-model="form.statut"
                    :label="form.statut ? $t('common.switch_statut_actif') : $t('common.switch_statut_inactif')"
                    class="custom-select"
                    inset
                    :readonly="mode === 'show'"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="isDialogVisible = false">
                {{ $t('modal.btnFermer') }}
              </v-btn>

              <v-btn color="primary" class="mr-n2 ml-4" @click="onSubmit" v-if="mode !== 'show'">
                {{ mode === 'create' ? $t('modal.btnCreer') : $t('modal.btnEnregistrer') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import { useConfigPaiementModeStore } from '@/store'
import { useSwalAlerte } from '@/utils'
import { ref } from 'vue'

export default {
  setup() {
    const store = useConfigPaiementModeStore()
    const alerte = useSwalAlerte()

    const form = store.form
    form.reset()
    form.clear()
    const isDialogVisible = ref(false)
    const titleDialog = ref()
    const mode = ref('create')

    const create = () => {
      mode.value = 'create'
      titleDialog.value = i18n.t('config.paiement_mode.titreModalCreation')
      form.reset()
      form.clear()
      store.requirements().then(() => {
        isDialogVisible.value = true
      })
    }

    const show = id => {
      titleDialog.value = i18n.t('config.paiement_mode.titreModalApercu')
      form.reset()
      form.clear()
      mode.value = 'show'
      store
        .get(id)
        .then(() => {
          isDialogVisible.value = true
        })
        .catch(() => {})
    }

    const edit = id => {
      titleDialog.value = i18n.t('config.paiement_mode.titreModalModification')
      form.reset()
      form.clear()
      mode.value = 'edit'
      store
        .get(id, { mode: 'edit' })
        .then(() => {
          isDialogVisible.value = true
        })
        .catch(() => {})
    }

    const onSubmit = () => {
      isDialogVisible.value = false

      if (form.id) {
        store
          .update()
          .then(resp => {
            onSuccess(resp)
          })
          .catch(() => {
            isDialogVisible.value = true
          })
      } else {
        store
          .store()
          .then(resp => {
            onSuccess(resp)
          })
          .catch(() => {
            isDialogVisible.value = true
          })
      }
    }

    const onSuccess = resp => {
      alerte.showSimpleAlerte({ icon: 'success', text: resp.data.message })
      form.reset()
      form.clear()
      store.getData({ clearSearch: true })
    }

    return {
      form,
      store,
      mode,
      titleDialog,
      isDialogVisible,
      onSubmit,
      create,
      show,
      edit,
    }
  },
}
</script>
