import EmbarquementDescente from '@/views/config/embarquement-descente/pages/EmbarquementDescente';

export default [
  {
    path: '/config/point-embarquement-descente',
    name: 'config-embarquement-descente',
    component: EmbarquementDescente,
    meta: {
      auth: true,
    },
  },
];
