<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="isDialogVisible" persistent max-width="700px">
        <v-form @submit.prevent="onValidate">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ $t('config.vehicule_type.modalSeatTitle') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="d-flex justify-center">
                <v-col cols="12" md="12" lg="6">
                  <v-text-field
                    ref="input"
                    type="text"
                    v-model="seatNumber"
                    :label="$t('config.vehicule_type.form.nom') + ' *'"
                    maxlength="2"
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="isDialogVisible = false">
                {{ $t('modal.btnFermer') }}
              </v-btn>

              <v-btn color="primary" class="mr-n2 ml-4" @click="onValidate">
                {{ $t('modal.btnEnregistrer') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  setup(_props, { emit }) {
    const seatNumber = ref(null)
    const isDialogVisible = ref(false)

    const show = number => {
      seatNumber.value = number
      isDialogVisible.value = true
    }

    const onValidate = () => {
      emit('on-save-number', seatNumber.value)
      isDialogVisible.value = false
    }

    return {
      onValidate,
      seatNumber,
      isDialogVisible,
      show,
    }
  },
}
</script>
