/** Layouts */
import EditLayout from '@/views/voyage/layouts/EditLayout.vue';
import ShowLayout from '@/views/voyage/layouts/ShowLayout.vue';

import Create from '@/views/sous-voyage/pages/Create.vue';
import Edit from '@/views/sous-voyage/pages/Edit.vue';
import List from "@/views/sous-voyage/pages/List.vue";
import Show from '@/views/sous-voyage/pages/Show.vue';


export default [
  {
    path: '/voyage/edit/:id/sous-voyage',
    component: EditLayout,
    children: [
      {
        path: '',
        name: 'voyage-edit-sous-voyage',
        component: List,
        meta: {
          auth: true,
        },
      },
      {
        path: 'create',
        name: 'voyage-edit-sous-voyage_create',
        component: Create,
        meta: {
          auth: true,
        },
      },
      {
        path: 'edit/:sousVoyageId',
        name: 'voyage-edit-sous-voyage_edit',
        component: Edit,
        meta: {
          auth: true,
        },
      },
      {
        path: 'show/:sousVoyageId',
        name: 'voyage-edit-sous-voyage_show',
        component: Show,
        meta: {
          auth: true,
        },
      },
    ]
  },
  {
    path: '/voyage/show/:id/sous-voyage',
    component: ShowLayout,
    children: [
      {
        path: '',
        name: 'voyage-show-sous-voyage',
        component: List,
        meta: {
          auth: true,
        },
      },
      {
        path: 'create',
        name: 'voyage-show-sous-voyage_create',
        component: Create,
        meta: {
          auth: true,
        },
      },
      {
        path: 'edit/:sousVoyageId',
        name: 'voyage-show-sous-voyage_edit',
        component: Edit,
        meta: {
          auth: true,
        },
      },
      {
        path: 'show/:sousVoyageId',
        name: 'voyage-show-sous-voyage_show',
        component: Show,
        meta: {
          auth: true,
        },
      },
    ]
  }
];
