import '@/plugins/axios';

import { useInitApp } from '@/store';
import '@/styles/styles.scss';
import { createPinia, PiniaVuePlugin } from 'pinia';
import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';

import BreadCrumd from '@/components/BreadCrumd.vue';
import ButtonLoader from '@/components/ButtonLoader.vue';

import Blank from "@/layouts/Blank";
import Default from "@/layouts/Default";

import i18n from '@/plugins/i18n';
import VueApexCharts from 'vue-apexcharts';
import VueMeta from 'vue-meta';
import VueSweetalert2 from 'vue-sweetalert2';

Vue.config.productionTip = true

Vue.use(VueMeta);
Vue.use(VueSweetalert2);

window.Swal = Vue.prototype.$swal

Vue.component("default-layout", Default);
Vue.component("blank-layout", Blank);
Vue.component('ButtonLoader', ButtonLoader);
Vue.component('BreadCrumd', BreadCrumd);
Vue.component('apexchart', VueApexCharts);

Vue.use(PiniaVuePlugin)
Vue.use(createPinia())

const store = useInitApp()
store.initConfig().then(() => {
  store.refreshToken().then(() => { }).catch(() => { }).finally(() => {
    new Vue({
      router,
      i18n,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  })
})

