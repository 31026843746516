<template>
  <div>
    <v-row>
      <v-col cols="12" class="mb-n2">
        <BreadCrumd :breadCrumbs="breadCrumbs" :title="$t('reservation.liste.breadTitre')" />
      </v-col>
      <v-col cols="12" class="text-right">
        <v-btn color="primary" @click.prevent="router.push({ name: 'reservation-create' }).catch(() => {})">
          <v-icon class="mr-1"> mdi-magnify </v-icon>
          {{ $t('reservation.btnRecherche') }}
        </v-btn>

        <v-btn
          color="error"
          class="ml-4"
          v-if="store.rowsSelected.length"
          @click="onDelete(store.rowsSelected.map(rolw => rolw.id))"
        >
          <v-icon left> mdi-delete </v-icon>
          {{ $t('common.btnSuppMass') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-4">
        <DataTable
          ref="datatable"
          @open-modal-statut="openModalStatut"
          @on-delete="onDelete"
          @on-paiement="openModalPaiement"
        />
      </v-col>
    </v-row>
    <ModalStatut ref="modalStatut" @on-success="store.getData({ clearSearch: true })" />
    <ModalPaiement ref="modalPaiement" @on-success="store.getData({ clearSearch: true })" />
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import { useReservationStore } from '@/store'
import { useCommon, useSwalAlerte } from '@/utils'
import { ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import ModalStatut from '../../../components/ModalReservationStatut.vue'
import DataTable from '../components/DataTable.vue'
import ModalPaiement from '../components/ModalPaiement.vue'

export default {
  setup() {
    const store = useReservationStore()
    const commonConfig = useCommon()
    const alerte = useSwalAlerte()
    const router = useRouter()
    const modalStatut = ref()
    const modalPaiement = ref()

    const onDelete = async ids => {
      if (ids.length > 0) {
        const result = await alerte.showConfirm({})
        if (result.value) {
          await store
            .delete(ids)
            .then(resp => {
              alerte.showSimpleAlerte({ icon: 'success', text: resp.data.message })
              store.getData({ clearSearch: true })
            })
            .catch(() => {})
        }
      }
    }

    const openModalPaiement = id => {
      modalPaiement.value.create(id)
    }

    const openModalStatut = (id, currentStatutCode) => {
      modalStatut.value.openModal(id, currentStatutCode)
    }

    return {
      store,
      router,
      title: 'reservation.liste.pageTitre',
      commonConfig,
      modalStatut,
      modalPaiement,
      openModalStatut,
      openModalPaiement,
      breadCrumbs: [
        {
          text: i18n.t('bread.dashboard'),
          disabled: false,
          href: '/',
        },
        {
          text: i18n.t('reservation.liste.breadActif'),
          disabled: true,
        },
      ],
      onDelete,
    }
  },
  metaInfo() {
    return {
      title: this.$t(this.title),
      titleTemplate: `${this.commonConfig.getTitle()} | %s`,
    }
  },
  components: {
    DataTable,
    ModalStatut,
    ModalPaiement,
  },
}
</script>
