import ConfigAgence from "./agence";
import ConfigCivilite from "./civilite";
import ConfigDestination from "./destination";
import ConfigDevise from "./devise";
import ConfigEmbarquementDescente from "./embarquement-descente";
import ConfigPaiementMode from "./paiement-mode";
import ConfigPays from "./pays";
import ConfigPaysIndicatif from "./pays-indicatif";
import ConfigSeat from "./seat";
import ConfigSituationFamiliale from "./situation-familiale";
import ConfigUser from "./user";
import ConfigVehiculeType from "./vehicule-type";

export default [
  ...ConfigDevise,
  ...ConfigPays,
  ...ConfigDestination,
  ...ConfigPaysIndicatif,
  ...ConfigCivilite,
  ...ConfigAgence,
  ...ConfigEmbarquementDescente,
  ...ConfigUser,
  ...ConfigSituationFamiliale,
  ...ConfigSeat,
  ...ConfigVehiculeType,
  ...ConfigPaiementMode
];
