<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <span class="text-h5"> {{ $t('config.vehicule_type.apercu.panelTitre') }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-row class="d-flex justify-lg-center">
                <v-col cols="12" md="6" lg="3">
                  <v-text-field
                    type="text"
                    v-model="form.nom"
                    :label="$t('config.vehicule_type.form.nom') + ' *'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="2">
                  <v-switch
                    v-model="form.statut"
                    :label="form.statut ? $t('common.switch_statut_actif') : $t('common.switch_statut_inactif')"
                    class="custom-select"
                    inset
                  ></v-switch>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card no-body>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div class="d-flex justify-center align-center" id="svg-container">
                  <div v-if="store.svgContent" v-html="store.svgContent" class="svg-content" />
                  <div v-else>
                    <p class="text-h4 error--text">{{ $t('config.vehicule_type.noSeletedFileMsg') }}</p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useConfigVehiculeTypeStore } from '@/store'

export default {
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useConfigVehiculeTypeStore()
    const form = store.form

    return {
      store,
      form,
    }
  },
}
</script>
