var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.store.columns,"items":_vm.store.items,"server-items-length":_vm.store.total,"options":_vm.store.options,"single-select":_vm.store.singleSelect,"show-select":"","footer-props":{
      showFirstLastPage: true,
      itemsPerPageOptions: _vm.store.pageSizes,
    },"fixed-header":""},on:{"update:options":function($event){return _vm.$set(_vm.store, "options", $event)}},scopedSlots:_vm._u([{key:`item.statut`,fn:function({ item }){return [_c('a',{staticClass:"cursor-pointer",on:{"click":function($event){$event.preventDefault();return _vm.onUpdateStatut(item.id, item.statut.code)}}},[_c('v-icon',{attrs:{"color":item.statut.couleur}},[_vm._v(" mdi-circle ")]),_vm._v(" "+_vm._s(item.statut.nom)+" ")],1)]}},{key:`item.nbre_paiement`,fn:function({ item }){return [_c('v-icon',{attrs:{"color":_vm.commonConfig.getStatutClass(item.statut)}},[_vm._v(" mdi-circle ")]),_vm._v(" "+_vm._s(item.nbre_paiement > 0 ? `${_vm.$t('common.paiement_statut_ok')}` : `${_vm.$t('common.paiement_statut_non_ok')}`)+" ")]}},{key:`item.nbre_place`,fn:function({ item }){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.nbre_place)+" ")])]}},{key:`item.prix`,fn:function({ item }){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.currencyFormtter.formatCurrency(item.prix, item.devise))+" ")])]}},{key:`item.montant`,fn:function({ item }){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.currencyFormtter.formatCurrency(item.montant, item.devise))+" ")])]}},{key:`item.id`,fn:function({ item }){return [_c('router-link',{attrs:{"title":_vm.$t('common.table.tooltipApercu'),"to":{
          name: 'reservation-show',
          params: { id: item.id },
        }}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-eye ")])],1),(item.statut.code !== _vm.CONSTANT.RESERVATION_CONFIRME && item.nbre_paiement === 0)?_c('router-link',{staticClass:"ml-4",attrs:{"title":_vm.$t('common.table.tooltipModifier'),"to":{
          name: 'reservation-edit',
          params: { id: item.id },
        }}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-clipboard-edit-outline ")])],1):_vm._e(),_c('a',{staticClass:"ml-4",attrs:{"title":_vm.$t('common.table.tooltipImprimerTicket'),"href":`${_vm.store.URL_PRINT_TICKET}/${item.id}`,"target":"_blank","rel":"noopener noreferrer"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" fa-solid fa-cash-register ")])],1),(item.nbre_paiement === 0)?_c('a',{staticClass:"ml-4 cursor-pointer",attrs:{"title":_vm.$t('common.table.tooltipPaiement')},on:{"click":function($event){$event.preventDefault();return _vm.onPaiement(item.id)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" fa-regular fa-money-bill-1 ")])],1):_vm._e(),(item.statut.code !== _vm.CONSTANT.RESERVATION_CONFIRME && item.nbre_paiement === 0)?_c('a',{staticClass:"ml-4 cursor-pointer",attrs:{"title":_vm.$t('common.table.tooltipSupprimer')},on:{"click":function($event){$event.preventDefault();return _vm.onDelete(item.id)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-delete ")])],1):_vm._e()]}},{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"shrink mx-4 mt-4",attrs:{"append-icon":"md-magnify","clearable":"","label":_vm.$t('common.recherche'),"single-line":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.store.getData({ clearSearch: false })}},model:{value:(_vm.store.search),callback:function ($$v) {_vm.$set(_vm.store, "search", $$v)},expression:"store.search"}}),_c('v-divider',{staticClass:"mt-0 mb-0"})]},proxy:true}],null,true),model:{value:(_vm.store.rowsSelected),callback:function ($$v) {_vm.$set(_vm.store, "rowsSelected", $$v)},expression:"store.rowsSelected"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }