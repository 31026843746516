<template>
  <v-app>
    <v-data-table
      :headers="store.columns"
      :items="store.items"
      :server-items-length="store.total"
      :options.sync="store.options"
      v-model="store.rowsSelected"
      :single-select="store.singleSelect"
      show-select
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions: store.pageSizes,
      }"
      class="elevation-1"
      fixed-header
    >
      <template v-slot:[`item.statut`]="{ item }">
        <v-icon :color="commonConfig.getStatutClass(item.statut)"> mdi-circle </v-icon>
        {{ $t('common.table.statut_' + commonConfig.getStatutTxt(item.statut)) }}
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <a :title="$t('common.table.tooltipApercu')" class="cursor-pointer mr-4" @click.prevent="onShow(item.id)">
          <v-icon color="primary"> mdi-eye </v-icon>
        </a>

        <a :title="$t('common.table.tooltipModifier')" class="cursor-pointer mr-4" @click.prevent="onEdit(item.id)">
          <v-icon color="secondary2"> mdi-clipboard-edit-outline </v-icon>
        </a>

        <a :title="$t('common.table.tooltipSupprimer')" class="cursor-pointer" @click.prevent="onDelete(item.id)">
          <v-icon color="error"> mdi-delete </v-icon>
        </a>
      </template>
      <template v-slot:top>
        <v-text-field
          v-model="store.search"
          append-icon="md-magnify"
          clearable
          :label="$t('common.recherche')"
          single-line
          class="shrink mx-4 mt-4"
          @keyup.enter="store.getData({ clearSearch: false })"
        ></v-text-field>

        <v-divider class="mt-0 mb-0"></v-divider>
      </template>
    </v-data-table>
  </v-app>
</template>
<script>
import { useConfigDestinationStore } from '@/store'
import { useCommon } from '@/utils'
import { onMounted, watch } from 'vue'

export default {
  setup(_props, { emit }) {
    const store = useConfigDestinationStore()
    const commonConfig = useCommon()

    watch(
      () => store.options,
      (_currentValue, _oldValue) => {
        store.getData({ clearSearch: false })
      },
    )

    const onShow = id => {
      emit('on-show', id)
    }
    const onEdit = id => {
      emit('on-edit', id)
    }
    const onDelete = id => {
      emit('on-delete', [id])
    }

    onMounted(() => {
      store.getData({ clearSearch: true })
    })

    return {
      store,
      commonConfig,
      onShow,
      onEdit,
      onDelete,
    }
  },
}
</script>
