<template>
  <v-container>
    <v-row v-for="rowIndex in rows" :key="rowIndex">
      <v-col v-for="colIndex in cols" :key="colIndex">
        <Seat
          :seatNumber="getSeatNumber(rowIndex, colIndex)"
          :status="getSeatStatus(rowIndex, colIndex)"
          @seat-selected="selectSeat"
          @seat-deselected="deselectSeat"
          @seat-number-updated="updateSeatNumber"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { onMounted, ref } from 'vue'
import Seat from '../components/Seat.vue'

export default {
  components: {
    Seat,
  },
  setup() {
    const rows = ref(5)
    const cols = ref(6)
    const selectedSeats = ref([])
    const seats = ref([])

    const generateSeats = () => {
      for (let row = 1; row <= rows.value; row++) {
        for (let col = 1; col <= cols.value; col++) {
          seats.value.push({
            seatNumber: getSeatNumber(row, col),
            status: 'available',
          })
        }
      }
    }

    const getSeatNumber = (row, col) => {
      return row * 100 + col
    }

    const getSeatStatus = (row, col) => {
      const seatNumber = getSeatNumber(row, col)
      const selected = selectedSeats.value.includes(seatNumber)
      return selected ? 'selected' : 'available'
    }

    const selectSeat = seatNumber => {
      selectedSeats.value.push(seatNumber)
      updateSeatStatus(seatNumber, 'selected')
    }

    const deselectSeat = seatNumber => {
      selectedSeats.value = selectedSeats.value.filter(selectedSeat => selectedSeat !== seatNumber)
      updateSeatStatus(seatNumber, 'available')
    }

    const updateSeatStatus = (seatNumber, status) => {
      const seatIndex = seats.value.findIndex(seat => seat.seatNumber === seatNumber)
      seats.value[seatIndex].status = status
    }

    const updateSeatNumber = (oldSeatNumber, newSeatNumber) => {
      const seatIndex = seats.value.findIndex(seat => seat.seatNumber === oldSeatNumber)
      if (seatIndex !== -1) {
        seats.value[seatIndex].seatNumber = newSeatNumber
      }
    }

    onMounted(() => {
      generateSeats()
    })

    return {
      rows,
      cols,
      selectedSeats,
      seats,
      getSeatNumber,
      getSeatStatus,
      selectSeat,
      deselectSeat,
      updateSeatStatus,
      updateSeatNumber,
    }
  },
}
</script>
