import URL_CONFIG from '@/http/app-config'
import URL_AUTH from '@/http/auth'

import { useAuthStore } from './auth.store'

import { defineStore } from 'pinia'
import { reactive } from 'vue'

export const useInitApp = defineStore({
  id: 'initApp',
  state: () => ({
    config: reactive(
      {
        app_name: 'KODAX APP',
        app_developer: 'KODAX',
        app_version: '1.0',
        devise: null,
      }
    ),
  }),

  actions: {
    async initConfig() {
      try {
        const resp = await window.axios.get(URL_CONFIG.CONFIG)
        Object.assign(this.config, resp.data.config)

        return resp

      } catch (err) {
      }
    },

    async refreshToken() {
      const authStore = useAuthStore()
      try {
        const resp = await window.axios.get(URL_AUTH.REFRESH_TOKEN)

        authStore.isAuthenticated = true
        authStore.user = resp.data.user
        authStore.token = resp.data.token

        return resp

      } catch (err) {
        authStore.isAuthenticated = false
        authStore.user = null
        authStore.token = null
        throw err
      }
    },
  }
})
