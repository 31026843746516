<template>
  <div>
    <v-row v-if="form.errors.has('sous_trajet_error_message')">
      <v-col cols="12">
        <v-card>
          <v-alert dismissible dense outlined shaped border="left" color="error" elevation="2">
            <p>{{ form.errors.get('sous_trajet_error_message') }}</p>
            <template #close>
              <v-icon size="35" color="error" role="button" aria-hidden="true" @click="clearFormError">
                mdi-close-octagon
              </v-icon>
            </template>
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5">
              {{ edit ? $t('sous_trajet.modification.panelTitre') : $t('sous_trajet.creation.panelTitre') }}</span
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" md="6" lg="3">
                    <v-autocomplete
                      v-model="form.depart"
                      :items="store.departs"
                      item-text="nom"
                      item-value="uuid"
                      :label="$t('trajet.form.depart') + ' *'"
                      :error-messages="form.errors.get('depart')"
                      :no-data-text="$t('common.autocompleteSearchNoResult')"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-autocomplete
                      v-model="form.arrivee"
                      :items="store.arrivees"
                      item-text="nom"
                      item-value="uuid"
                      :label="$t('trajet.form.arrivee') + ' *'"
                      :error-messages="form.errors.get('arrivee')"
                      :no-data-text="$t('common.autocompleteSearchNoResult')"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.distance"
                      :error-messages="form.errors.get('distance')"
                      :label="$t('trajet.form.distance') + ' *'"
                      counter
                      maxlength="15"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.duree"
                      :error-messages="form.errors.get('duree')"
                      :label="$t('trajet.form.duree') + ' *'"
                      counter
                      maxlength="15"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.prix"
                      :error-messages="form.errors.get('prix')"
                      :label="$t('trajet.form.prix') + ' *'"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5"> {{ $t('trajet.embarquementPanelTitre') }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            <v-autocomplete
              v-model="form.embarquements"
              :items="store.embarquements"
              item-text="nom"
              item-value="uuid"
              outlined
              dense
              chips
              :label="$t('trajet.form.point_embarquement') + ' *'"
              :error-messages="form.errors.get('embarquements')"
              multiple
              :no-data-text="$t('common.autocompleteSearchNoResult')"
              clearable
              deletable-chips
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5"> {{ $t('trajet.descentePanelTitre') }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            <v-autocomplete
              v-model="form.descentes"
              :items="store.descentes"
              item-text="nom"
              item-value="uuid"
              outlined
              dense
              chips
              :label="$t('trajet.form.point_descente') + ' *'"
              :error-messages="form.errors.get('descentes')"
              multiple
              :no-data-text="$t('common.autocompleteSearchNoResult')"
              clearable
              deletable-chips
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useSousTrajetStore } from '@/store'
import { watch } from 'vue'

export default {
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useSousTrajetStore()
    const form = store.form

    watch(
      () => store.form.depart,
      (currentValue, _oldValue) => {
        store.onChangeDepart(currentValue)
      },
      { immediate: true },
    )

    watch(
      () => store.form.arrivee,
      (currentValue, _oldValue) => {
        store.onChangeArrivee(currentValue)
      },
      { immediate: true },
    )

    const clearFormError = () => {
      const errors = form.errors
      errors.clear('sous_trajet_error_message')
    }

    return {
      store,
      form,
      clearFormError,
    }
  },
}
</script>
