<template>
  <div v-if="form.id">
    <v-row>
      <v-col cols="12" class="mb-n2">
        <BreadCrumd :breadCrumbs="breadCrumbs" :title="$t('trajet.modification.breadTitre')" />
      </v-col>
    </v-row>
    <v-row class="mt-4">
      <v-col cols="12">
        <v-tabs id="custom-tab">
          <v-tab
            :to="{
              name: 'trajet-edit',
              params: { id: route.params.id },
            }"
            exact
          >
            <v-icon>mdi-clipboard-edit-outline</v-icon>
            {{ $t('trajet.tabs.trajet') }}
          </v-tab>
          <v-tab
            :to="{
              name: 'trajet-edit-sous-trajet',
              params: { id: route.params.id },
            }"
          >
            <v-icon>mdi-cube-outline</v-icon>
            {{ $t('trajet.tabs.sousTrajet') }}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <router-view />
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import { useTrajetStore } from '@/store'
import { useCommon } from '@/utils'
import { onMounted } from 'vue'
import { useRoute } from 'vue-router/composables'

export default {
  setup() {
    const store = useTrajetStore()
    const commonConfig = useCommon()
    const route = useRoute()

    const form = store.form
    form.reset()
    form.clear()

    onMounted(() => {
      store.get(route.params.id, { mode: 'edit' })
    })

    return {
      title: 'trajet.modification.pageTitre',
      commonConfig,
      route,
      form,
      breadCrumbs: [
        {
          text: i18n.t('bread.dashboard'),
          disabled: false,
          href: '/',
        },
        {
          text: i18n.t('trajet.modification.breadActif'),
          disabled: true,
        },
      ],
    }
  },
  metaInfo() {
    return {
      title: this.$t(this.title),
      titleTemplate: `${this.commonConfig.getTitle()} | %s`,
    }
  },
}
</script>
