
import Create from '@/views/config/seat/pages/Create.vue';
import Edit from '@/views/config/seat/pages/Edit.vue';
import List from "@/views/config/seat/pages/List.vue";
import Show from '@/views/config/seat/pages/Show.vue';

export default [
  {
    path: '/config/seat',
    name: 'config-seat',
    component: List,
    meta: {
      auth: true,
    },
  },
  {
    path: '/config/seat/create',
    name: 'config-seat-create',
    component: Create,
    meta: {
      auth: true,
    },
  },
  {
    path: '/config/seat/edit/:id',
    name: 'config-seat-edit',
    component: Edit,
    meta: {
      auth: true,
    },
  },
  {
    path: '/config/seat/show/:id',
    name: 'config-seat-show',
    component: Show,
    meta: {
      auth: true,
    },
  },
];
