import URL from '@/http/config/vehicule-type';
import i18n from '@/plugins/i18n';
import { useCommon } from '@/utils';
import { objectToFormData } from "object-to-formdata";
import { defineStore } from 'pinia';
import Form from 'vform';
import { reactive, ref } from 'vue';
const commonConfig = useCommon()

export const useConfigVehiculeTypeStore = defineStore({
  id: 'configVehiculeType',
  state: () => ({
    form: reactive(
      new Form({
        nom: null,
        fichier: null,
        file_content: null,
        statut: true,
      })
    ),
    columns: [
      {
        text: i18n.t("config.vehicule_type.table.nom"),
        align: "start",
        sortable: true,
        value: "nom",
      },
      {
        text: i18n.t("config.vehicule_type.table.nbre_place"),
        align: "start",
        sortable: false,
        value: "nbre_place",
      },
      {
        text: i18n.t("common.table.statut"),
        sortable: false,
        value: "statut",
      },
      {
        text: i18n.t("common.table.action"),
        align: "center",
        sortable: false,
        value: "id",
      },
    ],
    svgContent: ref(null),
    search: '',
    items: ref([]),
    total: 0,
    singleSelect: false,
    rowsSelected: ref([]),
    pageSizes: commonConfig.defaultPageSizes,
    options: {
      itemsPerPage: commonConfig.defaultPageSize,
      page: 1,
      sortBy: ['id'],
      sortDesc: [true],
    },
  }),

  actions: {
    getData({ clearSearch = false }) {
      if (clearSearch) {
        this.search = null
      }

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.rowsSelected = []
      this.items = []

      window.axios.get(URL.VEHICULE_TYPE, {
        params: {
          search: this.search,
          sortBy: sortBy.length > 0 ? sortBy[0] : 'id',
          sortDesc: sortDesc.length > 0 ? sortDesc[0] : true,
          page: page,
          perPage: itemsPerPage,
        },
      })
        .then(resp => {
          this.items = resp.data.data
          this.total = itemsPerPage === -1 ? resp.data.length : resp.data.meta.total
        })
        .catch(() => { })
    },

    get(id) {
      window.axios.get(`${URL.VEHICULE_TYPE}/${id}`).then((resp) => {
        Object.assign(this.form, resp.data.type)
        this.svgContent = resp.data.type.file_content
      })
        .catch((_err) => { })
    },

    async store() {
      return await this.form.post(URL.VEHICULE_TYPE, {
        transformRequest: [
          data => objectToFormData(data),
        ],
      })
    },

    async update() {
      return await this.form.put(`${URL.VEHICULE_TYPE}/${this.form.id}`, {
        transformRequest: [
          data => objectToFormData(data),
        ],
      })
    },

    async delete(ids) {
      return await window.axios.delete(URL.VEHICULE_TYPE, {
        params: {
          ids: ids,
        },
      })
    },
  }
})
