<template>
  <div>
    <loading
      :active.sync="loaderStore.isLoading"
      :can-cancel="loaderStore.canCancel"
      :is-full-page="loaderStore.fullPage"
      :color="loaderStore.color"
      :width="loaderStore.width"
      :height="loaderStore.height"
    />

    <component :is="resolveLayout">
      <router-view :key="$route.path" />
    </component>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useRoute } from 'vue-router/composables'
import { useLoaderStore } from '@/store'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  components: {
    Loading,
  },
  setup() {
    const defaultLayout = 'default'
    const route = useRoute()
    const loaderStore = useLoaderStore()

    const resolveLayout = computed(() => {
      return (route.meta.layout || defaultLayout) + '-layout'
    })

    return {
      resolveLayout,
      loaderStore,
      route,
    }
  },
}
</script>
