<template>
  <v-menu
    v-model="date_menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="computedDateFormatted"
        :label="label"
        clearable
        @click:clear="onClear"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker locale="fr" v-model="dateValue" @input="dateHandler" />
  </v-menu>
</template>

<script>
import { useDateFormatter } from '@/utils'
import moment from 'moment'
import { computed, ref } from 'vue'
export default {
  props: {
    date: {
      type: String | null,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const date_menu = ref(false)
    const dateValue = ref(props.date)
    const formatter = useDateFormatter()

    const onClear = () => {
      dateValue.value = null
      emit('update:value', dateValue.value)
    }

    const dateHandler = date => {
      date_menu.value = false
      if (typeof date === 'string') date = moment(date).format('YYYY-MM-DD')
      dateValue.value = date
      emit('update:value', dateValue.value)
    }

    const computedDateFormatted = computed(() => {
      return formatter.formatDate(dateValue.value, 'DD/MM/YYYY')
    })

    return {
      date_menu,
      dateValue,
      computedDateFormatted,
      dateHandler,
      onClear,
    }
  },
}
</script>
