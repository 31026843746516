import i18n from '../plugins/i18n'
import CONSTANT from './Constant'

export const useSwalAlerte = () => {

  const showSimpleAlerte = ({ position = 'top-end', icon, text }) => {
    Swal({
      position: position,
      icon: icon,
      text: text,
      showConfirmButton: false,
      timer: window.swalDefaultTimer,
    })
  }

  const showConfirm = (
    {
      title = i18n.t('common.showConfirm.title'),
      icon = 'warning',
      message = i18n.t('common.showConfirm.message'),
      confirmButtonText = i18n.t('common.showConfirm.confirmButtonText'),
      cancelButtonText = i18n.t('common.showConfirm.cancelButtonText')
    }) => {
    return Swal({
      icon: icon,
      title: title,
      text: message,
      showCancelButton: true,
      allowOutsideClick: false,
      reverseButtons: true,
      cancelButtonColor: '#d33',
      confirmButtonColor: CONSTANT.CUSTOM_PRIMARY_COLOR,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
    })
  }

  return {
    showSimpleAlerte,
    showConfirm,
  }
}
