import URL from '@/http/chauffeur'
import i18n from '@/plugins/i18n'
import { useCommon } from '@/utils'
import { defineStore } from 'pinia'
import Form from 'vform'
import { reactive, ref } from 'vue'
const commonConfig = useCommon()

export const useChauffeurStore = defineStore({
  id: 'chauffeur',
  state: () => ({
    form: reactive(
      new Form({
        prenom: null,
        nom: null,
        email: null,
        telephone: null,
        date_naissance: null,
        lieu_naissance: null,
        nationalite: null,
        num_permis: null,
        expiration_permis: null,
        situation_familiale: null,
        civilite: null,
        adresse: null,
        statut: true,
      })
    ),
    civilites: ref([]),
    situationFamiliales: ref([]),
    columns: [
      {
        text: i18n.t("chauffeur.table.nom"),
        align: "start",
        sortable: true,
        value: "nom",
      },
      {
        text: i18n.t("chauffeur.table.prenom"),
        align: "start",
        sortable: true,
        value: "prenom",
      },
      {
        text: i18n.t("chauffeur.table.email"),
        align: "start",
        sortable: true,
        value: "email",
      },
      {
        text: i18n.t("chauffeur.table.telephone"),
        align: "start",
        sortable: true,
        value: "telephone",
      },
      {
        text: i18n.t("chauffeur.table.num_permis"),
        align: "start",
        sortable: true,
        value: "num_permis",
      },
      {
        text: i18n.t("chauffeur.table.civilite"),
        align: "start",
        sortable: true,
        value: "civilite",
      },
      {
        text: i18n.t("chauffeur.table.situation_familiale"),
        align: "start",
        sortable: true,
        value: "situation_familiale",
      },
      {
        text: i18n.t("common.table.statut"),
        sortable: false,
        value: "statut",
      },
      {
        text: i18n.t("common.table.action"),
        align: "center",
        sortable: false,
        value: "id",
      },
    ],
    search: '',
    items: ref([]),
    total: 0,
    singleSelect: false,
    rowsSelected: ref([]),
    pageSizes: commonConfig.defaultPageSizes,
    options: {
      itemsPerPage: commonConfig.defaultPageSize,
      page: 1,
      sortBy: ['id'],
      sortDesc: [true],
    },
  }),

  actions: {
    getData({ clearSearch = false }) {
      if (clearSearch) {
        this.search = null
      }

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.rowsSelected = []
      this.items = []

      window.axios.get(URL.CHAUFFEUR, {
        params: {
          search: this.search,
          sortBy: sortBy.length > 0 ? sortBy[0] : 'id',
          sortDesc: sortDesc.length > 0 ? sortDesc[0] : true,
          page: page,
          perPage: itemsPerPage,
        },
      })
        .then(resp => {
          this.items = resp.data.data
          this.total = itemsPerPage === -1 ? resp.data.length : resp.data.meta.total
        })
        .catch(() => { })
    },

    get(id, params = { mode: 'show' }) {
      if (params.mode == 'edit') {
        window.axios
          .all([
            window.axios.get(`${URL.REQUIREMENTS}`),
            window.axios.get(`${URL.CHAUFFEUR}/${id}`, {
              params: params
            }),
          ])
          .then(
            window.axios.spread((resp1, resp2) => {
              this.civilites = resp1.data.civilites
              this.situationFamiliales = resp1.data.situation_familiales
              Object.assign(this.form, resp2.data.chauffeur)
            })
          )
          .catch((_err) => { })
      } else {
        window.axios
          .get(`${URL.CHAUFFEUR}/${id}`, {
            params: params
          })
          .then(resp => {
            Object.assign(this.form, resp.data.chauffeur)
          })
          .catch((_err) => { })
      }
    },

    requirements() {
      window.axios
        .get(`${URL.REQUIREMENTS}`)
        .then(resp => {
          this.civilites = resp.data.civilites
          this.situationFamiliales = resp.data.situation_familiales
        })
        .catch(() => { })
    },

    async store() {
      return await this.form.post(URL.CHAUFFEUR)
    },

    async update() {
      return await this.form.put(`${URL.CHAUFFEUR}/${this.form.id}`)
    },

    async delete(ids) {
      return await window.axios.delete(URL.CHAUFFEUR, {
        params: {
          ids: ids,
        },
      })
    },
  }
})
