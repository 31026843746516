<template>
  <div>
    <v-row>
      <v-col cols="12" class="mb-n2">
        <BreadCrumd :breadCrumbs="breadCrumbs" :title="$t('reservation.creation.breadTitre')" />
      </v-col>
    </v-row>
    <div class="mt-4">
      <v-row>
        <v-col cols="12">
          <SearchForm v-if="!route.params.voyageId" />
          <div v-else>
            <v-row v-if="!reservationStore.currentVoyage">
              <v-col cols="12">
                <v-alert outlined type="error" prominent border="left">
                  {{ $t('reservation.noCurrentVoyage') }}
                </v-alert>
              </v-col>
            </v-row>
            <ResumeVoyage class="mb-4" v-if="reservationStore.currentVoyage" />
            <form @submit.prevent="onSubmit" @keydown="form.onKeydown($event)">
              <v-row>
                <v-col cols="12" class="text-right">
                  <v-btn color="primary" @click.prevent="router.push({ name: 'reservation-create' }).catch(() => {})">
                    <v-icon class="mr-1"> mdi-magnify </v-icon>
                    {{ $t('reservation.btnRecherche') }}
                  </v-btn>

                  <v-btn
                    color="secondary2"
                    class="ml-4"
                    @click.prevent="router.push({ name: 'reservation' }).catch(() => {})"
                  >
                    {{ $t('reservation.listeReservations') }}
                  </v-btn>

                  <ButtonLoader
                    v-if="reservationStore.currentVoyage"
                    :busy="form.busy"
                    type="submit"
                    :normal-text="$t('reservation.btnCreer')"
                    :loader-text="$t('common.btnLoaderText')"
                    color="primary"
                    class="custom-submit-btn ml-4"
                  ></ButtonLoader>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <EditForm />
                </v-col>
              </v-row>
            </form>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import { useReservationStore, useVoyageRechercheStore } from '@/store'
import { useCommon, useSwalAlerte } from '@/utils'
import { useRoute, useRouter } from 'vue-router/composables'
import ResumeVoyage from '../components/ResumeVoyage.vue'
import SearchForm from '../components/Search.vue'
import EditForm from '../components/form/EditForm.vue'

export default {
  setup() {
    const reservationStore = useReservationStore()
    const rechercheStore = useVoyageRechercheStore()
    const commonConfig = useCommon()
    const alerte = useSwalAlerte()
    const route = useRoute()
    const router = useRouter()

    const rechForm = rechercheStore.form
    rechForm.reset()
    rechForm.clear()

    reservationStore.currentVoyage = null
    const form = reservationStore.form
    form.reset()
    form.clear()

    form.envoyer_sms = true

    const onSubmit = () => {
      reservationStore
        .store()
        .then(resp => {
          alerte.showSimpleAlerte({ icon: 'success', text: resp.data.message })
          router.push({
            name: 'reservation-show',
            params: { id: resp.data.reservation.id },
          })
        })
        .catch(_err => {})
    }

    if (route.params.voyageId) {
      reservationStore.voyageToReservation(route.params.voyageId)
    } else {
      rechercheStore.requirements()
    }

    return {
      form,
      reservationStore,
      title: 'reservation.creation.pageTitre',
      commonConfig,
      route,
      router,
      breadCrumbs: [
        {
          text: i18n.t('bread.dashboard'),
          disabled: false,
          href: '/',
        },
        {
          text: i18n.t('reservation.creation.breadActif'),
          disabled: true,
        },
      ],
      onSubmit,
    }
  },
  metaInfo() {
    return {
      title: this.$t(this.title),
      titleTemplate: `${this.commonConfig.getTitle()} | %s`,
    }
  },
  components: {
    SearchForm,
    EditForm,
    ResumeVoyage,
  },
}
</script>
