<template>
  <div class="mt-4">
    <v-row>
      <v-col cols="12" class="text-right">
        <v-btn color="secondary2" class="mr-4" @click.prevent="router.push({ name: 'trajet' }).catch(() => {})">
          {{ $t('trajet.listeTrajets') }}
        </v-btn>

        <v-btn color="primary" @click.prevent="router.push({ name: `${currentRoute}_create` }).catch(() => {})">
          <v-icon left> mdi-plus </v-icon>
          {{ $t('sous_trajet.btnAjout') }}
        </v-btn>

        <v-btn
          color="error"
          class="ml-4"
          v-if="store.rowsSelected.length"
          @click="onDelete(store.rowsSelected.map(rolw => rolw.id))"
        >
          <v-icon left> mdi-delete </v-icon>
          {{ $t('common.btnSuppMass') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-4">
        <DataTable ref="datatable" @on-delete="onDelete" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useSousTrajetStore } from '@/store'
import { useSwalAlerte } from '@/utils'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import DataTable from '../components/DataTable.vue'

export default {
  setup() {
    const store = useSousTrajetStore()
    const alerte = useSwalAlerte()
    const router = useRouter()
    const route = useRoute()

    const currentRoute = computed(() => {
      return route.name
    })

    const onDelete = async ids => {
      if (ids.length > 0) {
        const result = await alerte.showConfirm({})
        if (result.value) {
          await store
            .delete(ids)
            .then(resp => {
              alerte.showSimpleAlerte({ icon: 'success', text: resp.data.message })
              store.getData({ clearSearch: true })
            })
            .catch(() => {})
        }
      }
    }

    return {
      store,
      router,
      currentRoute,
      onDelete,
    }
  },
  components: {
    DataTable,
  },
}
</script>
