import Dashboard from '@/views/dashboard/Dashboard';

export default [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      auth: true,
    },
  },
];
