<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5">
              {{ $t('reservation.apercu.panelTitre', { ref: store.currentVoyage?.reference }) }}</span
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" md="12" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.reference"
                      :label="$t('reservation.form.reference')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-text-field type="text" v-model="form.date" :label="$t('reservation.form.date')"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-text-field type="text" v-model="form.heure" :label="$t('reservation.form.heure')"></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.nom"
                      :label="$t('reservation.form.nom') + ' *'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.telephone"
                      :label="$t('reservation.form.telephone') + ' *'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.voyageur"
                      :label="$t('reservation.form.voyageur')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="2">
                    <v-text-field
                      type="text"
                      :value="form.statut.nom"
                      :label="$t('common.table.statut')"
                      readonly
                      @click="$emit('open-modal-statut', form.id, form.statut.code)"
                    >
                      <template #prepend-inner>
                        <v-icon :color="form.statut.couleur"> mdi-circle </v-icon>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="store.currentVoyage">
      <v-col cols="12">
        <v-card no-body>
          <v-card-text class="pa-0">
            <v-simple-table class="pt-n1">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left text-h6">{{ $t('reservation.table.prix') }}</th>
                    <th class="text-left text-h6">{{ $t('reservation.table.nbre_places') }}</th>
                    <th class="text-left text-h6">{{ $t('reservation.table.montant') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="font-weight-bold text-h6">
                      {{ currencyFormatter.formatCurrency(store.currentVoyage.prix, store.currentVoyage.devise) }}
                    </td>
                    <td class="font-weight-bold text-h6">{{ form.places.length }}</td>
                    <td class="font-weight-bold text-h6">
                      {{
                        currencyFormatter.formatCurrency(
                          store.currentVoyage.prix * form.places.length,
                          store.currentVoyage.devise,
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="store.currentVoyage">
      <v-col cols="12">
        <v-card no-body>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div class="d-flex justify-center align-center" id="svg-container">
                  <div
                    v-if="store.currentVoyage.file_content"
                    v-html="store.currentVoyage.file_content"
                    class="svg-content"
                    id="svg-content"
                  />
                  <div v-else>
                    <p class="text-h4 error--text">{{ $t('reservation.noSvgContent') }}</p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useReservationStore } from '@/store'
import { useCurrencyFormatter } from '@/utils'

export default {
  setup() {
    const store = useReservationStore()
    const currencyFormatter = useCurrencyFormatter()
    const form = store.form

    return {
      store,
      form,
      currencyFormatter,
    }
  },
}
</script>
