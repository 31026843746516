<template>
  <v-btn :loading="busy" :disabled="busy" :color="color" :type="type" :block="block">
    <slot name="icon"></slot>
    {{ normalText }}
    <template v-slot:loader>
      <v-progress-circular v-if="busy" indeterminate color="primary" :size="25" class="mr-4" />
      {{ loaderText }}
    </template>
  </v-btn>
</template>

<script>
import { mdiCached } from '@mdi/js'
export default {
  setup() {
    return { mdiCached }
  },
  props: {
    busy: {
      type: Boolean,
      required: true,
    },
    block: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: true,
      default: 'submit',
    },
    customClass: {
      type: String,
      required: false,
      default: null,
    },
    normalText: {
      type: String,
      required: true,
    },
    loaderText: {
      type: String,
      required: true,
    },
    color: {
      default: 'white',
      type: String,
    },
  },
}
</script>

<style scoped></style>
