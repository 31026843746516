
export const useCurrencyFormatter = () => {
  const formatCurrency = (valeur, currencyCode) => {
    const symbol = currencyCode.slice(0, 3).toUpperCase()

    return new Intl.NumberFormat(undefined,
      {
        style: 'currency',
        currency: symbol,
        currencyDisplay: 'symbol'
      }
    ).format(valeur)
  }

  return {
    formatCurrency,
  }
}
