<template>
  <v-btn :class="['seat', status]" @click="toggleSeat">
    <v-icon v-if="status === 'available'" class="seat-icon">mdi-seat</v-icon>
    <v-icon v-else-if="status === 'selected'" class="seat-icon selected">mdi-seat-recline-normal</v-icon>
    <v-icon v-else class="seat-icon booked">mdi-seat-recline-normal</v-icon>
    <span v-if="!editing">{{ seatNumber }}</span>
    <v-text-field
      v-else
      v-model="editedSeatNumber"
      @blur="updateSeatNumber"
      @keydown.enter="updateSeatNumber"
      dense
      hide-details
    ></v-text-field>
  </v-btn>
</template>

<script>
import { ref } from 'vue'

export default {
  props: {
    seatNumber: Number,
    status: String,
  },
  setup(props, { emit }) {
    const editing = ref(false)
    const editedSeatNumber = ref(props.seatNumber.toString())

    const toggleSeat = () => {
      editing.value = true
    }

    const updateSeatNumber = () => {
      const newSeatNumber = parseInt(editedSeatNumber.value, 10)
      if (!isNaN(newSeatNumber)) {
        emit('seat-number-updated', props.seatNumber, newSeatNumber)
      }
      editing.value = false
    }

    return {
      editing,
      editedSeatNumber,
      toggleSeat,
      updateSeatNumber,
    }
  },
}
</script>

<style scoped>
.seat {
  margin: 5px;
  cursor: pointer;
  text-align: center;
}

.seat-icon {
  font-size: 24px;
  margin-right: 5px;
}

.selected {
  color: #2196f3;
}

.booked {
  color: #ff0000;
}
</style>
