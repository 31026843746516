<template>
  <div>
    <div class="mt-4">
      <v-form readonly>
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn color="secondary2" @click.prevent="router.push({ name: 'trajet' }).catch(() => {})">
              {{ $t('trajet.listeTrajets') }}
            </v-btn>

            <v-btn
              color="primary"
              class="ml-4"
              @click.prevent="
                router
                  .push({
                    name: 'trajet-edit',
                    params: { id: route.params.id },
                  })
                  .catch(() => {})
              "
            >
              <v-icon left> mdi-clipboard-edit-outline </v-icon>
              {{ $t('common.btnModifier') }}
            </v-btn>

            <v-btn class="ml-4" color="primary" @click.prevent="router.push({ name: 'trajet-create' }).catch(() => {})">
              <v-icon left> mdi-plus </v-icon>
              {{ $t('trajet.btnAjout') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <Form />
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import { useTrajetStore } from '@/store'
import { useRoute, useRouter } from 'vue-router/composables'
import Form from '../components/form/ShowForm.vue'

export default {
  setup() {
    const store = useTrajetStore()
    const route = useRoute()
    const router = useRouter()
    const form = store.form

    return {
      form,
      router,
      route,
    }
  },
  components: {
    Form,
  },
}
</script>
