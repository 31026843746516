<template>
  <div>
    <v-row>
      <v-col cols="12" class="mb-n2">
        <BreadCrumd :breadCrumbs="breadCrumbs" :title="$t('config.vehicule_type.apercu.breadTitre')" />
      </v-col>
    </v-row>
    <div class="mt-4">
      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn color="secondary2" @click.prevent="router.push({ name: 'config-vehicule-type' }).catch(() => {})">
            {{ $t('config.vehicule_type.listeTypes') }}
          </v-btn>

          <v-btn
            color="primary"
            class="ml-4"
            @click.prevent="
              router
                .push({
                  name: 'config-vehicule-type-edit',
                  params: { id: route.params.id },
                })
                .catch(() => {})
            "
          >
            <v-icon left> mdi-clipboard-edit-outline </v-icon>
            {{ $t('common.btnModifier') }}
          </v-btn>

          <v-btn
            class="ml-4"
            color="primary"
            @click.prevent="router.push({ name: 'config-vehicule-type-create' }).catch(() => {})"
          >
            <v-icon left> mdi-plus </v-icon>
            {{ $t('config.vehicule_type.btnAjout') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-form readonly>
            <Form />
          </v-form>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import { useConfigVehiculeTypeStore } from '@/store'
import { useCommon } from '@/utils'
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import Form from '../components/form/ShowForm.vue'

export default {
  setup() {
    const store = useConfigVehiculeTypeStore()
    const commonConfig = useCommon()
    const route = useRoute()
    const router = useRouter()

    const form = store.form
    form.reset()
    form.clear()

    onMounted(() => {
      store.get(route.params.id, { mode: 'show' })
    })

    return {
      form,
      store,
      title: 'config.vehicule_type.apercu.pageTitre',
      commonConfig,
      router,
      route,
      breadCrumbs: [
        {
          text: i18n.t('bread.dashboard'),
          disabled: false,
          href: '/',
        },
        {
          text: i18n.t('config.vehicule_type.apercu.breadActif'),
          disabled: true,
        },
      ],
    }
  },
  metaInfo() {
    return {
      title: this.$t(this.title),
      titleTemplate: `${this.commonConfig.getTitle()} | %s`,
    }
  },
  components: {
    Form,
  },
}
</script>
