import URL from '@/http/config/agence'
import i18n from '@/plugins/i18n'
import { useCommon } from '@/utils'
import { defineStore } from 'pinia'
import Form from 'vform'
import { reactive, ref } from 'vue'
const commonConfig = useCommon()

export const useConfigAgenceStore = defineStore({
  id: 'configAgence',
  state: () => ({
    form: reactive(
      new Form({
        nom: null,
        pays: null,
        destination: null,
        indicatif: null,
        email: null,
        telephone: null,
        devise: null,
        statut: true,
      })
    ),
    pays: ref([]),
    destinations: ref([]),
    indicatifs: ref([]),
    devises: ref([]),
    columns: [
      {
        text: i18n.t("config.agence.table.nom"),
        align: "start",
        sortable: true,
        value: "nom",
      },
      {
        text: i18n.t("config.agence.table.pays"),
        align: "start",
        sortable: true,
        value: "pays",
      },
      {
        text: i18n.t("config.agence.table.indicatif"),
        align: "start",
        sortable: true,
        value: "indicatif",
      },
      {
        text: i18n.t("config.agence.table.destination"),
        align: "start",
        sortable: true,
        value: "destination",
      },

      {
        text: i18n.t("config.agence.table.email"),
        align: "start",
        sortable: true,
        value: "email",
      },
      {
        text: i18n.t("config.agence.table.telephone"),
        align: "start",
        sortable: true,
        value: "telephone",
      },
      {
        text: i18n.t("config.agence.table.devise"),
        align: "start",
        sortable: true,
        value: "devise",
      },
      {
        text: i18n.t("common.table.statut"),
        sortable: false,
        value: "statut",
      },
      {
        text: i18n.t("common.table.action"),
        align: "center",
        sortable: false,
        value: "id",
      },
    ],
    search: '',
    items: ref([]),
    total: 0,
    singleSelect: false,
    rowsSelected: ref([]),
    pageSizes: commonConfig.defaultPageSizes,
    options: {
      itemsPerPage: commonConfig.defaultPageSize,
      page: 1,
      sortBy: ['id'],
      sortDesc: [true],
    },
  }),

  actions: {
    getData({ clearSearch = false }) {
      if (clearSearch) {
        this.search = null
      }

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.rowsSelected = []
      this.items = []

      window.axios.get(URL.AGENCE, {
        params: {
          search: this.search,
          sortBy: sortBy.length > 0 ? sortBy[0] : 'id',
          sortDesc: sortDesc.length > 0 ? sortDesc[0] : true,
          page: page,
          perPage: itemsPerPage,
        },
      })
        .then(resp => {
          this.items = resp.data.data
          this.total = itemsPerPage === -1 ? resp.data.length : resp.data.meta.total
        })
        .catch(() => { })
    },

    onChangePays(paysUuid) {
      this.form.destination = null
      this.form.indicatif = null
      const currentPays = window._.find(this.pays, p => p.uuid === paysUuid)
      this.destinations = currentPays ? currentPays.destinations : []
      this.indicatifs = currentPays ? currentPays.indicatifs : []
    },

    get(id, params = { mode: 'show' }) {
      this.destinations = []
      this.pays = []
      this.indicatifs = []
      this.devises = []

      if (params.mode == 'edit') {
        window.axios
          .all([
            window.axios.get(`${URL.REQUIREMENTS}`),
            window.axios.get(`${URL.AGENCE}/${id}`, {
              params: params
            }),
          ])
          .then(
            window.axios.spread((resp1, resp2) => {
              this.devises = resp1.data.devises
              this.pays = resp1.data.pays

              this.onChangePays(resp2.data.agence.pays)
              Object.assign(this.form, resp2.data.agence)
            })
          )
          .catch((_err) => { })
      } else {
        window.axios
          .get(`${URL.AGENCE}/${id}`, {
            params: params
          })
          .then(resp => {
            Object.assign(this.form, resp.data.agence)
          })
          .catch((_err) => { })
      }
    },

    requirements() {
      this.pays = [];
      this.destinations = [];
      this.indicatifs = [];
      this.devises = [];

      window.axios
        .get(`${URL.REQUIREMENTS}`)
        .then(resp => {
          this.pays = resp.data.pays
          this.devises = resp.data.devises
        })
        .catch(() => { })
    },

    async store() {
      return await this.form.post(URL.AGENCE)
    },

    async update() {
      return await this.form.put(`${URL.AGENCE}/${this.form.id}`)
    },

    async delete(ids) {
      return await window.axios.delete(URL.AGENCE, {
        params: {
          ids: ids,
        },
      })
    },
  }
})
