<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h1 class="text-medium-emphasis" v-if="title">
          {{ title }}
        </h1>
        <v-breadcrumbs :items="breadCrumbs" class="ma-0 pa-0">
          <template v-slot:divider>
            <v-icon color="primary"> mdi-forward </v-icon>
          </template>
        </v-breadcrumbs>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  setup() {
    return {}
  },
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    breadCrumbs: {
      type: Array,
      required: true,
    },
  },
  methods: {},
}
</script>
<style scoped>
</style>
