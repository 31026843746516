<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="12" lg="3">
          <v-text-field
            type="text"
            v-model="form.nom"
            :error-messages="form.errors.get('nom')"
            :label="$t('config.user.form.nom') + ' *'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="3">
          <v-text-field
            type="text"
            v-model="form.prenom"
            :error-messages="form.errors.get('prenom')"
            :label="$t('config.user.form.prenom') + ' *'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="3">
          <v-text-field
            type="email"
            v-model="form.email"
            :error-messages="form.errors.get('email')"
            :label="$t('config.user.form.email') + ' *'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="3">
          <v-text-field
            type="text"
            v-model="form.telephone"
            :error-messages="form.errors.get('telephone')"
            :label="$t('config.user.form.telephone') + ' *'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="3">
          <v-text-field
            type="text"
            v-model="form.role"
            :error-messages="form.errors.get('role')"
            :label="$t('config.user.form.role') + ' *'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="3">
          <v-checkbox v-model="form.access_all_agence" :label="$t('config.user.form.access_all_agence')" inset />
        </v-col>
        <v-col cols="12" md="12" lg="3">
          <v-switch
            v-model="form.statut"
            :label="form.statut ? $t('common.switch_statut_actif') : $t('common.switch_statut_inactif')"
            class="custom-select"
            inset
          ></v-switch>
        </v-col>
      </v-row>
      <v-row v-if="!form.access_all_agence">
        <v-col cols="12">
          <v-autocomplete
            v-model="form.agences"
            :items="form.agences"
            item-text="nom"
            item-value="uuid"
            chips
            :label="$t('config.user.form.access_agences') + ' *'"
            :error-messages="form.errors.get('agences')"
            multiple
            :no-data-text="$t('common.autocompleteSearchNoResult')"
            clearable
            append-icon=""
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { useConfigUserStore } from '@/store'

export default {
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useConfigUserStore()

    const form = store.form

    return {
      store,
      form,
    }
  },
}
</script>
