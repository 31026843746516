var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.form.id)?_c('div',[_c('v-row',[_c('v-col',{staticClass:"mb-n2",attrs:{"cols":"12"}},[_c('BreadCrumd',{attrs:{"breadCrumbs":_vm.breadCrumbs,"title":_vm.$t('reservation.modification.breadTitre')}})],1)],1),_c('div',{staticClass:"mt-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.store.currentVoyage)?_c('ResumeVoyage',{staticClass:"mb-4"}):_vm._e()],1)],1),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)},"keydown":function($event){return _vm.form.onKeydown($event)}}},[_c('v-row',[_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"secondary2"},on:{"click":function($event){$event.preventDefault();_vm.router.push({ name: 'reservation' }).catch(() => {})}}},[_vm._v(" "+_vm._s(_vm.$t('reservation.listeReservations'))+" ")]),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();_vm.router
                .push({
                  name: 'reservation-show',
                  params: { id: _vm.route.params.id },
                })
                .catch(() => {})}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-eye ")]),_vm._v(" "+_vm._s(_vm.$t('common.btnApercu'))+" ")],1),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary"},on:{"click":function($event){$event.preventDefault();_vm.router.push({ name: 'reservation-create' }).catch(() => {})}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" mdi-magnify ")]),_vm._v(" "+_vm._s(_vm.$t('reservation.btnRecherche'))+" ")],1),_c('ButtonLoader',{staticClass:"custom-submit-btn ml-4",attrs:{"busy":_vm.form.busy,"type":"submit","normal-text":_vm.$t('common.btnEnregistrer'),"loader-text":_vm.$t('common.btnLoaderText'),"color":"primary"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('EditForm',{attrs:{"edit":""},on:{"open-modal-statut":_vm.openModalStatut}})],1)],1)],1)],1),_c('ModalStatut',{ref:"modalStatut",on:{"on-success":function($event){return _vm.router.replace({
        name: 'reservation-show',
        params: { id: _vm.route.params.id },
      })}}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }