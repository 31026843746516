/** Layouts */
import BaseLayout from '@/views/voyage/layouts/BaseLayout.vue';
import EditLayout from '@/views/voyage/layouts/EditLayout.vue';
import ShowLayout from '@/views/voyage/layouts/ShowLayout.vue';

import Create from '@/views/voyage/pages/Create.vue';
import Edit from '@/views/voyage/pages/Edit.vue';
import List from "@/views/voyage/pages/List.vue";
import Show from '@/views/voyage/pages/Show.vue';

export default [
  {
    path: '/voyage',
    component: BaseLayout,
    children: [
      {
        path: '',
        name: 'voyage',
        component: List,
        meta: {
          auth: true,
        },
      },
      {
        path: 'create/:trajetId?',
        name: 'voyage-create',
        component: Create,
        meta: {
          auth: true,
        },
      },
      {
        path: 'edit/:id',
        component: EditLayout,
        children: [
          {
            path: '',
            name: 'voyage-edit',
            component: Edit,
            meta: {
              auth: true,
            },
          },
        ]
      },
      {
        path: 'show/:id',
        component: ShowLayout,
        children: [
          {
            path: '',
            name: 'voyage-show',
            component: Show,
            meta: {
              auth: true,
            },
          },
        ]
      },
    ]
  }
];
