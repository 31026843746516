<template>
  <div>
    <form @submit.prevent="store.recherche" @keydown="form.onKeydown($event)">
      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn color="secondary2" @click.prevent="router.push({ name: 'reservation' }).catch(() => {})">
            {{ $t('reservation.listeReservations') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card no-body>
            <v-card-title>
              <span class="text-h5"> {{ $t('reservation.creation.panelSearchTitre') }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-row class="d-flex justify-center">
                    <v-col cols="12" md="12" lg="3">
                      <v-menu
                        v-model="date_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="computedDateFormatted"
                            :label="$t('reservation.form.date')"
                            clearable
                            @click:clear="form.date = null"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker locale="fr" v-model="form.date" @input="dateHandler" />
                      </v-menu>
                    </v-col>
                    <v-col cols="12" md="6" lg="3">
                      <v-autocomplete
                        v-model="form.depart"
                        :items="store.departs"
                        item-text="nom"
                        item-value="uuid"
                        :label="$t('reservation.form.depart')"
                        :no-data-text="$t('common.autocompleteSearchNoResult')"
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" lg="3">
                      <v-autocomplete
                        v-model="form.arrivee"
                        :items="store.arrivees"
                        item-text="nom"
                        item-value="uuid"
                        :label="$t('reservation.form.arrivee')"
                        :no-data-text="$t('common.autocompleteSearchNoResult')"
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6" lg="1" class="pt-5">
                      <ButtonLoader
                        :busy="form.busy"
                        type="submit"
                        :normal-text="$t('reservation.btnRecherche')"
                        :loader-text="$t('common.btnLoaderText')"
                        color="primary"
                        class="custom-submit-btn"
                      >
                        <template v-slot:icon>
                          <v-icon class="mr-1"> mdi-magnify </v-icon>
                        </template>
                      </ButtonLoader>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </form>
    <div>
      <v-row>
        <v-col cols="12">
          <v-card no-body>
            <v-card-title>
              <span class="text-h5"> {{ $t('reservation.resultatPanelTitre') }}</span>
            </v-card-title>
            <v-divider></v-divider>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-n6">
        <v-col cols="12">
          <v-card>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">{{ $t('voyage.table.agence') }}</th>
                    <th class="text-left">{{ `${$t('voyage.table.depart')} - ${$t('voyage.table.arrivee')}` }}</th>
                    <th>{{ `${$t('voyage.table.place_disponible')} / ${$t('voyage.table.nbre_place')}` }}</th>
                    <th>{{ $t('voyage.table.distance') }}</th>
                    <th>{{ $t('voyage.table.duree') }}</th>
                    <th>{{ $t('voyage.table.prix') }}</th>
                    <th>{{ $t('voyage.table.heure_convocation') }}</th>
                    <th>{{ $t('voyage.table.heure_depart') }}</th>
                    <th class="text-center">{{ $t('common.table.action') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in store.results" :key="item.id">
                    <td>{{ item.agence }}</td>
                    <td>{{ `${item.depart} - ${item.arrivee}` }}</td>
                    <td class="font-weight-bold">{{ `${item.nbre_places_disponible} / ${item.nbre_places}` }}</td>
                    <td>{{ `${item.distance} Km` }}</td>
                    <td>{{ `${item.duree} H` }}</td>
                    <td class="font-weight-bold">
                      {{ currencyFormatter.formatCurrency(item.prix, item.devise.code) }}
                    </td>
                    <td style="width: 250px">
                      {{ item.heure_convocation }}
                    </td>
                    <td style="width: 250px">
                      {{ item.heure_depart }}
                    </td>
                    <td class="text-center" style="width: 250px">
                      <router-link
                        v-if="item.statut.code === CONSTANT.VOYAGE_CONFIRME"
                        :title="$t('common.table.tooltipReserver')"
                        :to="{
                          name: 'reservation-create',
                          params: { voyageId: item.id },
                        }"
                      >
                        <v-icon color="success" size="25"> fa-calendar-check </v-icon>
                      </router-link>
                      <v-icon v-else color="error" size="25"> fa-calendar-check </v-icon>
                    </td>
                  </tr>
                  <tr v-if="!store.results.length">
                    <td colspan="9" class="text-center">{{ $t('reservation.noResult') }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { useVoyageRechercheStore } from '@/store'
import { useCurrencyFormatter, useDateFormatter } from '@/utils'
import CONSTANT from '@/utils/Constant'
import moment from 'moment'
import { computed, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'

export default {
  setup() {
    const store = useVoyageRechercheStore()
    const currencyFormatter = useCurrencyFormatter()
    const route = useRoute()
    const router = useRouter()
    const formatter = useDateFormatter()
    const date_menu = ref(false)
    const form = store.form
    store.results = []

    watch(
      () => store.form.depart,
      (currentValue, _oldValue) => {
        store.onChangeDepart(currentValue)
      },
      { immediate: true },
    )

    watch(
      () => store.form.arrivee,
      (currentValue, _oldValue) => {
        store.onChangeArrivee(currentValue)
      },
      { immediate: true },
    )

    const dateHandler = date => {
      date_menu.value = false
      if (typeof date === 'string') date = moment(date).format('YYYY-MM-DD')
      form.date = date
    }

    const computedDateFormatted = computed(() => {
      return formatter.formatDate(form.date, 'DD/MM/YYYY')
    })

    return {
      store,
      route,
      router,
      form,
      date_menu,
      dateHandler,
      computedDateFormatted,
      currencyFormatter,
      CONSTANT,
    }
  },
}
</script>
