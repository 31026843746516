import SituationFamiliale from '@/views/config/situation-familiale/pages/SituationFamiliale';

export default [
  {
    path: '/config/situation-familiale',
    name: 'config-situation-familiale',
    component: SituationFamiliale,
    meta: {
      auth: true,
    },
  },
];
