<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card no-body>
          <v-card-title class="custom-card-title">
            <span class="text-h5"> {{ $t('reservation.resumePanelTitre') }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pa-0">
            <v-simple-table class="pt-n1">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">{{ $t('voyage.table.agence') }}</th>
                    <th class="text-left">{{ $t('voyage.table.reference_voyage') }}</th>
                    <th class="text-left">{{ `${$t('voyage.table.depart')} - ${$t('voyage.table.arrivee')}` }}</th>
                    <th>{{ `${$t('voyage.table.place_disponible')} / ${$t('voyage.table.nbre_place')}` }}</th>
                    <th>{{ $t('voyage.table.date') }}</th>
                    <th>{{ $t('voyage.table.vehicule') }}</th>
                    <th>{{ $t('voyage.table.prix') }}</th>
                    <th>{{ $t('voyage.table.heure_convocation') }}</th>
                    <th>{{ $t('voyage.table.heure_depart') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ store.currentVoyage.agence }}</td>
                    <td>{{ store.currentVoyage.reference }}</td>
                    <td>{{ `${store.currentVoyage.depart} - ${store.currentVoyage.arrivee}` }}</td>
                    <td class="font-weight-bold">
                      {{ `${store.currentVoyage.nbre_places_disponible} / ${store.currentVoyage.nbre_places}` }}
                    </td>
                    <td>{{ store.currentVoyage.date }}</td>
                    <td>{{ store.currentVoyage.vehicule }}</td>
                    <td>
                      {{ currencyFormatter.formatCurrency(store.currentVoyage.prix, store.currentVoyage.devise) }}
                    </td>
                    <td style="width: 250px">{{ store.currentVoyage.heure_convocation }}</td>
                    <td style="width: 250px">{{ store.currentVoyage.heure_depart }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useReservationStore } from '@/store'
import { useCurrencyFormatter } from '@/utils'

export default {
  setup() {
    const store = useReservationStore()
    const currencyFormatter = useCurrencyFormatter()

    return {
      store,
      currencyFormatter,
    }
  },
}
</script>
<style scoped>
.custom-card-title {
  padding: 0px 10px 0px 10px;
  height: 45px; /* Adjust the height as needed */
}
</style>
