<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="12" lg="4">
          <v-text-field type="text" v-model="form.nom" :label="$t('chauffeur.form.nom') + ' *'"></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field type="text" v-model="form.prenom" :label="$t('chauffeur.form.prenom')"></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field type="text" v-model="form.civilite" :label="$t('chauffeur.form.civilite')"></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field
            type="text"
            v-model="form.situation_familiale"
            :label="$t('chauffeur.form.situation_familiale')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field type="email" v-model="form.email" :label="$t('chauffeur.form.email')"></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field type="text" v-model="form.telephone" :label="$t('chauffeur.form.telephone')"></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field
            type="text"
            :value="computedDateNaissanceFormatted"
            :label="$t('chauffeur.form.date_naissance')"
            prepend-icon="mdi-calendar"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field
            type="text"
            v-model="form.lieu_naissance"
            :label="$t('chauffeur.form.lieu_naissance')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field type="text" v-model="form.nationalite" :label="$t('chauffeur.form.nationalite')"></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field
            type="text"
            v-model="form.num_permis"
            :label="$t('chauffeur.form.num_permis') + ' *'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field
            type="text"
            :value="computedExpirationPermisFormatted"
            :label="$t('chauffeur.form.expiration_permis')"
            prepend-icon="mdi-calendar"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-switch
            v-model="form.statut"
            :label="form.statut ? $t('common.switch_statut_actif') : $t('common.switch_statut_inactif')"
            class="custom-select"
            inset
          ></v-switch>
        </v-col>
        <v-col cols="12" md="12" lg="6">
          <v-textarea
            v-model="form.adresse"
            :label="$t('chauffeur.form.adresse')"
            :placeholder="$t('chauffeur.form.adresse')"
            counter
            maxlength="150"
            rows="5"
            clear-icon="mdi-close-circle"
            no-resize
          ></v-textarea>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { useChauffeurStore } from '@/store'
import { useDateFormatter } from '@/utils'
import { computed } from 'vue'

export default {
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useChauffeurStore()
    const formatter = useDateFormatter()

    const form = store.form

    const computedDateNaissanceFormatted = computed(() => {
      return formatter.formatDate(form.date_naissance, 'DD/MM/YYYY')
    })

    const computedExpirationPermisFormatted = computed(() => {
      return formatter.formatDate(form.expiration_permis, 'DD/MM/YYYY')
    })

    return {
      store,
      form,
      computedDateNaissanceFormatted,
      computedExpirationPermisFormatted,
    }
  },
}
</script>
