<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5">
              {{
                edit
                  ? $t('reservation.modification.panelTitre', { ref: store.currentVoyage?.reference })
                  : $t('reservation.creation.panelTitre', { ref: store.currentVoyage?.reference })
              }}</span
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" md="12" lg="3" v-if="edit">
                    <v-text-field
                      type="text"
                      v-model="form.reference"
                      :label="$t('reservation.form.reference')"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-menu
                      v-model="date_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="computedDateFormatted"
                          :label="$t('reservation.form.date')"
                          clearable
                          @click:clear="form.date = null"
                          :error-messages="form.errors.get('date')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker locale="fr" v-model="form.date" @input="dateHandler" />
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-menu
                      ref="heurePicker"
                      v-model="heure_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="form.heure"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="form.heure"
                          :label="$t('reservation.form.heure')"
                          clearable
                          :error-messages="form.errors.get('heure')"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="heure_menu"
                        ampm-in-title
                        format="24hr"
                        v-model="form.heure"
                        full-width
                        @click:minute="$refs.heurePicker.save(form.heure)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.nom"
                      :error-messages="form.errors.get('nom')"
                      :label="$t('reservation.form.nom') + ' *'"
                      counter
                      maxlength="50"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.telephone"
                      :error-messages="form.errors.get('telephone')"
                      :label="$t('reservation.form.telephone') + ' *'"
                      :hint="$t('reservation.form.telephone_avec_code')"
                      maxlength="20"
                      counter
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-autocomplete
                      v-model="form.voyageur"
                      :items="store.voyageurs"
                      item-text="nom"
                      item-value="uuid"
                      :label="$t('reservation.form.voyageur') + ' *'"
                      :error-messages="form.errors.get('voyageur')"
                      :no-data-text="$t('common.autocompleteSearchNoResult')"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" lg="2" v-if="edit">
                    <v-text-field
                      type="text"
                      :value="form.statut.nom"
                      :label="$t('common.table.statut')"
                      readonly
                      @click="$emit('open-modal-statut', form.id, form.statut.code)"
                    >
                      <template #prepend-inner>
                        <v-icon :color="form.statut.couleur"> mdi-circle </v-icon>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-checkbox v-model="form.envoyer_sms" :label="$t('reservation.form.envoyer_sms')" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="form.errors.has('reservation_error_message')">
      <v-col cols="12">
        <v-card>
          <v-alert dismissible dense outlined shaped border="left" color="error" elevation="2">
            <p>{{ form.errors.get('reservation_error_message') }}</p>
            <template #close>
              <v-icon size="35" color="error" role="button" aria-hidden="true" @click="clearFormError">
                mdi-close-octagon
              </v-icon>
            </template>
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="store.currentVoyage">
      <v-col cols="12">
        <v-card no-body>
          <v-card-text class="pa-0">
            <v-simple-table class="pt-n1">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left text-h6">{{ $t('reservation.table.prix') }}</th>
                    <th class="text-left text-h6">{{ $t('reservation.table.nbre_places') }}</th>
                    <th class="text-left text-h6">{{ $t('reservation.table.montant') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="font-weight-bold text-h6">
                      {{ currencyFormatter.formatCurrency(store.currentVoyage.prix, store.currentVoyage.devise) }}
                    </td>
                    <td class="font-weight-bold text-h6">{{ form.places.length }}</td>
                    <td class="font-weight-bold text-h6">
                      {{
                        currencyFormatter.formatCurrency(
                          store.currentVoyage.prix * form.places.length,
                          store.currentVoyage.devise,
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="store.currentVoyage">
      <v-col cols="12">
        <v-card no-body>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div class="d-flex justify-center align-center" id="svg-container">
                  <div
                    v-if="store.currentVoyage.file_content"
                    v-html="store.currentVoyage.file_content"
                    class="svg-content"
                    id="svg-content"
                    @click="onSvgClick"
                  />
                  <div v-else>
                    <p class="text-h4 error--text">{{ $t('reservation.noSvgContent') }}</p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useReservationStore } from '@/store'
import { useCurrencyFormatter, useDateFormatter } from '@/utils'
import moment from 'moment'
import { computed, ref } from 'vue'

export default {
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useReservationStore()
    const formatter = useDateFormatter()
    const currencyFormatter = useCurrencyFormatter()

    const date_menu = ref(false)
    const heure_menu = ref(false)
    const form = store.form

    const dateHandler = date => {
      date_menu.value = false
      if (typeof date === 'string') date = moment(date).format('YYYY-MM-DD')
      form.date = date
    }

    const computedDateFormatted = computed(() => {
      return formatter.formatDate(form.date, 'DD/MM/YYYY')
    })

    const clearFormError = () => {
      const errors = form.errors
      errors.clear('reservation_error_message')
    }

    const onSvgClick = e => {
      const clickedElement = e.target
      const clickedGroup = clickedElement.parentNode
      const hasSeatAttribute = clickedGroup.hasAttribute('data-seat-number')
      const disabledGroup = clickedGroup.classList.contains('seat-disabled')

      if (hasSeatAttribute && !disabledGroup) {
        const containerElement = document.getElementById('svg-container')
        const groups = containerElement.querySelectorAll('g')

        const dataSiegeValue = clickedGroup.getAttribute('data-seat-number')

        if (form.places.includes(dataSiegeValue)) {
          //exist
          for (const group of groups) {
            const seatNumber = group.getAttribute('data-seat-number')

            if (seatNumber === dataSiegeValue) {
              group.classList.remove('seat-active')
            }
          }
          form.places = form.places.filter(p => p !== dataSiegeValue)
        } else {
          //new
          clickedGroup.classList.add('seat-active')
          form.places.push(dataSiegeValue)
        }
      }
    }

    return {
      store,
      form,
      date_menu,
      heure_menu,
      computedDateFormatted,
      currencyFormatter,
      dateHandler,
      onSvgClick,
      clearFormError,
    }
  },
}
</script>
