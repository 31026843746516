<template>
  <div>
    <v-row>
      <v-col cols="12" class="mb-n2">
        <BreadCrumd :breadCrumbs="breadCrumbs" :title="$t('profil.breadTitre')" />
      </v-col>
    </v-row>
    <div class="mt-4">
      <form @submit.prevent="onSubmit" @keydown="form.onKeydown($event)">
        <v-row>
          <v-col cols="12" class="text-right">
            <ButtonLoader
              :busy="form.busy"
              type="submit"
              :normal-text="$t('common.btnEnregistrer')"
              :loader-text="$t('common.btnLoaderText')"
              color="primary"
              class="custom-submit-btn"
            ></ButtonLoader>
          </v-col>
        </v-row>
        <v-row class="mt-5">
          <v-col cols="12" lg="9">
            <v-row>
              <v-col cols="12">
                <v-card>
                  <v-card-title>
                    <span class="text-h5"> {{ $t('profil.panelTitre') }}</span>
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="12" lg="4">
                        <v-text-field
                          type="text"
                          v-model="form.nom"
                          :error-messages="form.errors.get('nom')"
                          :label="$t('config.user.form.nom') + ' *'"
                          counter
                          maxlength="50"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" lg="4">
                        <v-text-field
                          type="text"
                          v-model="form.prenom"
                          :error-messages="form.errors.get('prenom')"
                          :label="$t('config.user.form.prenom')"
                          counter
                          maxlength="50"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" lg="4">
                        <v-text-field
                          type="email"
                          v-model="form.email"
                          :error-messages="form.errors.get('email')"
                          :label="$t('config.user.form.email') + ' *'"
                          maxlength="100"
                          counter
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" lg="4">
                        <v-text-field
                          type="text"
                          v-model="form.telephone"
                          :error-messages="form.errors.get('telephone')"
                          :label="$t('config.user.form.telephone') + ' *'"
                          maxlength="20"
                          counter
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" lg="4">
                        <v-text-field
                          type="text"
                          v-model="form.role"
                          :label="$t('config.user.form.role')"
                          readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row class="mt-8">
              <v-col cols="12">
                <v-card>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" md="12" lg="4">
                        <v-text-field
                          v-model="form.password"
                          :type="isPasswordVisible ? 'text' : 'password'"
                          :label="$t('config.user.form.password')"
                          placeholder="············"
                          :append-icon="isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                          @click:append="isPasswordVisible = !isPasswordVisible"
                          :error-messages="form.errors.get('password')"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="12" lg="4">
                        <v-text-field
                          v-model="form.password_confirmation"
                          :type="isPasswordConfirmVisible ? 'text' : 'password'"
                          :label="$t('config.user.form.password_confirm')"
                          placeholder="············"
                          :append-icon="isPasswordConfirmVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                          @click:append="isPasswordConfirmVisible = !isPasswordConfirmVisible"
                          :error-messages="form.errors.get('password_confirmation')"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="3">
            <v-row v-if="form.errors.has('photo')">
              <v-col cols="12">
                <v-card>
                  <v-alert dismissible dense outlined shaped border="left" color="error" elevation="2">
                    <p>{{ form.errors.get('photo') }}</p>
                    <template #close>
                      <v-icon size="35" color="error" role="button" aria-hidden="true" @click="clearPhotoError">
                        mdi-close-octagon
                      </v-icon>
                    </template>
                  </v-alert>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-card class="mx-auto">
                  <v-layout class="d-flex justify-center align-center">
                    <v-avatar class="profile mt-8" color="grey-100" size="280">
                      <v-img v-if="store.urlPhoto" :src="store.urlPhoto" class="mt-4" :alt="$t('altPhoto')" />
                      <v-img class="mt-4" v-else :src="URL_FILE + '/' + CONSTANT.NO_AVATAR" :alt="$t('altPhoto')" />
                    </v-avatar>
                  </v-layout>
                  <div>
                    <input
                      ref="photoUpload"
                      type="file"
                      style="display: none"
                      accept="image/*"
                      @change="onChangePhoto"
                    />
                  </div>

                  <v-card-actions
                    class="mt-4 d-flex"
                    :class="store.urlPhoto !== null ? 'justify-space-between' : 'justify-center'"
                  >
                    <v-btn class="mx-2" fab small color="primary" @click="choosePhoto">
                      <v-icon center> mdi-image-plus </v-icon>
                    </v-btn>
                    <v-btn v-if="store.urlPhoto" class="mx-2" fab small color="error" @click="onDeletePhoto">
                      <v-icon center> mdi-delete </v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </form>
    </div>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import { useAuthStore } from '@/store'
import { useCommon, useSwalAlerte } from '@/utils'
import CONSTANT from '@/utils/Constant'
import { ref } from 'vue'
import { useRouter } from 'vue-router/composables'

export default {
  setup() {
    const store = useAuthStore()
    const commonConfig = useCommon()
    const alerte = useSwalAlerte()
    const router = useRouter()

    const form = store.form
    form.reset()
    form.clear()

    const isPasswordVisible = ref(false)
    const isPasswordConfirmVisible = ref(false)
    const photoUpload = ref()

    const choosePhoto = () => {
      photoUpload.value.click()
    }

    const onChangePhoto = e => {
      const file = e.target.files[0]
      form.photo = file
      store.urlPhoto = global.URL.createObjectURL(file)
      form.delete_photo = true
    }

    const onDeletePhoto = () => {
      form.photo = null
      store.urlPhoto = null
      form.delete_photo = true
    }

    const clearPhotoError = () => {
      const errors = form.errors
      errors.clear('photo')
    }

    const onSubmit = () => {
      store
        .updateProfil()
        .then(resp => {
          alerte.showSimpleAlerte({ icon: 'success', text: resp.data.message })
          form.reset()
          form.clear()
          store.getProfil()
        })
        .catch(_err => {})
    }

    store.getProfil()

    return {
      store,
      form,
      isPasswordVisible,
      isPasswordConfirmVisible,
      router,
      title: 'profil.pageTitre',
      commonConfig,
      choosePhoto,
      onChangePhoto,
      onDeletePhoto,
      clearPhotoError,
      photoUpload,
      URL_FILE: process.env.VUE_APP_URL_FILE,
      CONSTANT,
      breadCrumbs: [
        {
          text: i18n.t('bread.dashboard'),
          disabled: false,
          href: '/',
        },
        {
          text: i18n.t('profil.breadActif'),
          disabled: true,
        },
      ],
      onSubmit,
    }
  },
  metaInfo() {
    return {
      title: this.$t(this.title),
      titleTemplate: `${this.commonConfig.getTitle()} | %s`,
    }
  },
}
</script>
