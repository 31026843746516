import { useAuthStore } from '@/store';
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import Vue from "vue";
import VueRouter from "vue-router";
import "../assets/css/custom-nprogress.css";

Vue.use(VueRouter);

import AgentRoutes from "./agent";
import AuthRoutes from "./auth";
import chauffeurRoutes from "./chauffeur";
import ConfigRoutes from "./config";
import DashboardRoutes from "./dashboard";
import PaiementRoutes from "./paiement";
import ResevationRoutes from "./reservation";
import SousTrajetRoutes from "./sous-trajet";
import SousVoyageRoutes from "./sous-voyage";
import TrajetRoutes from "./trajet";
import VehiculeRoutes from "./vehicule";
import VoyageRoutes from "./voyage";
import VoyageurRoutes from "./voyageur";


const routes = [
  ...AuthRoutes,
  ...DashboardRoutes,
  ...AgentRoutes,
  ...VehiculeRoutes,
  ...VoyageurRoutes,
  ...chauffeurRoutes,
  ...TrajetRoutes,
  ...SousTrajetRoutes,
  ...VoyageRoutes,
  ...SousVoyageRoutes,
  ...ResevationRoutes,
  ...PaiementRoutes,
  ...ConfigRoutes
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, _, next) => {
  const store = useAuthStore()

  if (to.matched.some(record => record.meta.auth)) {
    //page qui necessité d'être connecté
    if (!store.isAuthenticated) {
      //redirection sur login s'il n'est pas connecté
      return next({ name: 'login', query: { redirectFrom: to.fullPath } })
    } else {
      next()
    }
  } else {
    next()
  }
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
  if (routeTo.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  try {
    // For each matched route...
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        // If a `beforeResolve` hook is defined, call it with
        // the same arguments as the `beforeEnter` hook.
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            // If the user chose to redirect...
            if (args.length) {
              // If redirecting to the same route we're coming from...
              // Complete the redirect.
              next(...args);
              reject(new Error("Redirected"));
            } else {
              resolve();
            }
          });
        } else {
          // Otherwise, continue resolving the route.
          resolve();
        }
      });
    }
    // If a `beforeResolve` hook chose to redirect, just return.
  } catch (error) {
    return;
  }

  // If we reach this point, continue resolving the route.
  next();
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  // Remove initial loading
  const preLoading = document.getElementById("lds-ripple");
  if (preLoading) {
    preLoading.style.display = "none";
  }
  setTimeout(() => NProgress.done(), 500);
});

export default router;
