import Destination from '@/views/config/destination/pages/Destination';

export default [
  {
    path: '/config/destination',
    name: 'config-destination',
    component: Destination,
    meta: {
      auth: true,
    },
  },
];
