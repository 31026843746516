<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="12" lg="4">
          <v-text-field
            type="text"
            v-model="form.nom"
            :error-messages="form.errors.get('nom')"
            :label="$t('config.agence.form.nom') + ' *'"
            counter
            maxlength="50"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-autocomplete
            v-model="form.pays"
            :items="store.pays"
            item-text="nom"
            item-value="uuid"
            :label="$t('config.agence.form.pays') + ' *'"
            :error-messages="form.errors.get('pays')"
            :no-data-text="$t('common.autocompleteSearchNoResult')"
            clearable
            @change="store.onChangePays"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-autocomplete
            v-model="form.destination"
            :items="store.destinations"
            item-text="nom"
            item-value="uuid"
            :label="$t('config.agence.form.destination') + ' *'"
            :error-messages="form.errors.get('destination')"
            :no-data-text="$t('common.autocompleteSearchNoResult')"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-autocomplete
            v-model="form.devise"
            :items="store.devises"
            item-text="nom"
            item-value="uuid"
            :label="$t('config.agence.form.devise') + ' *'"
            :error-messages="form.errors.get('devise')"
            :no-data-text="$t('common.autocompleteSearchNoResult')"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field
            type="text"
            v-model="form.telephone"
            :error-messages="form.errors.get('telephone')"
            :label="$t('config.agence.form.telephone') + ' *'"
            maxlength="20"
            counter
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field
            type="email"
            v-model="form.email"
            :error-messages="form.errors.get('email')"
            :label="$t('config.agence.form.email')"
            maxlength="100"
            counter
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-textarea
            v-model="form.adresse"
            :label="$t('config.agence.form.adresse')"
            :placeholder="$t('config.agence.form.adresse')"
            counter
            maxlength="150"
            rows="5"
            clear-icon="mdi-close-circle"
            :error-messages="form.errors.get('adresse')"
            no-resize
          ></v-textarea>
        </v-col>

        <v-col cols="12" md="12" lg="4">
          <v-switch
            v-model="form.statut"
            :label="form.statut ? $t('common.switch_statut_actif') : $t('common.switch_statut_inactif')"
            class="custom-select"
            inset
          ></v-switch>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { useConfigAgenceStore } from '@/store'

export default {
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useConfigAgenceStore()

    const form = store.form

    return {
      store,
      form,
    }
  },
}
</script>
