
import Create from '@/views/config/agence/pages/Create.vue';
import Edit from '@/views/config/agence/pages/Edit.vue';
import List from "@/views/config/agence/pages/List.vue";
import Show from '@/views/config/agence/pages/Show.vue';

export default [
  {
    path: '/config/agence',
    name: 'config-agence',
    component: List,
    meta: {
      auth: true,
    },
  },
  {
    path: '/config/agence/create',
    name: 'config-agence-create',
    component: Create,
    meta: {
      auth: true,
    },
  },
  {
    path: '/config/agence/edit/:id',
    name: 'config-agence-edit',
    component: Edit,
    meta: {
      auth: true,
    },
  },
  {
    path: '/config/agence/show/:id',
    name: 'config-agence-show',
    component: Show,
    meta: {
      auth: true,
    },
  },
];
