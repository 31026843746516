<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="350"
    color="white"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex justify-center align-center items-center w-100">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img
          :src="`${URL_FILE}/images/logo.png`"
          height="70px"
          max-width="180px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
      </router-link>
    </div>
    <v-divider class="mt-1" />

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu-items pr-5">
      <nav-menu-link title="Dashboard" :to="{ name: 'dashboard' }" exact icon="mdi-home-outline"></nav-menu-link>
      <nav-menu-link
        :title="$t('leftMenu.voyageurs')"
        :to="{ name: 'voyageur' }"
        icon="mdi-cube-outline"
        v-if="store.hasRole([CONSTANT.ROLE_SUPADMIN, CONSTANT.ROLE_ADMIN, CONSTANT.ROLE_MANAGER])"
      />
      <nav-menu-link
        :title="$t('leftMenu.vehicules')"
        :to="{ name: 'vehicule' }"
        icon="mdi-cube-outline"
        v-if="store.hasRole([CONSTANT.ROLE_SUPADMIN, CONSTANT.ROLE_ADMIN])"
      />
      <nav-menu-link
        :title="$t('leftMenu.chauffeurs')"
        :to="{ name: 'chauffeur' }"
        icon="mdi-cube-outline"
        v-if="store.hasRole([CONSTANT.ROLE_SUPADMIN, CONSTANT.ROLE_ADMIN])"
      />
      <nav-menu-link
        :title="$t('leftMenu.trajets')"
        :to="{ name: 'trajet' }"
        icon="mdi-cube-outline"
        v-if="store.hasRole([CONSTANT.ROLE_SUPADMIN, CONSTANT.ROLE_ADMIN, CONSTANT.ROLE_MANAGER])"
      />
      <nav-menu-link
        :title="$t('leftMenu.voyages')"
        :to="{ name: 'voyage' }"
        icon="mdi-cube-outline"
        v-if="store.hasRole([CONSTANT.ROLE_SUPADMIN, CONSTANT.ROLE_ADMIN, CONSTANT.ROLE_MANAGER])"
      />
      <nav-menu-link
        :title="$t('leftMenu.reservations')"
        :to="{ name: 'reservation' }"
        icon="mdi-cube-outline"
        v-if="
          store.hasRole([CONSTANT.ROLE_SUPADMIN, CONSTANT.ROLE_ADMIN, CONSTANT.ROLE_MANAGER, CONSTANT.ROLE_OPERATEUR])
        "
      />
      <nav-menu-link
        :title="$t('leftMenu.paiements')"
        :to="{ name: 'paiement' }"
        icon="mdi-cube-outline"
        v-if="
          store.hasRole([CONSTANT.ROLE_SUPADMIN, CONSTANT.ROLE_ADMIN, CONSTANT.ROLE_MANAGER, CONSTANT.ROLE_OPERATEUR])
        "
      />
      <template v-if="store.hasRole([CONSTANT.ROLE_SUPADMIN, CONSTANT.ROLE_ADMIN, CONSTANT.ROLE_MANAGER])">
        <nav-menu-section-title :title="$t('leftMenu.module.config')"></nav-menu-section-title>
        <template v-if="store.hasRole([CONSTANT.ROLE_SUPADMIN, CONSTANT.ROLE_ADMIN])">
          <nav-menu-link :title="$t('leftMenu.devises')" :to="{ name: 'config-devise' }" icon="mdi-cube-outline" />
          <nav-menu-link :title="$t('leftMenu.civilites')" :to="{ name: 'config-civilite' }" icon="mdi-cube-outline" />
          <nav-menu-link
            :title="$t('leftMenu.situationFamiliales')"
            :to="{ name: 'config-situation-familiale' }"
            icon="mdi-cube-outline"
          />
          <nav-menu-link :title="$t('leftMenu.pays')" :to="{ name: 'config-pays' }" icon="mdi-cube-outline" />
          <nav-menu-link
            :title="$t('leftMenu.paysIndicatifs')"
            :to="{ name: 'config-pays-indicatif' }"
            icon="mdi-cube-outline"
          />
          <nav-menu-link
            :title="$t('leftMenu.destionations')"
            :to="{ name: 'config-destination' }"
            icon="mdi-cube-outline"
          />
          <nav-menu-link
            :title="$t('leftMenu.pointEmbarquementDescentes')"
            :to="{ name: 'config-embarquement-descente' }"
            icon="mdi-cube-outline"
          />
          <nav-menu-link
            :title="$t('leftMenu.vehiculeTypes')"
            :to="{ name: 'config-vehicule-type' }"
            icon="mdi-cube-outline"
          />
          <nav-menu-link :title="$t('leftMenu.agences')" :to="{ name: 'config-agence' }" icon="mdi-cube-outline" />
        </template>
        <nav-menu-link
          :title="$t('leftMenu.paiementModes')"
          :to="{ name: 'config-paiement-mode' }"
          icon="mdi-cube-outline"
        />
        <nav-menu-link :title="$t('leftMenu.users')" :to="{ name: 'config-user' }" icon="mdi-cube-outline" />
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { useAuthStore } from '@/store'
import CONSTANT from '@/utils/Constant'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    const store = useAuthStore()
    return {
      store,
      URL_FILE: process.env.VUE_APP_URL_FILE,
      CONSTANT,
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}
</style>
