<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5"> {{ $t('voyage.apercu.panelTitre') }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" md="12" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.reference"
                      :label="$t('voyage.form.reference_voyage')"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-text-field
                      type="text"
                      :value="computedDateFormatted"
                      :label="$t('voyage.form.date')"
                      prepend-icon="mdi-calendar"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.heure_convocation"
                      :label="$t('voyage.form.heure_convocation')"
                      prepend-icon="mdi-clock-time-four-outline"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.heure_depart"
                      :label="$t('voyage.form.heure_depart')"
                      prepend-icon="mdi-clock-time-four-outline"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.vehicule"
                      :label="$t('voyage.form.vehicule') + ' *'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.agence"
                      :label="$t('voyage.form.agence') + ' *'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.depart_nom"
                      :label="$t('voyage.form.depart') + ' *'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.arrivee_nom"
                      :label="$t('voyage.form.arrivee') + ' *'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      :value="`${form.distance} Km`"
                      :label="$t('voyage.form.distance') + ' *'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      :value="`${form.duree} H`"
                      :label="$t('voyage.form.duree') + ' *'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      :value="`${currencyFormatter.formatCurrency(form.prix, form.devise)}`"
                      :label="$t('voyage.form.prix') + ' *'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-autocomplete
                      v-model="form.chauffeurs"
                      :items="form.chauffeurs"
                      item-text="nom"
                      item-value="uuid"
                      chips
                      :label="$t('voyage.form.chauffeurs') + ' *'"
                      :error-messages="form.errors.get('chauffeurs')"
                      multiple
                      :no-data-text="$t('common.autocompleteSearchNoResult')"
                      append-icon=""
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" lg="2">
                    <v-text-field
                      type="text"
                      :value="form.statut.nom"
                      :label="$t('common.table.statut')"
                      readonly
                      @click="$emit('open-modal-statut', form.id, form.statut.code)"
                    >
                      <template #prepend-inner>
                        <v-icon :color="form.statut.couleur"> mdi-circle </v-icon>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5"> {{ $t('voyage.arretPanelTitre') }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            <v-autocomplete
              v-model="form.arrets"
              :items="form.arrets"
              item-text="nom"
              item-value="nom"
              outlined
              dense
              chips
              :label="$t('voyage.form.point_arret')"
              multiple
              :no-data-text="$t('common.autocompleteSearchNoResult')"
              append-icon=""
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5"> {{ $t('voyage.embarquementPanelTitre') }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            <v-autocomplete
              v-model="form.embarquements"
              :items="form.embarquements"
              item-text="nom"
              item-value="nom"
              outlined
              dense
              chips
              :label="$t('voyage.form.point_embarquement') + ' *'"
              multiple
              :no-data-text="$t('common.autocompleteSearchNoResult')"
              append-icon=""
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5"> {{ $t('voyage.descentePanelTitre') }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            <v-autocomplete
              v-model="form.descentes"
              :items="form.descentes"
              item-text="nom"
              item-value="nom"
              outlined
              dense
              chips
              :label="$t('voyage.form.point_descente') + ' *'"
              multiple
              :no-data-text="$t('common.autocompleteSearchNoResult')"
              append-icon=""
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useVoyageStore } from '@/store'
import { useCurrencyFormatter, useDateFormatter } from '@/utils'
import { computed } from 'vue'

export default {
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useVoyageStore()
    const currencyFormatter = useCurrencyFormatter()
    const formatter = useDateFormatter()
    const form = store.form

    const computedDateFormatted = computed(() => {
      return formatter.formatDate(form.date, 'DD/MM/YYYY')
    })

    return {
      store,
      form,
      currencyFormatter,
      computedDateFormatted,
    }
  },
}
</script>
