import { useInitApp } from '@/store';
import Vue from 'vue';
import CONSTANT from './Constant';

export const useCommon = () => {
  const defaultPageSize = 100
  const defaultPageSizes = [50, 100, 150, 200, -1]

  const reactiveAssign = (target, source) => {
    for (const key in source) {
      if (!target.hasOwnProperty(key)) {
        Vue.set(target, key, source[key]);
      } else {
        Vue.set(target, key, source[key]);
      }
    }

    return target;
  }

  const getTitle = () => {
    const store = useInitApp()

    return store.app_name ? store.app_name : 'KODAX-APP';
  }

  const getStatutTxt = value => {
    let result = ""
    switch (Boolean(value)) {
      case CONSTANT.ACTIF:
        result = CONSTANT.TXT_ACTIF
        break
      case CONSTANT.INACTIF:
        result = CONSTANT.TXT_INACTIF
        break
    }

    return result
  }

  const getStatutClass = value => {
    let result = "error"
    switch (Boolean(value)) {
      case CONSTANT.ACTIF:
        result = "success"
        break
      case CONSTANT.INACTIF:
        result = "error"
        break
    }

    return result
  }

  const getTrueFalseTxt = value => {
    let result = ''
    switch (Boolean(value)) {
      case CONSTANT.OUI:
        result = CONSTANT.TXT_OUI
        break
      case CONSTANT.NON:
        result = CONSTANT.TXT_NON
        break
    }

    return result
  }

  const getTrueFalseClass = value => {
    let result = 'error'
    switch (Boolean(value)) {
      case CONSTANT.OUI:
        result = 'success'
        break
      case CONSTANT.NON:
        result = 'error'
        break
    }

    return result
  }

  return {
    reactiveAssign,

    defaultPageSize,
    defaultPageSizes,

    getStatutTxt,
    getStatutClass,

    getTrueFalseTxt,
    getTrueFalseClass,
    getTitle,
  }
}
