<template>
  <div v-if="form.id">
    <div class="mt-4">
      <form @submit.prevent="onSubmit" @keydown="form.onKeydown($event)">
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn color="secondary2" class="mr-4" @click.prevent="router.push({ name: currentRoute }).catch(() => {})">
              {{ $t('sous_voyage.listeSousVoyages') }}
            </v-btn>

            <v-btn
              color="primary"
              class="ml-4"
              @click.prevent="
                router
                  .push({
                    name: `${currentRoute}_show`,
                    params: { id: route.params.id, sousVoyageId: route.params.sousVoyageId },
                  })
                  .catch(() => {})
              "
            >
              <v-icon left> mdi-eye </v-icon>
              {{ $t('common.btnApercu') }}
            </v-btn>

            <v-btn
              class="ml-4"
              color="primary"
              @click.prevent="
                router.push({ name: `${currentRoute}_create`, params: { id: route.params.id } }).catch(() => {})
              "
            >
              <v-icon left> mdi-plus </v-icon>
              {{ $t('sous_voyage.btnAjout') }}
            </v-btn>

            <ButtonLoader
              :busy="form.busy"
              type="submit"
              :normal-text="$t('common.btnEnregistrer')"
              :loader-text="$t('common.btnLoaderText')"
              color="primary"
              class="custom-submit-btn ml-4"
            ></ButtonLoader>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <Form edit @open-modal-statut="openModalStatut" />
          </v-col>
        </v-row>
      </form>
    </div>
    <ModalStatut ref="modalStatut" @on-success="store.get(route.params.sousVoyageId, { mode: 'edit' })" />
  </div>
</template>

<script>
import ModalStatut from '@/components/ModalVoyageStatut.vue'
import { useSousVoyageStore } from '@/store'
import { useSwalAlerte } from '@/utils'
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import Form from '../components/form/EditForm.vue'

export default {
  setup() {
    const store = useSousVoyageStore()
    const alerte = useSwalAlerte()
    const route = useRoute()
    const router = useRouter()
    const modalStatut = ref()
    const openModalStatut = (id, currentStatutCode) => {
      modalStatut.value.openModal(id, currentStatutCode)
    }

    const form = store.form
    form.reset()
    form.clear()

    const currentRoute = computed(() => {
      return route.name.split('_')[0]
    })

    store.get(route.params.sousVoyageId, { mode: 'edit' })

    const clearFormError = () => {
      const errors = form.errors
      errors.clear('sous_voyage_error_message')
    }

    const onSubmit = () => {
      store
        .update()
        .then(resp => {
          alerte.showSimpleAlerte({ icon: 'success', text: resp.data.message })
          form.reset()
          form.clear()
          store.get(route.params.sousVoyageId, { mode: 'edit' })
        })
        .catch(_err => {})
    }

    return {
      form,
      store,
      router,
      route,
      currentRoute,
      onSubmit,
      clearFormError,
      modalStatut,
      openModalStatut,
    }
  },
  components: {
    Form,
    ModalStatut,
  },
}
</script>
