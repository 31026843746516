
import { defineStore } from 'pinia'

export const useLoaderStore = defineStore({
  id: 'loader',
  state: () => ({
    isLoading: true,
    fullPage: false,
    canCancel: false,
    color: '#041a37',
    width: 100,
    height: 100
  }),

  actions: {
    updateLoader({ isLoading = false, fullPage = false, canCancel = false }) {
      this.isLoading = isLoading
      this.fullPage = fullPage
      this.canCancel = canCancel
    },
  }
})
