<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5">
              {{ edit ? $t('voyageur.modification.panelTitre') : $t('voyageur.creation.panelTitre') }}</span
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" lg="3">
                <v-text-field
                  type="text"
                  v-model="form.nom"
                  :error-messages="form.errors.get('nom')"
                  :label="$t('voyageur.form.nom') + ' *'"
                  counter
                  maxlength="50"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <v-text-field
                  type="text"
                  v-model="form.prenom"
                  :error-messages="form.errors.get('prenom')"
                  :label="$t('voyageur.form.prenom')"
                  counter
                  maxlength="50"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <v-autocomplete
                  v-model="form.pays"
                  :items="store.pays"
                  item-text="nom"
                  item-value="uuid"
                  :label="$t('voyageur.form.pays') + ' *'"
                  :error-messages="form.errors.get('pays')"
                  :no-data-text="$t('common.autocompleteSearchNoResult')"
                  clearable
                  @change="store.onChangePays"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <v-autocomplete
                  v-model="form.indicatif"
                  :items="store.indicatifs"
                  item-text="code"
                  item-value="uuid"
                  :label="$t('voyageur.form.indicatif') + ' *'"
                  :error-messages="form.errors.get('indicatif')"
                  :no-data-text="$t('common.autocompleteSearchNoResult')"
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <v-text-field
                  type="text"
                  v-model="form.telephone"
                  :error-messages="form.errors.get('telephone')"
                  :label="$t('voyageur.form.telephone')"
                  maxlength="20"
                  counter
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="6" lg="3">
                <v-switch
                  v-model="form.verifier"
                  :label="form.verifier ? $t('common.switch_compte_verifier') : $t('common.switch_compte_non_verifier')"
                  class="custom-select"
                  inset
                ></v-switch>
              </v-col>

              <v-col cols="12" md="6" lg="3">
                <v-switch
                  v-model="form.statut"
                  :label="form.statut ? $t('common.switch_statut_actif') : $t('common.switch_statut_inactif')"
                  class="custom-select"
                  inset
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mt-8">
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6" lg="3">
                <v-text-field
                  v-model="form.password"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :label="$t('config.user.form.password')"
                  placeholder="············"
                  :append-icon="isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                  @click:append="isPasswordVisible = !isPasswordVisible"
                  :error-messages="form.errors.get('password')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <v-text-field
                  v-model="form.password_confirmation"
                  :type="isPasswordConfirmVisible ? 'text' : 'password'"
                  :label="$t('config.user.form.password_confirm')"
                  placeholder="············"
                  :append-icon="isPasswordConfirmVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                  @click:append="isPasswordConfirmVisible = !isPasswordConfirmVisible"
                  :error-messages="form.errors.get('password_confirmation')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useVoyageurStore } from '@/store'
import { ref } from 'vue'

export default {
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useVoyageurStore()
    const isPasswordVisible = ref(false)
    const isPasswordConfirmVisible = ref(false)
    const form = store.form

    return {
      store,
      form,
      isPasswordVisible,
      isPasswordConfirmVisible,
    }
  },
}
</script>
