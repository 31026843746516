import URL_TRAJET from '@/http/trajet'
import URL from '@/http/voyage'
import i18n from '@/plugins/i18n'
import { useSousVoyageStore } from '@/store/sous-voyage.store'
import { useCommon } from '@/utils'
import moment from 'moment'
import { defineStore } from 'pinia'
import Form from 'vform'
import { reactive, ref } from 'vue'

const commonConfig = useCommon()
export const useVoyageStore = defineStore({
  id: 'voyage',
  state: () => ({
    form: reactive(
      new Form({
        agence: null,
        date: moment(new Date()).format('YYYY-MM-DD'),
        heure_convocation: null,
        heure_depart: null,
        depart: null,
        arrivee: null,
        vehicule: null,
        distance: null,
        duree: null,
        prix: null,
        statut: true,
        chauffeurs: [],
        arrets: [],
        embarquements: [],
        descentes: [],
        nbre_sous_voyages: 0,
        sous_voyages: [],
      })
    ),
    destinations: ref([]),
    departs: ref([]),
    arrivees: ref([]),
    agences: ref([]),
    chauffeurs: ref([]),
    vehicules: ref([]),
    embarquements: ref([]),
    descentes: ref([]),
    arrets: ref([]),
    sousVoyages: ref([]),
    columns: [
      {
        text: i18n.t("voyage.table.agence"),
        align: "start",
        sortable: true,
        value: "agence",
      },
      {
        text: i18n.t("voyage.table.reference_voyage"),
        align: "start",
        sortable: true,
        value: "reference",
      },
      {
        text: i18n.t("voyage.table.date"),
        align: "start",
        sortable: true,
        value: "date",
      },
      {
        text: i18n.t("voyage.table.heure_convocation"),
        align: "start",
        sortable: true,
        value: "heure_convocation",
      },
      {
        text: i18n.t("voyage.table.heure_depart"),
        align: "start",
        sortable: true,
        value: "heure_depart",
      },
      {
        text: i18n.t("voyage.table.depart"),
        align: "start",
        sortable: true,
        value: "depart",
      },
      {
        text: i18n.t("voyage.table.arrivee"),
        align: "start",
        sortable: true,
        value: "arrivee",
      },
      {
        text: i18n.t("voyage.table.distance"),
        align: "start",
        sortable: true,
        value: "distance",
      },
      {
        text: i18n.t("voyage.table.duree"),
        align: "start",
        sortable: true,
        value: "duree",
      },
      {
        text: i18n.t("voyage.table.prix"),
        align: "start",
        sortable: true,
        value: "prix",
      },
      {
        text: i18n.t("common.table.statut"),
        sortable: false,
        value: "statut",
      },
      {
        text: i18n.t("common.table.action"),
        align: "center",
        sortable: false,
        value: "id",
      },
    ],
    search: '',
    items: ref([]),
    total: 0,
    singleSelect: false,
    rowsSelected: ref([]),
    pageSizes: commonConfig.defaultPageSizes,
    options: {
      itemsPerPage: commonConfig.defaultPageSize,
      page: 1,
      sortBy: ['id'],
      sortDesc: [true],
    },
  }),

  actions: {
    getData({ clearSearch = false }) {
      if (clearSearch) {
        this.search = null
      }

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.rowsSelected = []
      this.items = []

      window.axios.get(URL.VOYAGE, {
        params: {
          search: this.search,
          sortBy: sortBy.length > 0 ? sortBy[0] : 'id',
          sortDesc: sortDesc.length > 0 ? sortDesc[0] : true,
          page: page,
          perPage: itemsPerPage,
        },
      })
        .then(resp => {
          this.items = resp.data.data
          this.total = itemsPerPage === -1 ? resp.data.length : resp.data.meta.total
        })
        .catch(() => { })
    },

    onChangeAgence(agenceUuid) {
      this.form.depart = null
      const obj = window._.find(this.agences, a => a.uuid === agenceUuid)
      if (obj) {
        this.onChangeDepart(obj.destination.uuid)
        this.form.depart = obj.destination.uuid
      }
    },

    onChangeDepart(destinationUuid) {
      if (destinationUuid === this.form.arrivee) this.form.arrivee = null
      this.arrivees = window._.filter(this.destinations, d => d.uuid !== destinationUuid)

      const obj = window._.find(this.destinations, d => d.uuid === destinationUuid)
      this.embarquements = obj ? obj.embarquement_descentes : []
    },

    onChangeArrivee(destinationUuid) {
      if (destinationUuid === this.form.depart) this.form.depart = null
      this.departs = window._.filter(this.destinations, d => d.uuid !== destinationUuid)

      const obj = window._.find(this.destinations, d => d.uuid === destinationUuid)
      this.descentes = obj ? obj.embarquement_descentes : []
    },

    updatePointArretes() {
      let arrets = JSON.parse(JSON.stringify(this.destinations))
      if (this.form.depart) {
        this.form.arrets = window._.filter(this.form.arrets, pa => pa !== this.form.depart)
        const obj = window._.find(arrets, pa => pa.uuid === this.form.depart)
        Object.assign(obj, { disabled: true })
      }
      if (this.form.arrivee) {
        this.form.arrets = window._.filter(this.form.arrets, pa => pa !== this.form.arrivee)
        const obj = window._.find(arrets, pa => pa.uuid === this.form.arrivee)
        Object.assign(obj, { disabled: true })
      }

      this.arrets = arrets
    },

    get(id, params = { mode: 'show' }) {
      const sousVoyage = useSousVoyageStore()

      this.reset()

      if (params.mode == 'edit') {
        window.axios
          .all([
            window.axios.get(`${URL.REQUIREMENTS}`),
            window.axios.get(`${URL.VOYAGE}/${id}`, {
              params: params
            }),
            window.axios.get(`${URL.VOYAGE}/${id}`, {
              params: { mode: 'edit' }
            }),
          ])
          .then(
            window.axios.spread((resp1, resp2, resp3) => {
              this.destinations = resp1.data.destinations
              this.departs = this.destinations
              this.arrivees = this.destinations
              this.agences = resp1.data.agences
              this.vehicules = resp1.data.vehicules
              this.chauffeurs = resp1.data.chauffeurs
              this.arrets = JSON.parse(JSON.stringify(this.destinations))

              this.form = commonConfig.reactiveAssign(this.form, resp2.data.voyage)
              sousVoyage.currentVoyage = resp3.data.voyage
            })
          )
          .catch((_err) => { })
      } else {
        window.axios
          .get(`${URL.VOYAGE}/${id}`, {
            params: params
          })
          .then(resp => {
            this.form = commonConfig.reactiveAssign(this.form, resp.data.voyage)
            sousVoyage.currentVoyage = resp.data.voyage
          })
          .catch((_err) => { })
      }
    },

    trajetToVoyage(trajetId) {
      window.axios
        .all([
          window.axios.get(`${URL.REQUIREMENTS}`),
          window.axios.get(`${URL_TRAJET.TRAJET}/${trajetId}`, { params: { mode: 'edit', include_sous_trajet: true } }),
        ])
        .then(
          window.axios.spread((resp1, resp2) => {
            this.destinations = resp1.data.destinations
            this.departs = this.destinations
            this.arrivees = this.destinations
            this.agences = resp1.data.agences
            this.vehicules = resp1.data.vehicules
            this.chauffeurs = resp1.data.chauffeurs
            this.arrets = JSON.parse(JSON.stringify(this.destinations))

            this.form = commonConfig.reactiveAssign(this.form, resp2.data.trajet)
            this.sousVoyages = resp2.data.trajet.sous_trajets.map(sousTrajet => {
              Object.assign(sousTrajet, { date: moment(new Date()).format('YYYY-MM-DD'), heure_convocation: null, heure_depart: null })
              return sousTrajet
            })

          })
        )
        .catch(() => { })
    },

    requirements() {
      this.reset()
      window.axios
        .get(`${URL.REQUIREMENTS}`)
        .then(resp => {
          this.destinations = resp.data.destinations
          this.departs = this.destinations
          this.arrivees = this.destinations
          this.agences = resp.data.agences
          this.vehicules = resp.data.vehicules
          this.chauffeurs = resp.data.chauffeurs
          this.arrets = JSON.parse(JSON.stringify(this.destinations))
        })
        .catch(() => { })
    },

    reset() {
      this.destinations = []
      this.departs = []
      this.arrivees = []
      this.agences = []
      this.arrets = []
      this.embarquements = []
      this.descentes = []
      this.vehicules = []
      this.chauffeurs = []
    },

    async store() {
      return await this.form.post(URL.VOYAGE)
    },

    async update() {
      return await this.form.put(`${URL.VOYAGE}/${this.form.id}`)
    },

    async delete(ids) {
      return await window.axios.delete(URL.VOYAGE, {
        params: {
          ids: ids,
        },
      })
    },
  }
})
