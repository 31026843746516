var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.form.id)?_c('div',[_c('v-row',[_c('v-col',{staticClass:"mb-n2",attrs:{"cols":"12"}},[_c('BreadCrumd',{attrs:{"breadCrumbs":_vm.breadCrumbs,"title":_vm.$t('trajet.apercu.breadTitre')}})],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{attrs:{"id":"custom-tab"}},[_c('v-tab',{attrs:{"to":{
            name: 'trajet-show',
            params: { id: _vm.route.params.id },
          },"exact":""}},[_c('v-icon',[_vm._v("mdi-eye")]),_vm._v(" "+_vm._s(_vm.$t('trajet.tabs.trajet'))+" ")],1),_c('v-tab',{attrs:{"to":{
            name: 'trajet-show-sous-trajet',
            params: { id: _vm.route.params.id },
          }}},[_c('v-icon',[_vm._v("mdi-cube-outline")]),_vm._v(" "+_vm._s(_vm.$t('trajet.tabs.sousTrajet'))+" ")],1)],1)],1)],1),_c('router-view')],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }