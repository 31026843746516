<template>
  <div v-if="form.id">
    <v-row>
      <v-col cols="12" class="mb-n2">
        <BreadCrumd :breadCrumbs="breadCrumbs" :title="$t('reservation.modification.breadTitre')" />
      </v-col>
    </v-row>
    <div class="mt-4">
      <v-row>
        <v-col cols="12">
          <ResumeVoyage class="mb-4" v-if="store.currentVoyage" />
        </v-col>
      </v-row>
      <form @submit.prevent="onSubmit" @keydown="form.onKeydown($event)">
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn color="secondary2" @click.prevent="router.push({ name: 'reservation' }).catch(() => {})">
              {{ $t('reservation.listeReservations') }}
            </v-btn>

            <v-btn
              color="primary"
              class="ml-4"
              @click.prevent="
                router
                  .push({
                    name: 'reservation-show',
                    params: { id: route.params.id },
                  })
                  .catch(() => {})
              "
            >
              <v-icon left> mdi-eye </v-icon>
              {{ $t('common.btnApercu') }}
            </v-btn>

            <v-btn
              color="primary"
              class="ml-4"
              @click.prevent="router.push({ name: 'reservation-create' }).catch(() => {})"
            >
              <v-icon class="mr-1"> mdi-magnify </v-icon>
              {{ $t('reservation.btnRecherche') }}
            </v-btn>

            <ButtonLoader
              :busy="form.busy"
              type="submit"
              :normal-text="$t('common.btnEnregistrer')"
              :loader-text="$t('common.btnLoaderText')"
              color="primary"
              class="custom-submit-btn ml-4"
            ></ButtonLoader>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <EditForm edit @open-modal-statut="openModalStatut" />
          </v-col>
        </v-row>
      </form>
    </div>
    <ModalStatut
      ref="modalStatut"
      @on-success="
        router.replace({
          name: 'reservation-show',
          params: { id: route.params.id },
        })
      "
    />
  </div>
</template>

<script>
import ModalStatut from '@/components/ModalReservationStatut.vue'
import i18n from '@/plugins/i18n'
import { useReservationStore } from '@/store'
import { useCommon, useSwalAlerte } from '@/utils'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import ResumeVoyage from '../components/ResumeVoyage.vue'
import SearchForm from '../components/Search.vue'
import EditForm from '../components/form/EditForm.vue'

export default {
  setup() {
    const store = useReservationStore()
    const commonConfig = useCommon()
    const alerte = useSwalAlerte()
    const route = useRoute()
    const router = useRouter()
    const modalStatut = ref()

    store.currentVoyage = null
    const form = store.form
    form.reset()
    form.clear()

    const openModalStatut = (id, currentStatutCode) => {
      modalStatut.value.openModal(id, currentStatutCode)
    }

    const onSubmit = () => {
      store
        .update()
        .then(resp => {
          alerte.showSimpleAlerte({ icon: 'success', text: resp.data.message })
          form.reset()
          form.clear()
          store.get(route.params.id, { mode: 'edit' })
        })
        .catch(_err => {})
    }

    form.envoyer_sms = false
    store.get(route.params.id, { mode: 'edit' })

    return {
      form,
      store,
      router,
      route,
      title: 'reservation.modification.pageTitre',
      commonConfig,
      modalStatut,
      openModalStatut,
      breadCrumbs: [
        {
          text: i18n.t('bread.dashboard'),
          disabled: false,
          href: '/',
        },
        {
          text: i18n.t('reservation.modification.breadActif'),
          disabled: true,
        },
      ],
      onSubmit,
    }
  },
  metaInfo() {
    return {
      title: this.$t(this.title),
      titleTemplate: `${this.commonConfig.getTitle()} | %s`,
    }
  },
  components: {
    SearchForm,
    EditForm,
    ResumeVoyage,
    ModalStatut,
  },
}
</script>
