import URL from '@/http/vehicule'
import i18n from '@/plugins/i18n'
import { useCommon } from '@/utils'
import { defineStore } from 'pinia'
import Form from 'vform'
import { reactive, ref } from 'vue'
const commonConfig = useCommon()

export const useVehiculeStore = defineStore({
  id: 'vehicule',
  state: () => ({
    form: reactive(
      new Form({
        immatriculation: null,
        type: null,
        marque: null,
        modele: null,
        carburant: null,
        year: null,
        statut: true,
      })
    ),
    types: ref([]),
    columns: [
      {
        text: i18n.t("vehicule.table.immatriculation"),
        align: "start",
        sortable: true,
        value: "immatriculation",
      },
      {
        text: i18n.t("vehicule.table.vehicule_type"),
        align: "start",
        sortable: true,
        value: "vehicule_type",
      },
      {
        text: i18n.t("vehicule.table.carburant"),
        align: "start",
        sortable: true,
        value: "carburant",
      },
      {
        text: i18n.t("vehicule.table.marque"),
        align: "start",
        sortable: true,
        value: "marque",
      },
      {
        text: i18n.t("vehicule.table.modele"),
        align: "start",
        sortable: true,
        value: "modele",
      },
      {
        text: i18n.t("vehicule.table.annee"),
        align: "start",
        sortable: true,
        value: "annee",
      },
      {
        text: i18n.t("common.table.statut"),
        sortable: false,
        value: "statut",
      },
      {
        text: i18n.t("common.table.action"),
        align: "center",
        sortable: false,
        value: "id",
      },
    ],
    search: '',
    items: ref([]),
    total: 0,
    singleSelect: false,
    rowsSelected: ref([]),
    pageSizes: commonConfig.defaultPageSizes,
    options: {
      itemsPerPage: commonConfig.defaultPageSize,
      page: 1,
      sortBy: ['id'],
      sortDesc: [true],
    },
  }),

  actions: {
    getData({ clearSearch = false }) {
      if (clearSearch) {
        this.search = null
      }

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.rowsSelected = []
      this.items = []

      window.axios.get(URL.VEHICULE, {
        params: {
          search: this.search,
          sortBy: sortBy.length > 0 ? sortBy[0] : 'id',
          sortDesc: sortDesc.length > 0 ? sortDesc[0] : true,
          page: page,
          perPage: itemsPerPage,
        },
      })
        .then(resp => {
          this.items = resp.data.data
          this.total = itemsPerPage === -1 ? resp.data.length : resp.data.meta.total
        })
        .catch(() => { })
    },

    get(id, params = { mode: 'show' }) {
      if (params.mode == 'edit') {
        window.axios
          .all([
            window.axios.get(`${URL.REQUIREMENTS}`),
            window.axios.get(`${URL.VEHICULE}/${id}`, {
              params: params
            }),
          ])
          .then(
            window.axios.spread((resp1, resp2) => {
              this.types = resp1.data.types
              Object.assign(this.form, resp2.data.vehicule)
            })
          )
          .catch((_err) => { })
      } else {
        window.axios
          .get(`${URL.VEHICULE}/${id}`, {
            params: params
          })
          .then(resp => {
            Object.assign(this.form, resp.data.vehicule)
          })
          .catch((_err) => { })
      }
    },

    requirements() {
      window.axios
        .get(`${URL.REQUIREMENTS}`)
        .then(resp => {
          this.types = resp.data.types
        })
        .catch(() => { })
    },

    async store() {
      return await this.form.post(URL.VEHICULE)
    },

    async update() {
      return await this.form.put(`${URL.VEHICULE}/${this.form.id}`)
    },

    async delete(ids) {
      return await window.axios.delete(URL.VEHICULE, {
        params: {
          ids: ids,
        },
      })
    },
  }
})
