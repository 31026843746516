import URL from '@/http/voyage-recherche'
import moment from 'moment'
import { defineStore } from 'pinia'
import Form from 'vform'
import { reactive, ref } from 'vue'

export const useVoyageRechercheStore = defineStore({
  id: 'voyageRecherche',
  state: () => ({
    form: reactive(
      new Form({
        date: moment(new Date()).format('YYYY-MM-DD'),
        depart: null,
        arrivee: null,
      })
    ),
    results: ref([]),
    destinations: ref([]),
    departs: ref([]),
    arrivees: ref([]),
  }),

  actions: {
    onChangeDepart(destinationUuid) {
      if (destinationUuid === this.form.arrivee) this.form.arrivee = null
      this.arrivees = window._.filter(this.destinations, d => d.uuid !== destinationUuid)
    },

    onChangeArrivee(destinationUuid) {
      if (destinationUuid === this.form.depart) this.form.depart = null
      this.departs = window._.filter(this.destinations, d => d.uuid !== destinationUuid)
    },

    requirements() {
      this.destinations = [];
      this.departs = [];
      this.arrivees = [];

      window.axios
        .get(`${URL.REQUIREMENTS}`)
        .then(resp => {
          this.destinations = resp.data.destinations
          this.departs = this.destinations
          this.arrivees = this.destinations
        })
        .catch(() => { })
    },

    recherche() {
      this.results = [];
      if (this.form.depart && this.form.arrivee && this.form.date) {
        window.axios
          .get(URL.RECHERCHE, {
            params: {
              depart: this.form.depart,
              arrivee: this.form.arrivee,
              date: this.form.date,
            },
          })
          .then(resp => {
            this.results = resp.data.results
          })
          .catch(() => { })
      }

    },
  }
})
