<template>
  <div class="auth-wrapper d-flex flex-column justify-center">
    <div>
      <v-container v-if="message.text">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-alert dismissible dense outlined shaped border="left" :color="message.type" elevation="2">
                <p>{{ message.text }}</p>
                <template #close>
                  <v-icon size="35" color="error" role="button" aria-hidden="true" @click="flashStore.resetMessage()">
                    {{ icons.mdiCloseOctagon }}
                  </v-icon>
                </template>
              </v-alert>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-row>
        <v-col cols="12" lg="4" offset-lg="8" class="auth-bg d-flex align-center justify-center">
          <div class="auth-inner pa-4">
            <v-card class="auth-card">
              <!-- logo -->
              <v-card-title class="d-flex align-center justify-center py-7">
                <router-link to="/" class="d-flex align-center">
                  <v-img
                    :src="`${URL_FILE}/images/logo.png`"
                    alt="Logo"
                    max-height="140px"
                    max-width="250px"
                    contain
                    class="me-3"
                  ></v-img>
                </router-link>
              </v-card-title>

              <!-- title -->
              <v-card-text>
                <h5 class="text-h6 font-weight-semibold mb-1">{{ $t('forgetPassword.intro') }} 🔒</h5>
                <p class="mb-4 pt-2">
                  {{ $t('forgetPassword.description') }}
                </p>
              </v-card-text>

              <!-- forget pwd form -->
              <form @submit.prevent="onSubmit" @keydown="form.onKeydown($event)">
                <v-card-text>
                  <v-text-field
                    v-model="form.email"
                    :label="$t('login.form.email')"
                    type="text"
                    outlined
                    dense
                    placeholder="john.doe@example.com"
                    :error-messages="form.errors.get('email')"
                    class="mb-4"
                    autocomplete="off"
                    aria-autocomplete="off"
                  ></v-text-field>

                  <div class="d-flex align-center justify-end flex-wrap mt-4">
                    <router-link to="/login" class="mt-1">
                      {{ $t('forgetPassword.seConnecter') }}
                    </router-link>
                  </div>

                  <ButtonLoader
                    :busy="form.busy"
                    type="submit"
                    block
                    :normal-text="$t('forgetPassword.btnEnvoyer')"
                    :loader-text="$t('common.btnLoaderText')"
                    color="primary"
                    class="mt-10"
                  ></ButtonLoader>
                </v-card-text>
              </form>
            </v-card>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { useAuthStore, useFlashMsgStore } from '@/store'
import { useCommon } from '@/utils/Common'
import { mdiCloseOctagon } from '@mdi/js'

export default {
  setup() {
    const authStore = useAuthStore()
    const flashStore = useFlashMsgStore()
    const commonConfig = useCommon()

    const form = authStore.form
    flashStore.resetMessage()
    const message = flashStore.message
    form.email = null
    form.reset()
    form.clear()

    const onSubmit = () => {
      authStore.forgetPassword()
    }

    return {
      title: 'forgetPassword.pageTitre',
      commonConfig,
      URL_FILE: process.env.VUE_APP_URL_FILE,
      form,
      message,
      flashStore,
      icons: {
        mdiCloseOctagon,
      },
      onSubmit,
    }
  },
  metaInfo() {
    return {
      title: this.$t(this.title),
      titleTemplate: `${this.commonConfig.getTitle()} | %s`,
    }
  },
}
</script>

<style lang="scss">
@import '~@/plugins/vuetify/default-preset/preset/pages/auth.scss';
</style>
