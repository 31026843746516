<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="isDialogVisible" persistent max-width="700px">
        <form @submit.prevent="onSubmit" @keydown="form.onKeydown($event)">
          <v-card>
            <v-card-title>
              <span class="text-h5">Modification du statut</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" lg="12">
                  <v-autocomplete
                    v-model="form.statut"
                    :items="statuts"
                    item-text="nom"
                    item-value="code"
                    label="Statut *"
                    :error-messages="form.errors.get('statut')"
                    :no-data-text="$t('common.autocompleteSearchNoResult')"
                    clearable
                  >
                    <template #item="{ item }">
                      <v-icon :color="item.couleur" class="mr-1"> mdi-circle </v-icon>
                      {{ item.nom }}
                    </template>
                    <template #selection="{ item }">
                      <v-icon :color="item.couleur" class="mr-1"> mdi-circle </v-icon>
                      {{ item.nom }}
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="isDialogVisible = false">
                {{ $t('modal.btnFermer') }}
              </v-btn>

              <v-btn color="primary" class="mr-n2 ml-4" @click="onSubmit">
                {{ $t('modal.btnEnregistrer') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import URL from '@/http/voyage-statut'
import { useSwalAlerte } from '@/utils'
import Form from 'vform'
import { reactive, ref } from 'vue'

export default {
  setup(_props, { emit }) {
    const form = reactive(
      new Form({
        statut: null,
      }),
    )
    const currentId = ref()
    const statuts = ref([])
    const alerte = useSwalAlerte()
    const isDialogVisible = ref(false)

    const requirements = () => {
      window.axios
        .get(URL.REQUIREMENTS)
        .then(resp => {
          statuts.value = resp.data.statuts
          isDialogVisible.value = true
        })
        .catch(() => {})
    }

    const openModal = (id, currentStatutCode) => {
      currentId.value = id
      form.reset()
      form.clear()
      form.statut = currentStatutCode
      requirements()
    }

    const onSubmit = () => {
      isDialogVisible.value = false

      form
        .put(`${URL.VOYAGE_STATUT}/${currentId.value}`)
        .then(resp => {
          onSuccess(resp)
        })
        .catch(() => {
          isDialogVisible.value = true
        })
    }

    const onSuccess = resp => {
      alerte.showSimpleAlerte({ icon: 'success', text: resp.data.message })
      form.reset()
      form.clear()
      emit('on-success')
    }

    return {
      form,
      statuts,
      isDialogVisible,
      onSubmit,
      openModal,
    }
  },
}
</script>
