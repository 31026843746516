import URL from '@/http/sous-trajet'
import i18n from '@/plugins/i18n'
import { useCommon } from '@/utils'
import { defineStore } from 'pinia'
import Form from 'vform'
import { reactive, ref } from 'vue'
const commonConfig = useCommon()

export const useSousTrajetStore = defineStore({
  id: 'sousTrajet',
  state: () => ({
    form: reactive(
      new Form({
        depart: null,
        arrivee: null,
        distance: null,
        duree: null,
        prix: null,
        embarquements: [],
        descentes: [],
      })
    ),
    currentTrajet: null,
    destinations: ref([]),
    departs: ref([]),
    arrivees: ref([]),
    embarquements: ref([]),
    descentes: ref([]),
    columns: [
      {
        text: i18n.t("trajet.table.depart"),
        align: "start",
        sortable: true,
        value: "depart",
      },
      {
        text: i18n.t("trajet.table.arrivee"),
        align: "start",
        sortable: true,
        value: "arrivee",
      },
      {
        text: i18n.t("trajet.table.distance"),
        align: "start",
        sortable: true,
        value: "distance",
      },
      {
        text: i18n.t("trajet.table.duree"),
        align: "start",
        sortable: true,
        value: "duree",
      },
      {
        text: i18n.t("trajet.table.prix"),
        align: "start",
        sortable: true,
        value: "prix",
      },
      {
        text: i18n.t("common.table.action"),
        align: "center",
        sortable: false,
        value: "id",
      },
    ],
    search: '',
    items: ref([]),
    total: 0,
    singleSelect: false,
    rowsSelected: ref([]),
    pageSizes: commonConfig.defaultPageSizes,
    options: {
      itemsPerPage: commonConfig.defaultPageSize,
      page: 1,
      sortBy: ['id'],
      sortDesc: [true],
    },
  }),

  actions: {
    getData({ clearSearch = false }) {
      if (clearSearch) {
        this.search = null
      }

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.rowsSelected = []
      this.items = []

      window.axios.get(URL.SOUS_TRAJET, {
        params: {
          trajetId: this.currentTrajet.id,
          search: this.search,
          sortBy: sortBy.length > 0 ? sortBy[0] : 'id',
          sortDesc: sortDesc.length > 0 ? sortDesc[0] : true,
          page: page,
          perPage: itemsPerPage,
        },
      })
        .then(resp => {
          this.items = resp.data.data
          this.total = itemsPerPage === -1 ? resp.data.length : resp.data.meta.total
        })
        .catch(() => { })
    },

    onChangeDepart(destinationUuid) {
      let arrivees = JSON.parse(JSON.stringify(this.destinations))
      if (destinationUuid === this.form.arrivee) this.form.arrivee = null

      arrivees = window._.filter(arrivees, d => d.uuid !== destinationUuid)
      if (destinationUuid === this.currentTrajet.depart) {
        const obj = window._.find(arrivees, d => d.uuid === this.currentTrajet.arrivee)
        if (obj) Object.assign(obj, { disabled: true })
      }

      this.arrivees = arrivees;
      const obj = window._.find(this.destinations, d => d.uuid === destinationUuid)
      this.embarquements = obj ? obj.embarquement_descentes : []
    },

    onChangeArrivee(destinationUuid) {
      let departs = JSON.parse(JSON.stringify(this.destinations))
      if (destinationUuid === this.form.depart) this.form.depart = null

      departs = window._.filter(departs, d => d.uuid !== destinationUuid)
      if (destinationUuid === this.currentTrajet.arrivee) {
        const obj = window._.find(departs, d => d.uuid === this.currentTrajet.depart)
        if (obj) Object.assign(obj, { disabled: true })
      }

      this.departs = departs;
      const obj = window._.find(this.destinations, d => d.uuid === destinationUuid)
      this.descentes = obj ? obj.embarquement_descentes : []
    },

    get(id, params = { mode: 'show' }) {
      this.reset()
      if (params.mode == 'edit') {
        window.axios
          .all([
            window.axios.get(`${URL.REQUIREMENTS}/${this.currentTrajet.id}`),
            window.axios.get(`${URL.SOUS_TRAJET}/${id}`, {
              params: params
            }),
          ])
          .then(
            window.axios.spread((resp1, resp2) => {
              this.destinations = resp1.data.destinations
              this.departs = this.destinations
              this.arrivees = this.destinations
              this.form = commonConfig.reactiveAssign(this.form, resp2.data.sous_trajet)
            })
          )
          .catch((_err) => { })
      } else {
        window.axios
          .get(`${URL.SOUS_TRAJET}/${id}`, {
            params: params
          })
          .then(resp => {
            this.form = commonConfig.reactiveAssign(this.form, resp.data.sous_trajet)
          })
          .catch((_err) => { })
      }
    },

    requirements() {
      this.reset()
      window.axios
        .get(`${URL.REQUIREMENTS}/${this.currentTrajet.id}`)
        .then(resp => {
          this.destinations = resp.data.destinations
          this.departs = this.destinations
          this.arrivees = this.destinations
        })
        .catch(() => { })
    },

    reset() {
      this.destinations = []
      this.departs = []
      this.arrivees = []
      this.embarquements = []
      this.descentes = []
    },

    async store() {
      return await this.form.post(`${URL.SOUS_TRAJET}/${this.currentTrajet.id}`)
    },

    async update() {
      return await this.form.put(`${URL.SOUS_TRAJET}/${this.form.id}`)
    },

    async delete(ids) {
      return await window.axios.delete(URL.SOUS_TRAJET, {
        params: {
          ids: ids,
        },
      })
    },
  }
})
