
import Create from '@/views/chauffeur/pages/Create.vue';
import Edit from '@/views/chauffeur/pages/Edit.vue';
import List from "@/views/chauffeur/pages/List.vue";
import Show from '@/views/chauffeur/pages/Show.vue';

export default [
  {
    path: '/chauffeur',
    name: 'chauffeur',
    component: List,
    meta: {
      auth: true,
    },
  },
  {
    path: '/chauffeur/create',
    name: 'chauffeur-create',
    component: Create,
    meta: {
      auth: true,
    },
  },
  {
    path: '/chauffeur/edit/:id',
    name: 'chauffeur-edit',
    component: Edit,
    meta: {
      auth: true,
    },
  },
  {
    path: '/chauffeur/show/:id',
    name: 'chauffeur-show',
    component: Show,
    meta: {
      auth: true,
    },
  },
];
