<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="text-h5"> {{ $t('voyageur.apercu.panelTitre') }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="6" lg="3">
                <v-text-field type="text" v-model="form.nom" :label="$t('voyageur.form.nom') + ' *'"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <v-text-field type="text" v-model="form.prenom" :label="$t('voyageur.form.prenom')"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <v-text-field type="text" v-model="form.pays" :label="$t('voyageur.form.pays')"></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <v-text-field
                  type="text"
                  v-model="form.indicatif"
                  :label="$t('voyageur.form.indicatif')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <v-text-field
                  type="text"
                  v-model="form.telephone"
                  :label="$t('voyageur.form.telephone')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <v-switch
                  v-model="form.verifier"
                  :label="form.verifier ? $t('common.switch_compte_verifier') : $t('common.switch_compte_non_verifier')"
                  class="custom-select"
                  inset
                ></v-switch>
              </v-col>
              <v-col cols="12" md="6" lg="3">
                <v-switch
                  v-model="form.statut"
                  :label="form.statut ? $t('common.switch_statut_actif') : $t('common.switch_statut_inactif')"
                  class="custom-select"
                  inset
                ></v-switch>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { useVoyageurStore } from '@/store'

export default {
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useVoyageurStore()
    const form = store.form

    return {
      store,
      form,
    }
  },
}
</script>
