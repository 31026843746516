import moment from 'moment'

export const useDateFormatter = () => {
  const formatDate = (date, filterFormat) => {
    let momentObj = moment(date)

    if (date) {
      return momentObj.format(filterFormat)
    }
    
    return ''
  }


  return {
    formatDate,
  }
}
  