<template>
  <div>
    <div class="mt-4">
      <v-form readonly>
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn color="secondary2" @click.prevent="router.push({ name: 'voyage' }).catch(() => {})">
              {{ $t('voyage.listeVoyages') }}
            </v-btn>

            <v-btn
              color="primary"
              class="ml-4"
              @click.prevent="
                router
                  .push({
                    name: 'voyage-edit',
                    params: { id: route.params.id },
                  })
                  .catch(() => {})
              "
            >
              <v-icon left> mdi-clipboard-edit-outline </v-icon>
              {{ $t('common.btnModifier') }}
            </v-btn>

            <v-btn class="ml-4" color="primary" @click.prevent="router.push({ name: 'voyage-create' }).catch(() => {})">
              <v-icon left> mdi-plus </v-icon>
              {{ $t('voyage.btnAjout') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <Form @open-modal-statut="openModalStatut" />
          </v-col>
        </v-row>
      </v-form>
    </div>
    <ModalStatut ref="modalStatut" @on-success="store.get(route.params.id, { mode: 'show' })" />
  </div>
</template>

<script>
import ModalStatut from '@/components/ModalVoyageStatut.vue'
import { useVoyageStore } from '@/store'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import Form from '../components/form/ShowForm.vue'

export default {
  setup() {
    const store = useVoyageStore()
    const route = useRoute()
    const router = useRouter()
    const form = store.form
    const modalStatut = ref()

    const openModalStatut = (id, currentStatutCode) => {
      modalStatut.value.openModal(id, currentStatutCode)
    }

    return {
      store,
      form,
      router,
      route,
      modalStatut,
      openModalStatut,
    }
  },
  components: {
    Form,
    ModalStatut,
  },
}
</script>
