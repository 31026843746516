import PaiementMode from '@/views/config/paiement-mode/pages/PaiementMode';

export default [
  {
    path: '/config/paiement-mode',
    name: 'config-paiement-mode',
    component: PaiementMode,
    meta: {
      auth: true,
    },
  },
];
