<template>
  <div v-if="form.id">
    <v-row>
      <v-col cols="12" class="mb-n2">
        <BreadCrumd :breadCrumbs="breadCrumbs" :title="$t('reservation.apercu.breadTitre')" />
      </v-col>
    </v-row>
    <div class="mt-4">
      <v-row>
        <v-col cols="12">
          <ResumeVoyage class="mb-4" v-if="store.currentVoyage" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-right">
          <v-btn color="secondary2" class="ml-4" @click.prevent="router.push({ name: 'reservation' }).catch(() => {})">
            {{ $t('reservation.listeReservations') }}
          </v-btn>

          <v-btn
            v-if="form.statut.code !== CONSTANT.RESERVATION_CONFIRME && form.nbre_paiement === 0"
            color="primary"
            class="ml-4"
            @click.prevent="
              router
                .push({
                  name: 'reservation-edit',
                  params: { id: route.params.id },
                })
                .catch(() => {})
            "
          >
            <v-icon left> mdi-clipboard-edit-outline </v-icon>
            {{ $t('common.btnModifier') }}
          </v-btn>

          <v-btn
            color="primary"
            class="ml-4"
            @click.prevent="router.push({ name: 'reservation-create' }).catch(() => {})"
          >
            <v-icon class="mr-1"> mdi-magnify </v-icon>
            {{ $t('reservation.btnRecherche') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-form readonly>
            <ShowForm @open-modal-statut="openModalStatut" />
          </v-form>
        </v-col>
      </v-row>
    </div>
    <ModalStatut ref="modalStatut" @on-success="store.get(route.params.id, { mode: 'show' })" />
  </div>
</template>

<script>
import ModalStatut from '@/components/ModalReservationStatut.vue'
import i18n from '@/plugins/i18n'
import { useReservationStore } from '@/store'
import { useCommon } from '@/utils'
import CONSTANT from '@/utils/Constant'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import ResumeVoyage from '../components/ResumeVoyage.vue'
import ShowForm from '../components/form/ShowForm.vue'

export default {
  setup() {
    const store = useReservationStore()
    const commonConfig = useCommon()
    const route = useRoute()
    const router = useRouter()
    const modalStatut = ref()

    store.currentVoyage = null
    const form = store.form
    form.reset()
    form.clear()

    store.get(route.params.id, { mode: 'show' })

    const openModalStatut = (id, currentStatutCode) => {
      modalStatut.value.openModal(id, currentStatutCode)
    }

    return {
      form,
      store,
      title: 'reservation.apercu.pageTitre',
      commonConfig,
      router,
      route,
      CONSTANT,
      openModalStatut,
      modalStatut,
      breadCrumbs: [
        {
          text: i18n.t('bread.dashboard'),
          disabled: false,
          href: '/',
        },
        {
          text: i18n.t('reservation.apercu.breadActif'),
          disabled: true,
        },
      ],
    }
  },
  metaInfo() {
    return {
      title: this.$t(this.title),
      titleTemplate: `${this.commonConfig.getTitle()} | %s`,
    }
  },
  components: {
    ShowForm,
    ResumeVoyage,
    ModalStatut,
  },
}
</script>
