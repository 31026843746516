<template>
  <div>
    <v-row>
      <v-col cols="12" class="mb-n2">
        <BreadCrumd :breadCrumbs="breadCrumbs" :title="$t('paiement.breadTitre')" />
      </v-col>
      <v-col cols="12" class="text-right">
        <v-btn color="primary" @click="create">
          <v-icon left> mdi-plus </v-icon>
          {{ $t('paiement.btnAjout') }}
        </v-btn>

        <v-btn
          color="error"
          class="ml-4"
          v-if="store.rowsSelected.length"
          @click="onDelete(store.rowsSelected.map(rolw => rolw.id))"
        >
          <v-icon left> mdi-delete </v-icon>
          {{ $t('common.btnSuppMass') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-4">
        <DataTable ref="datatable" @on-show="onShow" @on-edit="onEdit" @on-delete="onDelete" />
      </v-col>
    </v-row>
    <Modal ref="modalPaiementMode" />
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import { usePaiementStore } from '@/store'
import { useCommon, useSwalAlerte } from '@/utils'
import { ref } from 'vue'
import DataTable from '../components/DataTable'
import Modal from '../components/Modal.vue'

export default {
  setup() {
    const store = usePaiementStore()
    const commonConfig = useCommon()
    const alerte = useSwalAlerte()
    const selectedRows = ref([])
    const modalPaiementMode = ref()

    const onSelectRows = ids => {
      selectedRows.value = ids
    }

    const create = () => {
      modalPaiementMode.value.create()
    }

    const onShow = id => {
      modalPaiementMode.value.show(id)
    }

    const onEdit = id => {
      modalPaiementMode.value.edit(id)
    }

    const onDelete = async ids => {
      if (ids.length > 0) {
        const result = await alerte.showConfirm({})
        if (result.value) {
          await store
            .delete(ids)
            .then(resp => {
              alerte.showSimpleAlerte({ icon: 'success', text: resp.data.message })
              store.getData({ clearSearch: true })
            })
            .catch(() => {})
        }
      }
    }

    return {
      store,
      title: 'paiement.pageTitre',
      commonConfig,
      modalPaiementMode,
      isSelect: false,
      breadCrumbs: [
        {
          text: i18n.t('bread.dashboard'),
          disabled: false,
          href: '/',
        },
        {
          text: i18n.t('paiement.breadActif'),
          disabled: true,
        },
      ],
      create,
      onShow,
      onEdit,
      onDelete,
      onSelectRows,
    }
  },
  metaInfo() {
    return {
      title: this.$t(this.title),
      titleTemplate: `${this.commonConfig.getTitle()} | %s`,
    }
  },
  components: {
    DataTable,
    Modal,
  },
}
</script>
