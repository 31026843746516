<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5"> {{ $t('sous_voyage.apercu.panelTitre') }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" md="12" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.reference"
                      :label="$t('voyage.form.reference_s_voyage')"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.date"
                      :label="$t('voyage.form.date')"
                      prepend-icon="mdi-calendar"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.heure_convocation"
                      :label="$t('voyage.form.heure_convocation')"
                      prepend-icon="mdi-clock-time-four-outline"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.heure_depart"
                      :label="$t('voyage.form.heure_depart')"
                      prepend-icon="mdi-clock-time-four-outline"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.depart"
                      :label="$t('trajet.form.depart') + ' *'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.arrivee"
                      :label="$t('trajet.form.arrivee') + ' *'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      :value="`${form.distance} Km`"
                      :label="$t('trajet.form.distance') + ' *'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      :value="`${form.duree} H`"
                      :label="$t('trajet.form.duree') + ' *'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3" v-if="form.devise">
                    <v-text-field
                      type="text"
                      :value="`${currencyFormatter.formatCurrency(form.prix, form.devise)}`"
                      :label="$t('trajet.form.prix') + ' *'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="2">
                    <v-text-field
                      type="text"
                      :value="form.statut.nom"
                      :label="$t('common.table.statut')"
                      readonly
                      @click="$emit('open-modal-statut', form.id, form.statut.code)"
                    >
                      <template #prepend-inner>
                        <v-icon :color="form.statut.couleur"> mdi-circle </v-icon>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5"> {{ $t('trajet.embarquementPanelTitre') }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            <v-autocomplete
              v-model="form.embarquements"
              :items="form.embarquements"
              item-text="nom"
              item-value="nom"
              outlined
              dense
              chips
              :label="$t('trajet.form.point_embarquement') + ' *'"
              multiple
              :no-data-text="$t('common.autocompleteSearchNoResult')"
              append-icon=""
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5"> {{ $t('trajet.descentePanelTitre') }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            <v-autocomplete
              v-model="form.descentes"
              :items="form.descentes"
              item-text="nom"
              item-value="nom"
              outlined
              dense
              chips
              :label="$t('trajet.form.point_descente') + ' *'"
              multiple
              :no-data-text="$t('common.autocompleteSearchNoResult')"
              append-icon=""
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useSousVoyageStore } from '@/store'
import { useCurrencyFormatter } from '@/utils'

export default {
  setup() {
    const store = useSousVoyageStore()
    const currencyFormatter = useCurrencyFormatter()
    const form = store.form

    return {
      store,
      form,
      currencyFormatter,
    }
  },
}
</script>
