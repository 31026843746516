<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="12" lg="4">
          <v-text-field
            type="text"
            v-model="form.nom"
            :error-messages="form.errors.get('nom')"
            :label="$t('chauffeur.form.nom') + ' *'"
            counter
            maxlength="50"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field
            type="text"
            v-model="form.prenom"
            :error-messages="form.errors.get('prenom')"
            :label="$t('chauffeur.form.prenom')"
            counter
            maxlength="50"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-autocomplete
            v-model="form.civilite"
            :items="store.civilites"
            item-text="nom"
            item-value="uuid"
            :label="$t('chauffeur.form.civilite') + ' *'"
            :error-messages="form.errors.get('civilite')"
            :no-data-text="$t('common.autocompleteSearchNoResult')"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-autocomplete
            v-model="form.situation_familiale"
            :items="store.situationFamiliales"
            item-text="nom"
            item-value="uuid"
            :label="$t('chauffeur.form.situation_familiale') + ' *'"
            :error-messages="form.errors.get('situation_familiale')"
            :no-data-text="$t('common.autocompleteSearchNoResult')"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field
            type="email"
            v-model="form.email"
            :error-messages="form.errors.get('email')"
            :label="$t('chauffeur.form.email')"
            maxlength="100"
            counter
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field
            type="text"
            v-model="form.telephone"
            :error-messages="form.errors.get('telephone')"
            :label="$t('chauffeur.form.telephone')"
            maxlength="20"
            counter
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-menu
            v-model="date_naissance_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="computedDateNaissanceFormatted"
                :label="$t('chauffeur.form.date_naissance')"
                clearable
                @click:clear="form.date_naissance = null"
                :error-messages="form.errors.get('date_naissance')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker locale="fr" v-model="form.date_naissance" @input="dateNaissanceHandler" />
          </v-menu>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field
            type="text"
            v-model="form.lieu_naissance"
            :error-messages="form.errors.get('lieu_naissance')"
            :label="$t('chauffeur.form.lieu_naissance')"
            counter
            maxlength="50"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field
            type="text"
            v-model="form.nationalite"
            :error-messages="form.errors.get('nationalite')"
            :label="$t('chauffeur.form.nationalite')"
            counter
            maxlength="50"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field
            type="text"
            v-model="form.num_permis"
            :error-messages="form.errors.get('num_permis')"
            :label="$t('chauffeur.form.num_permis') + ' *'"
            counter
            maxlength="15"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-menu
            v-model="date_expiration_permis_menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="computedExpirationPermisFormatted"
                :label="$t('chauffeur.form.expiration_permis')"
                clearable
                @click:clear="form.expiration_permis = null"
                :error-messages="form.errors.get('expiration_permis')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker locale="fr" v-model="form.expiration_permis" @input="dateExpirationPermisHandler" />
          </v-menu>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-switch
            v-model="form.statut"
            :label="form.statut ? $t('common.switch_statut_actif') : $t('common.switch_statut_inactif')"
            class="custom-select"
            inset
          ></v-switch>
        </v-col>
        <v-col cols="12" md="12" lg="6">
          <v-textarea
            v-model="form.adresse"
            :label="$t('chauffeur.form.adresse')"
            :placeholder="$t('chauffeur.form.adresse')"
            counter
            maxlength="150"
            rows="5"
            clear-icon="mdi-close-circle"
            :error-messages="form.errors.get('adresse')"
            no-resize
          ></v-textarea>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { useChauffeurStore } from '@/store'
import { useDateFormatter } from '@/utils'
import moment from 'moment'
import { computed, ref } from 'vue'

export default {
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useChauffeurStore()
    const formatter = useDateFormatter()
    const date_naissance_menu = ref(false)
    const date_expiration_permis_menu = ref(false)
    const form = store.form

    const dateNaissanceHandler = date => {
      date_naissance_menu.value = false
      if (typeof date === 'string') date = moment(date).format('YYYY-MM-DD')
      form.date_naissance = date
    }

    const computedDateNaissanceFormatted = computed(() => {
      return formatter.formatDate(form.date_naissance, 'DD/MM/YYYY')
    })

    const dateExpirationPermisHandler = date => {
      date_expiration_permis_menu.value = false
      if (typeof date === 'string') date = moment(date).format('YYYY-MM-DD')
      form.expiration_permis = date
    }

    const computedExpirationPermisFormatted = computed(() => {
      return formatter.formatDate(form.expiration_permis, 'DD/MM/YYYY')
    })

    return {
      store,
      form,
      date_naissance_menu,
      date_expiration_permis_menu,
      computedDateNaissanceFormatted,
      computedExpirationPermisFormatted,
      dateNaissanceHandler,
      dateExpirationPermisHandler,
    }
  },
}
</script>
