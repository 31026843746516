import { useAuthStore } from '@/store';
import Login from '@/views/auth/Login';
import PasswordEmail from '@/views/auth/PasswordEmail';
import PasswordReset from '@/views/auth/PasswordReset';
import Profil from '@/views/auth/Profil';
export default [
  {
    path: '/profil',
    name: 'profil',
    component: Profil,
    meta: {
      auth: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      layout: 'blank',
      beforeResolve(_routeTo, _routeFrom, next) {
        const store = useAuthStore()
        // If the user is already logged in
        if (store.isAuthenticated) {
          // Redirect to the home page instead
          next({ name: 'dashboard' });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
    component: Login,
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    meta: {
      layout: 'blank',
      beforeResolve(_routeTo, _routeFrom, next) {
        const store = useAuthStore()

        // If the user is already logged in
        if (store.isAuthenticated) {
          // Redirect to the home page instead
          next({ name: 'dashboard' });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
    component: PasswordEmail,
  },
  {
    path: "/reset-password/:token/:email",
    name: 'reset-password',
    meta: {
      layout: 'blank',
      beforeResolve(_routeTo, _routeFrom, next) {
        const store = useAuthStore()
        // If the user is already logged in
        if (store.isAuthenticated) {
          // Redirect to the home page instead
          next({ name: 'dashboard' });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
    component: PasswordReset,
  },
];
