import Vue from "vue";
import VueI18n from "vue-i18n";
Vue.use(VueI18n);

import fr from './fr.json';

export default new VueI18n({
  locale: 'fr', // set default locale
  fallbackLocale: 'fr', // set default locale
  messages: {
    fr: fr,
  },
  silentTranslationWarn: true,
});
