<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5">
              {{ edit ? $t('agent.modification.panelTitre') : $t('agent.creation.panelTitre') }}</span
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12" lg="4">
                <v-text-field
                  type="text"
                  v-model="form.nom"
                  :error-messages="form.errors.get('nom')"
                  :label="$t('agent.form.nom') + ' *'"
                  counter
                  maxlength="50"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" lg="4">
                <v-text-field
                  type="text"
                  v-model="form.prenom"
                  :error-messages="form.errors.get('prenom')"
                  :label="$t('agent.form.prenom')"
                  counter
                  maxlength="50"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" lg="4">
                <v-text-field
                  type="text"
                  v-model="form.num_cmi_passeport"
                  :error-messages="form.errors.get('num_cmi_passeport')"
                  :label="$t('agent.form.num_cmi_passeport')"
                  counter
                  maxlength="15"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" lg="4">
                <v-text-field
                  type="email"
                  v-model="form.email"
                  :error-messages="form.errors.get('email')"
                  :label="$t('agent.form.email') + ' *'"
                  maxlength="100"
                  counter
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" lg="4">
                <v-text-field
                  type="text"
                  v-model="form.telephone"
                  :error-messages="form.errors.get('telephone')"
                  :label="$t('agent.form.telephone') + ' *'"
                  maxlength="20"
                  counter
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" lg="4">
                <v-select
                  v-model="form.role"
                  :items="store.roles"
                  :label="$t('agent.form.role') + ' *'"
                  item-text="nom"
                  item-value="code"
                  :error-messages="form.errors.get('role')"
                  clearable
                ></v-select>
              </v-col>
              <v-col cols="12" md="12" lg="4">
                <v-switch
                  v-model="form.statut"
                  :label="form.statut ? $t('common.switch_statut_actif') : $t('common.switch_statut_inactif')"
                  class="custom-select"
                  inset
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-8">
      <v-col cols="12">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="12" lg="4">
                <v-text-field
                  v-model="form.password"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :label="$t('agent.form.password')"
                  placeholder="············"
                  :append-icon="isPasswordVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                  @click:append="isPasswordVisible = !isPasswordVisible"
                  :error-messages="form.errors.get('password')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" lg="4">
                <v-text-field
                  v-model="form.password_confirmation"
                  :type="isPasswordConfirmVisible ? 'text' : 'password'"
                  :label="$t('agent.form.password_confirm')"
                  placeholder="············"
                  :append-icon="isPasswordConfirmVisible ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                  @click:append="isPasswordConfirmVisible = !isPasswordConfirmVisible"
                  :error-messages="form.errors.get('password_confirmation')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useAgentStore } from '@/store'
import { ref } from 'vue'

export default {
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useAgentStore()
    const form = store.form

    const isPasswordVisible = ref(false)
    const isPasswordConfirmVisible = ref(false)

    return {
      store,
      form,
      isPasswordVisible,
      isPasswordConfirmVisible,
    }
  },
}
</script>
