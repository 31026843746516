
import Create from '@/views/voyageur/pages/Create.vue';
import Edit from '@/views/voyageur/pages/Edit.vue';
import List from "@/views/voyageur/pages/List.vue";
import Show from '@/views/voyageur/pages/Show.vue';

export default [
  {
    path: '/voyageur',
    name: 'voyageur',
    component: List,
    meta: {
      auth: true,
    },
  },
  {
    path: '/voyageur/create',
    name: 'voyageur-create',
    component: Create,
    meta: {
      auth: true,
    },
  },
  {
    path: '/voyageur/edit/:id',
    name: 'voyageur-edit',
    component: Edit,
    meta: {
      auth: true,
    },
  },
  {
    path: '/voyageur/show/:id',
    name: 'voyageur-show',
    component: Show,
    meta: {
      auth: true,
    },
  },
];
