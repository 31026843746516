<template>
  <div>
    <v-row>
      <v-col cols="12" class="mb-n2">
        <BreadCrumd :breadCrumbs="breadCrumbs" :title="$t('voyage.creation.breadTitre')" />
      </v-col>
    </v-row>
    <div class="mt-4">
      <form @submit.prevent="onSubmit" @keydown="form.onKeydown($event)">
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn color="secondary2" class="mr-4" @click.prevent="router.push({ name: 'voyage' }).catch(() => {})">
              {{ $t('voyage.listeVoyages') }}
            </v-btn>

            <ButtonLoader
              :busy="form.busy"
              type="submit"
              :normal-text="$t('voyage.btnCreer')"
              :loader-text="$t('common.btnLoaderText')"
              color="primary"
              class="custom-submit-btn"
            ></ButtonLoader>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <Form />
          </v-col>
        </v-row>
      </form>
    </div>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import { useVoyageStore } from '@/store'
import { useCommon, useSwalAlerte } from '@/utils'
import { useRoute, useRouter } from 'vue-router/composables'
import Form from '../components/form/EditForm.vue'

export default {
  setup() {
    const store = useVoyageStore()
    const commonConfig = useCommon()
    const alerte = useSwalAlerte()
    const route = useRoute()
    const router = useRouter()

    const form = store.form
    form.reset()
    form.clear()

    store.sousVoyages = []

    const onSubmit = () => {
      form.sous_voyages = store.sousVoyages.map(voyage => {
        const embarquements = voyage.embarquements.map(element => element.embarquement.uuid)
        const descentes = voyage.descentes.map(element => element.descente.uuid)

        return {
          distance: voyage.distance,
          duree: voyage.duree,
          prix: voyage.prix,
          depart: voyage.depart.uuid,
          arrivee: voyage.arrivee.uuid,
          date: voyage.date,
          heure_convocation: voyage.heure_convocation,
          heure_depart: voyage.heure_depart,
          embarquements: embarquements,
          descentes: descentes,
        }
      })

      store
        .store()
        .then(resp => {
          alerte.showSimpleAlerte({ icon: 'success', text: resp.data.message })
          router.push({
            name: 'voyage-show',
            params: { id: resp.data.voyage.id },
          })
        })
        .catch(_err => {})
    }

    if (route.params.trajetId) {
      store.trajetToVoyage(route.params.trajetId)
    } else {
      store.requirements()
    }

    return {
      form,
      store,
      title: 'voyage.creation.pageTitre',
      commonConfig,
      route,
      router,
      breadCrumbs: [
        {
          text: i18n.t('bread.dashboard'),
          disabled: false,
          href: '/',
        },
        {
          text: i18n.t('voyage.creation.breadActif'),
          disabled: true,
        },
      ],
      onSubmit,
    }
  },
  metaInfo() {
    return {
      title: this.$t(this.title),
      titleTemplate: `${this.commonConfig.getTitle()} | %s`,
    }
  },
  components: {
    Form,
  },
}
</script>
