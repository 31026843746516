<template>
  <div>
    <div class="mt-4">
      <form @submit.prevent="onSubmit" @keydown="form.onKeydown($event)">
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn color="secondary2" class="mr-4" @click.prevent="router.push({ name: 'trajet' }).catch(() => {})">
              {{ $t('trajet.listeTrajets') }}
            </v-btn>

            <v-btn color="secondary2" class="mr-4" @click.prevent="router.push({ name: currentRoute }).catch(() => {})">
              {{ $t('sous_trajet.listeSousTrajets') }}
            </v-btn>

            <ButtonLoader
              :busy="form.busy"
              type="submit"
              :normal-text="$t('trajet.btnCreer')"
              :loader-text="$t('common.btnLoaderText')"
              color="primary"
              class="custom-submit-btn"
            ></ButtonLoader>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <Form />
          </v-col>
        </v-row>
      </form>
    </div>
  </div>
</template>

<script>
import { useSousTrajetStore } from '@/store'
import { useSwalAlerte } from '@/utils'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import Form from '../components/form/EditForm.vue'

export default {
  setup() {
    const store = useSousTrajetStore()
    const alerte = useSwalAlerte()
    const route = useRoute()
    const router = useRouter()

    const currentRoute = computed(() => {
      return route.name.split('_')[0]
    })

    const form = store.form
    form.reset()
    form.clear()

    store.requirements()

    const onSubmit = () => {
      store
        .store()
        .then(resp => {
          alerte.showSimpleAlerte({ icon: 'success', text: resp.data.message })
          router.push({ name: currentRoute.value })
        })
        .catch(_err => {})
    }

    return {
      form,
      store,
      currentRoute,
      route,
      router,
      onSubmit,
    }
  },
  components: {
    Form,
  },
}
</script>
