
import Create from '@/views/agent/pages/Create.vue';
import Edit from '@/views/agent/pages/Edit.vue';
import List from "@/views/agent/pages/List.vue";
import Show from '@/views/agent/pages/Show.vue';

export default [
  {
    path: '/agent',
    name: 'agent',
    component: List,
    meta: {
      auth: true,
    },
  },
  {
    path: '/agent/create',
    name: 'agent-create',
    component: Create,
    meta: {
      auth: true,
    },
  },
  {
    path: '/agent/edit/:id',
    name: 'agent-edit',
    component: Edit,
    meta: {
      auth: true,
    },
  },
  {
    path: '/agent/show/:id',
    name: 'agent-show',
    component: Show,
    meta: {
      auth: true,
    },
  },
];
