<template>
  <div>
    <v-row v-if="form.errors.has('sous_voyage_error_message')">
      <v-col cols="12">
        <v-card>
          <v-alert dismissible dense outlined shaped border="left" color="error" elevation="2">
            <p>{{ form.errors.get('sous_voyage_error_message') }}</p>
            <template #close>
              <v-icon size="35" color="error" role="button" aria-hidden="true" @click="clearFormError">
                mdi-close-octagon
              </v-icon>
            </template>
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5">
              {{ edit ? $t('sous_voyage.modification.panelTitre') : $t('sous_voyage.creation.panelTitre') }}</span
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" md="12" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.reference"
                      :label="$t('voyage.form.reference_s_voyage')"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-menu
                      v-model="date_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="computedDateFormatted"
                          :label="$t('voyage.form.date')"
                          clearable
                          @click:clear="form.date = null"
                          :error-messages="form.errors.get('date')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker locale="fr" v-model="form.date" @input="dateHandler" />
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-menu
                      ref="heureConvocationPicker"
                      v-model="heure_convovation_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="form.heure_convocation"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="form.heure_convocation"
                          :label="$t('voyage.form.heure_convocation')"
                          clearable
                          :error-messages="form.errors.get('heure_convocation')"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="heure_convovation_menu"
                        ampm-in-title
                        format="24hr"
                        v-model="form.heure_convocation"
                        full-width
                        @click:minute="$refs.heureConvocationPicker.save(form.heure_convocation)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-menu
                      ref="heureDepartPicker"
                      v-model="heure_depart_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="form.heure_depart"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.heure_depart"
                          :label="$t('voyage.form.heure_depart')"
                          clearable
                          @click:clear="form.heure_depart = null"
                          :error-messages="form.errors.get('heure_depart')"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="heure_depart_menu"
                        ampm-in-title
                        format="24hr"
                        v-model="form.heure_depart"
                        full-width
                        @click:minute="$refs.heureDepartPicker.save(form.heure_depart)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-autocomplete
                      v-model="form.depart"
                      :items="store.departs"
                      item-text="nom"
                      item-value="uuid"
                      :label="$t('trajet.form.depart') + ' *'"
                      :error-messages="form.errors.get('depart')"
                      :no-data-text="$t('common.autocompleteSearchNoResult')"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-autocomplete
                      v-model="form.arrivee"
                      :items="store.arrivees"
                      item-text="nom"
                      item-value="uuid"
                      :label="$t('trajet.form.arrivee') + ' *'"
                      :error-messages="form.errors.get('arrivee')"
                      :no-data-text="$t('common.autocompleteSearchNoResult')"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.distance"
                      :error-messages="form.errors.get('distance')"
                      :label="$t('trajet.form.distance') + ' *'"
                      counter
                      maxlength="15"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.duree"
                      :error-messages="form.errors.get('duree')"
                      :label="$t('trajet.form.duree') + ' *'"
                      counter
                      maxlength="15"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.prix"
                      :error-messages="form.errors.get('prix')"
                      :label="$t('trajet.form.prix') + ' *'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="2" v-if="edit">
                    <v-text-field
                      type="text"
                      :value="form.statut.nom"
                      :label="$t('common.table.statut')"
                      readonly
                      @click="$emit('open-modal-statut', form.id, form.statut.code)"
                    >
                      <template #prepend-inner>
                        <v-icon :color="form.statut.couleur"> mdi-circle </v-icon>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5"> {{ $t('trajet.embarquementPanelTitre') }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            <v-autocomplete
              v-model="form.embarquements"
              :items="store.embarquements"
              item-text="nom"
              item-value="uuid"
              outlined
              dense
              chips
              :label="$t('trajet.form.point_embarquement') + ' *'"
              :error-messages="form.errors.get('embarquements')"
              multiple
              :no-data-text="$t('common.autocompleteSearchNoResult')"
              clearable
              deletable-chips
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5"> {{ $t('trajet.descentePanelTitre') }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            <v-autocomplete
              v-model="form.descentes"
              :items="store.descentes"
              item-text="nom"
              item-value="uuid"
              outlined
              dense
              chips
              :label="$t('trajet.form.point_descente') + ' *'"
              :error-messages="form.errors.get('descentes')"
              multiple
              :no-data-text="$t('common.autocompleteSearchNoResult')"
              clearable
              deletable-chips
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useSousVoyageStore } from '@/store'
import { useDateFormatter } from '@/utils'
import moment from 'moment'
import { computed, ref, watch } from 'vue'

export default {
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useSousVoyageStore()
    const formatter = useDateFormatter()
    const form = store.form
    const date_menu = ref(false)
    const heure_convovation_menu = ref(false)
    const heure_depart_menu = ref(false)

    watch(
      () => store.form.depart,
      (currentValue, _oldValue) => {
        store.onChangeDepart(currentValue)
      },
      { immediate: true },
    )

    watch(
      () => store.form.arrivee,
      (currentValue, _oldValue) => {
        store.onChangeArrivee(currentValue)
      },
      { immediate: true },
    )

    const clearFormError = () => {
      const errors = form.errors
      errors.clear('sous_voyage_error_message')
    }

    const dateHandler = date => {
      date_menu.value = false
      if (typeof date === 'string') date = moment(date).format('YYYY-MM-DD')
      form.date = date
    }

    const computedDateFormatted = computed(() => {
      return formatter.formatDate(form.date, 'DD/MM/YYYY')
    })

    return {
      store,
      form,
      clearFormError,
      dateHandler,
      computedDateFormatted,
      date_menu,
      heure_convovation_menu,
      heure_depart_menu,
    }
  },
}
</script>
