<template>
  <v-menu
    ref="timePicker"
    v-model="time_menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="timeValue"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="timeValue"
        :label="$t('voyage.form.heure_convocation')"
        clearable
        @click:clear="onClear"
        prepend-icon="mdi-clock-time-four-outline"
        readonly
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="time_menu"
      ampm-in-title
      format="24hr"
      v-model="timeValue"
      full-width
      @click:minute="onSave"
    ></v-time-picker>
  </v-menu>
</template>

<script>
import { ref } from 'vue'

export default {
  props: {
    time: {
      type: String | null,
      required: true,
    },
  },
  setup(props, { emit }) {
    const time_menu = ref(false)
    const timePicker = ref()
    const timeValue = ref(props.time)

    const onClear = () => {
      timeValue.value = null
      timePicker.value.save(timeValue.value)
      emit('update:value', timeValue.value)
    }

    const onSave = () => {
      timePicker.value.save(timeValue.value)
      emit('update:value', timeValue.value)
    }

    return {
      time_menu,
      timePicker,
      timeValue,
      onSave,
      onClear,
    }
  },
}
</script>
