<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5">
              {{ edit ? $t('voyage.modification.panelTitre') : $t('voyage.creation.panelTitre') }}</span
            >
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" md="12" lg="3" v-if="edit">
                    <v-text-field
                      type="text"
                      v-model="form.reference"
                      :label="$t('voyage.form.reference_voyage')"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-menu
                      v-model="date_menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :value="computedDateFormatted"
                          :label="$t('voyage.form.date')"
                          clearable
                          @click:clear="form.date = null"
                          :error-messages="form.errors.get('date')"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker locale="fr" v-model="form.date" @input="dateHandler" />
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-menu
                      ref="heureConvocationPicker"
                      v-model="heure_convovation_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="form.heure_convocation"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="form.heure_convocation"
                          :label="$t('voyage.form.heure_convocation')"
                          clearable
                          :error-messages="form.errors.get('heure_convocation')"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="heure_convovation_menu"
                        ampm-in-title
                        format="24hr"
                        v-model="form.heure_convocation"
                        full-width
                        @click:minute="$refs.heureConvocationPicker.save(form.heure_convocation)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-menu
                      ref="heureDepartPicker"
                      v-model="heure_depart_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="form.heure_depart"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="form.heure_depart"
                          :label="$t('voyage.form.heure_depart')"
                          clearable
                          @click:clear="form.heure_depart = null"
                          :error-messages="form.errors.get('heure_depart')"
                          prepend-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="heure_depart_menu"
                        ampm-in-title
                        format="24hr"
                        v-model="form.heure_depart"
                        full-width
                        @click:minute="$refs.heureDepartPicker.save(form.heure_depart)"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-autocomplete
                      v-if="form.nbre_sous_voyages === 0"
                      v-model="form.vehicule"
                      :items="store.vehicules"
                      item-text="immatriculation"
                      item-value="uuid"
                      :label="$t('voyage.form.vehicule') + ' *'"
                      :error-messages="form.errors.get('vehicule')"
                      :no-data-text="$t('common.autocompleteSearchNoResult')"
                      clearable
                    >
                    </v-autocomplete>
                    <v-text-field
                      v-else
                      type="text"
                      v-model="form.vehicule"
                      :label="$t('voyage.form.vehicule') + ' *'"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-autocomplete
                      v-if="form.nbre_sous_voyages === 0 && store.sousVoyages.length === 0"
                      v-model="form.agence"
                      :items="store.agences"
                      item-text="nom"
                      item-value="uuid"
                      :label="$t('voyage.form.agence') + ' *'"
                      :error-messages="form.errors.get('agence')"
                      :no-data-text="$t('common.autocompleteSearchNoResult')"
                      clearable
                      @change="store.onChangeAgence"
                    ></v-autocomplete>
                    <v-text-field
                      v-else
                      type="text"
                      v-model="form.agence_nom"
                      :label="$t('voyage.form.agence') + ' *'"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-autocomplete
                      v-if="form.nbre_sous_voyages === 0 && store.sousVoyages.length === 0"
                      v-model="form.depart"
                      :items="store.departs"
                      item-text="nom"
                      item-value="uuid"
                      :label="$t('voyage.form.depart') + ' *'"
                      :error-messages="form.errors.get('depart')"
                      :no-data-text="$t('common.autocompleteSearchNoResult')"
                      clearable
                    ></v-autocomplete>
                    <v-text-field
                      v-else
                      type="text"
                      v-model="form.depart_nom"
                      :label="$t('voyage.form.depart') + ' *'"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-autocomplete
                      v-if="form.nbre_sous_voyages === 0 && store.sousVoyages.length === 0"
                      v-model="form.arrivee"
                      :items="store.arrivees"
                      item-text="nom"
                      item-value="uuid"
                      :label="$t('voyage.form.arrivee') + ' *'"
                      :error-messages="form.errors.get('arrivee')"
                      :no-data-text="$t('common.autocompleteSearchNoResult')"
                      clearable
                    ></v-autocomplete>
                    <v-text-field
                      v-else
                      type="text"
                      v-model="form.arrivee_nom"
                      :label="$t('voyage.form.arrivee') + ' *'"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.distance"
                      :error-messages="form.errors.get('distance')"
                      :label="$t('voyage.form.distance') + ' *'"
                      counter
                      maxlength="15"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.duree"
                      :error-messages="form.errors.get('duree')"
                      :label="$t('voyage.form.duree') + ' *'"
                      counter
                      maxlength="15"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.prix"
                      :error-messages="form.errors.get('prix')"
                      :label="$t('voyage.form.prix') + ' *'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-autocomplete
                      v-model="form.chauffeurs"
                      :items="store.chauffeurs"
                      item-text="nom"
                      item-value="uuid"
                      chips
                      :label="$t('voyage.form.chauffeurs') + ' *'"
                      :error-messages="form.errors.get('chauffeurs')"
                      multiple
                      :no-data-text="$t('common.autocompleteSearchNoResult')"
                      clearable
                      deletable-chips
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" lg="2" v-if="edit">
                    <v-text-field
                      type="text"
                      :value="form.statut.nom"
                      :label="$t('common.table.statut')"
                      readonly
                      @click="$emit('open-modal-statut', form.id, form.statut.code)"
                    >
                      <template #prepend-inner>
                        <v-icon :color="form.statut.couleur"> mdi-circle </v-icon>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="route.trajetId">
      <v-col cols="12">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5"> {{ $t('voyage.arretPanelTitre') }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            <v-autocomplete
              :readonly="store.sousVoyages.length > 0"
              v-model="form.arrets"
              :items="store.arrets"
              item-text="nom"
              item-value="uuid"
              outlined
              dense
              chips
              :label="$t('voyage.form.point_arret')"
              :error-messages="form.errors.get('arrets')"
              multiple
              :no-data-text="$t('common.autocompleteSearchNoResult')"
              :clearable="!store.sousVoyages.length"
              deletable-chips
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5"> {{ $t('voyage.embarquementPanelTitre') }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            <v-autocomplete
              :readonly="store.sousVoyages.length > 0"
              v-model="form.embarquements"
              :items="store.embarquements"
              item-text="nom"
              item-value="uuid"
              outlined
              dense
              chips
              :label="$t('voyage.form.point_embarquement') + ' *'"
              :error-messages="form.errors.get('embarquements')"
              multiple
              :no-data-text="$t('common.autocompleteSearchNoResult')"
              :clearable="!store.sousVoyages.length"
              deletable-chips
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5"> {{ $t('voyage.descentePanelTitre') }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="mt-4">
            <v-autocomplete
              :readonly="store.sousVoyages.length > 0"
              v-model="form.descentes"
              :items="store.descentes"
              item-text="nom"
              item-value="uuid"
              outlined
              dense
              chips
              :label="$t('voyage.form.point_descente') + ' *'"
              :error-messages="form.errors.get('descentes')"
              multiple
              :no-data-text="$t('common.autocompleteSearchNoResult')"
              :clearable="!store.sousVoyages.length"
              deletable-chips
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <div>
      <v-row v-if="form.errors.has('voyage_error_message')">
        <v-col cols="12">
          <v-card>
            <v-alert dismissible dense outlined shaped border="left" color="error" elevation="2">
              <p>{{ form.errors.get('voyage_error_message') }}</p>
              <template #close>
                <v-icon size="35" color="error" role="button" aria-hidden="true" @click="clearFormError">
                  mdi-close-octagon
                </v-icon>
              </template>
            </v-alert>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card no-body>
            <v-card-title>
              <span class="text-h5"> {{ $t('voyage.sousTrajetPanelTitre') }}</span>
            </v-card-title>
            <v-divider></v-divider>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-n6">
        <v-col cols="12">
          <v-card>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">{{ `${$t('voyage.table.depart')} - ${$t('voyage.table.arrivee')}` }}</th>
                    <th class="text-left">{{ $t('voyage.table.embarquement') }}</th>
                    <th class="text-left">{{ $t('voyage.table.descente') }}</th>
                    <th>{{ $t('voyage.table.distance') }}</th>
                    <th>{{ $t('voyage.table.duree') }}</th>
                    <th>{{ $t('voyage.table.prix') }}</th>
                    <th class="text-center">{{ $t('voyage.table.date') }}</th>
                    <th class="text-center">{{ $t('voyage.table.heure_convocation') }}</th>
                    <th class="text-center">{{ $t('voyage.table.heure_depart') }}</th>
                    <th class="text-center">{{ $t('common.table.action') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in store.sousVoyages" :key="item.id">
                    <td>{{ `${item.depart.nom} - ${item.arrivee.nom}` }}</td>
                    <td>{{ getPointEmbarquement(item.embarquements) }}</td>
                    <td>{{ getPointDescente(item.descentes) }}</td>
                    <td>{{ `${item.distance} Km` }}</td>
                    <td>{{ `${item.duree} H` }}</td>
                    <td>{{ devise ? currencyFormatter.formatCurrency(item.prix, devise) : item.prix }}</td>
                    <td class="text-center justify-center" style="width: 250px">
                      <DatePickerItem :value.sync="item.date" :date="item.date" label="Date" :key="item.id" />
                    </td>
                    <td class="text-center justify-center" style="width: 250px">
                      <TimePickerItem
                        :value.sync="item.heure_convocation"
                        :time="item.heure_convocation"
                        :key="item.id"
                      />
                    </td>
                    <td class="text-center justify-center" style="width: 250px">
                      <TimePickerItem :value.sync="item.heure_depart" :time="item.heure_depart" :key="item.id" />
                    </td>
                    <td class="text-center" style="width: 250px">
                      <a class="cursor-pointer" @click.prevent="removeRow(index)">
                        <v-icon color="error"> mdi-delete </v-icon>
                      </a>
                    </td>
                  </tr>
                  <tr v-if="store.sousVoyages.length === 0">
                    <td colspan="10" class="text-center">{{ $t('voyage.noSousTrajet') }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { useVoyageStore } from '@/store'
import { useCurrencyFormatter, useDateFormatter } from '@/utils'
import moment from 'moment'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router/composables'
import DatePickerItem from '../DatePickerItem.vue'
import TimePickerItem from '../TimePickerItem.vue'
export default {
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useVoyageStore()
    const route = useRoute()
    const formatter = useDateFormatter()
    const date_menu = ref(false)
    const heure_convovation_menu = ref(false)
    const heure_depart_menu = ref(false)
    const currencyFormatter = useCurrencyFormatter()
    const form = store.form

    watch(
      () => [store.form.depart, store.form.arrivee],
      () => {
        store.updatePointArretes()
      },
    )

    watch(
      () => store.form.depart,
      (currentValue, _oldValue) => {
        store.onChangeDepart(currentValue)
      },
      { immediate: true },
    )

    watch(
      () => store.form.arrivee,
      (currentValue, _oldValue) => {
        store.onChangeArrivee(currentValue)
      },
      { immediate: true },
    )

    const dateHandler = date => {
      date_menu.value = false
      if (typeof date === 'string') date = moment(date).format('YYYY-MM-DD')
      form.date = date
    }

    const computedDateFormatted = computed(() => {
      return formatter.formatDate(form.date, 'DD/MM/YYYY')
    })

    const getPointEmbarquement = embarquements => {
      const data = embarquements
        .map(element => {
          return element.embarquement.nom
        })
        .join(',')

      return data
    }

    const getPointDescente = descentes => {
      const data = descentes
        .map(element => {
          return element.descente.nom
        })
        .join(',')

      return data
    }

    const devise = computed(() => {
      const obj = window._.find(store.agences, a => a.uuid === form.agence)
      if (obj) {
        return obj.devise.code
      }
      return null
    })

    const removeRow = index => {
      store.sousVoyages.splice(index, 1)
    }

    const clearFormError = () => {
      const errors = form.errors
      errors.clear('voyage_error_message')
    }

    return {
      store,
      route,
      form,
      computedDateFormatted,
      date_menu,
      heure_convovation_menu,
      heure_depart_menu,
      devise,
      currencyFormatter,
      dateHandler,
      getPointEmbarquement,
      getPointDescente,
      removeRow,
      clearFormError,
    }
  },
  components: {
    TimePickerItem,
    DatePickerItem,
  },
}
</script>
