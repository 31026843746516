
import Create from '@/views/config/vehicule-type/pages/Create.vue';
import Edit from '@/views/config/vehicule-type/pages/Edit.vue';
import List from "@/views/config/vehicule-type/pages/List.vue";
import Show from '@/views/config/vehicule-type/pages/Show.vue';

export default [
  {
    path: '/config/vehicule-type',
    name: 'config-vehicule-type',
    component: List,
    meta: {
      auth: true,
    },
  },
  {
    path: '/config/vehicule-type/create',
    name: 'config-vehicule-type-create',
    component: Create,
    meta: {
      auth: true,
    },
  },
  {
    path: '/config/vehicule-type/edit/:id',
    name: 'config-vehicule-type-edit',
    component: Edit,
    meta: {
      auth: true,
    },
  },
  {
    path: '/config/vehicule-type/show/:id',
    name: 'config-vehicule-type-show',
    component: Show,
    meta: {
      auth: true,
    },
  },
];
