<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5"> {{ $t('vehicule.creation.panelTitre') }}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.immatriculation"
                      :error-messages="form.errors.get('immatriculation')"
                      :label="$t('vehicule.form.immatriculation') + ' *'"
                      counter
                      maxlength="20"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="12" lg="3">
                    <v-autocomplete
                      v-model="form.type"
                      :items="store.types"
                      item-text="nom"
                      item-value="uuid"
                      :label="$t('vehicule.form.vehicule_type') + ' *'"
                      :error-messages="form.errors.get('type')"
                      :no-data-text="$t('common.autocompleteSearchNoResult')"
                      clearable
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.marque"
                      :error-messages="form.errors.get('marque')"
                      :label="$t('vehicule.form.marque')"
                      counter
                      maxlength="50"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.modele"
                      :error-messages="form.errors.get('modele')"
                      :label="$t('vehicule.form.modele')"
                      counter
                      maxlength="50"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.carburant"
                      :error-messages="form.errors.get('carburant')"
                      :label="$t('vehicule.form.carburant')"
                      counter
                      maxlength="50"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.annee"
                      :error-messages="form.errors.get('annee')"
                      :label="$t('vehicule.form.annee')"
                      counter
                      maxlength="4"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-switch
                      v-model="form.statut"
                      :label="form.statut ? $t('common.switch_statut_actif') : $t('common.switch_statut_inactif')"
                      class="custom-select"
                      inset
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { useVehiculeStore } from '@/store'
export default {
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useVehiculeStore()
    const form = store.form

    return {
      store,
      form,
    }
  },
}
</script>
