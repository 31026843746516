import Civilite from '@/views/config/civilite/pages/Civilite';

export default [
  {
    path: '/config/civilite',
    name: 'config-civilite',
    component: Civilite,
    meta: {
      auth: true,
    },
  },
];
