import URL from '@/http/paiement'
import i18n from '@/plugins/i18n'
import { useCommon } from '@/utils'
import moment from 'moment'
import { defineStore } from 'pinia'
import Form from 'vform'
import { reactive, ref } from 'vue'
const commonConfig = useCommon()

export const usePaiementStore = defineStore({
  id: 'paiement',
  state: () => ({
    form: reactive(
      new Form({
        date: moment(new Date()).format('YYYY-MM-DD'),
        montant: null,
        paiement_mode: null,
        reservation_id: null,
      })
    ),
    paiementModes: ref([]),
    reservations: ref([]),
    currentReservation: ref([]),
    columns: [
      {
        text: i18n.t("paiement.table.date"),
        align: "start",
        sortable: true,
        value: "date",
      },
      {
        text: i18n.t("paiement.table.reference"),
        align: "start",
        sortable: true,
        value: "reference",
      },
      {
        text: i18n.t("paiement.table.reservation"),
        align: "start",
        sortable: true,
        value: "reservation",
      },
      {
        text: i18n.t("paiement.table.paiement_mode"),
        align: "start",
        sortable: true,
        value: "paiement_mode",
      },
      {
        text: i18n.t("paiement.table.montant"),
        align: "start",
        sortable: true,
        value: "montant",
      },
      {
        text: i18n.t("common.table.action"),
        align: "center",
        sortable: false,
        value: "id",
      },
    ],
    search: '',
    items: ref([]),
    total: 0,
    singleSelect: false,
    rowsSelected: ref([]),
    pageSizes: commonConfig.defaultPageSizes,
    options: {
      itemsPerPage: commonConfig.defaultPageSize,
      page: 1,
      sortBy: ['id'],
      sortDesc: [true],
    },
  }),

  actions: {
    getData({ clearSearch = false }) {
      if (clearSearch) {
        this.search = null
      }

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.rowsSelected = []
      this.items = []

      window.axios.get(URL.PAIEMENT, {
        params: {
          search: this.search,
          sortBy: sortBy.length > 0 ? sortBy[0] : 'id',
          sortDesc: sortDesc.length > 0 ? sortDesc[0] : true,
          page: page,
          perPage: itemsPerPage,
        },
      })
        .then(resp => {
          this.items = resp.data.data
          this.total = itemsPerPage === -1 ? resp.data.length : resp.data.meta.total
        })
        .catch(() => { })
    },

    async get(id, params = { mode: 'show' }) {
      try {
        this.paiementModes = []
        this.reservations = []
        this.currentReservation = null

        const resp = await window.axios.get(`${URL.PAIEMENT}/${id}`, {
          params: params
        })

        Object.assign(this.form, resp.data.paiement)

        if (params.mode == 'edit') {
          this.paiementModes = this.form.paiement_modes;
          delete this.form.paiement_modes;
        }
      } catch (err) {
        throw err
      }
    },

    async requirements(reservationId = null) {
      try {
        this.paiementModes = []

        if (!reservationId) {
          const resp = await window.axios.get(URL.REQUIREMENTS)
          this.reservations = resp.data.reservations
          return resp

        } else {
          const resp = await window.axios.get(`${URL.REQUIREMENTS}/${reservationId}`)
          this.currentReservation = resp.data.reservation
          this.paiementModes = resp.data.reservation.paiement_modes
          return resp
        }
      } catch (err) {
        this.paiementModes = []
        this.reservations = []
        this.currentReservation = null
        throw err
      }
    },

    async store() {
      return await this.form.post(URL.PAIEMENT)
    },

    async update() {
      return await this.form.put(`${URL.PAIEMENT}/${this.form.id}`)
    },

    async delete(ids) {
      return await window.axios.delete(URL.PAIEMENT, {
        params: {
          ids: ids,
        },
      })
    },
  }
})
