import URL from '@/http/config/destination'
import i18n from '@/plugins/i18n'
import { useCommon } from '@/utils'
import { defineStore } from 'pinia'
import Form from 'vform'
import { reactive, ref } from 'vue'
const commonConfig = useCommon()

export const useConfigDestinationStore = defineStore({
  id: 'configDestination',
  state: () => ({
    form: reactive(
      new Form({
        nom: null,
        pays: null,
        statut: true,
      })
    ),
    pays: ref([]),
    columns: [
      {
        text: i18n.t("config.destination.table.pays"),
        align: "start",
        sortable: true,
        value: "pays",
      },
      {
        text: i18n.t("config.destination.table.nom"),
        align: "start",
        sortable: true,
        value: "nom",
      },
      {
        text: i18n.t("common.table.statut"),
        sortable: false,
        value: "statut",
      },
      {
        text: i18n.t("common.table.action"),
        align: "center",
        sortable: false,
        value: "id",
      },
    ],
    search: '',
    items: ref([]),
    total: 0,
    singleSelect: false,
    rowsSelected: ref([]),
    pageSizes: commonConfig.defaultPageSizes,
    options: {
      itemsPerPage: commonConfig.defaultPageSize,
      page: 1,
      sortBy: ['id'],
      sortDesc: [true],
    },
  }),

  actions: {
    getData({ clearSearch = false }) {
      if (clearSearch) {
        this.search = null
      }

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.rowsSelected = []
      this.items = []

      window.axios.get(URL.DESTINATION, {
        params: {
          search: this.search,
          sortBy: sortBy.length > 0 ? sortBy[0] : 'id',
          sortDesc: sortDesc.length > 0 ? sortDesc[0] : true,
          page: page,
          perPage: itemsPerPage,
        },
      })
        .then(resp => {
          this.items = resp.data.data
          this.total = itemsPerPage === -1 ? resp.data.length : resp.data.meta.total
        })
        .catch(() => { })
    },

    async get(id, params = { mode: 'show' }) {
      try {
        this.pays = []

        if (params.mode == 'edit') {
          const [resp1, resp2] = await window.axios
            .all([
              window.axios.get(`${URL.REQUIREMENTS}`),
              window.axios.get(`${URL.DESTINATION}/${id}`, {
                params: params
              }),
            ]);

          this.pays = resp1.data.pays
          Object.assign(this.form, resp2.data.destination)
        } else {
          const resp = await window.axios.get(`${URL.DESTINATION}/${id}`)
          Object.assign(this.form, resp.data.destination)
        }
      } catch (err) {
        throw err
      }
    },

    async requirements() {
      try {
        const resp = await window.axios.get(URL.REQUIREMENTS)
        this.pays = resp.data.pays

        return resp
      } catch (err) {
        this.pays = []
        throw err
      }
    },

    async store() {
      return await this.form.post(URL.DESTINATION)
    },

    async update() {
      return await this.form.put(`${URL.DESTINATION}/${this.form.id}`)
    },

    async delete(ids) {
      return await window.axios.delete(URL.DESTINATION, {
        params: {
          ids: ids,
        },
      })
    },
  }
})
