import Paiement from '@/views/paiement/pages/Paiement';

export default [
  {
    path: '/paiement',
    name: 'paiement',
    component: Paiement,
    meta: {
      auth: true,
    },
  },
];
