<template>
  <div v-if="form.id">
    <div class="mt-4">
      <v-form readonly>
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn color="secondary2" @click.prevent="router.push({ name: currentRoute }).catch(() => {})">
              {{ $t('sous_trajet.listeSousTrajets') }}
            </v-btn>

            <v-btn
              color="primary"
              class="ml-4"
              @click.prevent="
                router
                  .push({
                    name: `${currentRoute}_edit`,
                    params: { id: route.params.id, sousTrajetId: route.params.sousTrajetId },
                  })
                  .catch(() => {})
              "
            >
              <v-icon left> mdi-clipboard-edit-outline </v-icon>
              {{ $t('common.btnModifier') }}
            </v-btn>

            <v-btn
              class="ml-4"
              color="primary"
              @click.prevent="
                router.push({ name: `${currentRoute}_create`, params: { id: route.params.id } }).catch(() => {})
              "
            >
              <v-icon left> mdi-plus </v-icon>
              {{ $t('sous_trajet.btnAjout') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <Form />
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import { useSousTrajetStore } from '@/store'
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import Form from '../components/form/ShowForm.vue'

export default {
  setup() {
    const store = useSousTrajetStore()
    const route = useRoute()
    const router = useRouter()
    const form = store.form
    form.reset()
    form.clear()

    const currentRoute = computed(() => {
      return route.name.split('_')[0]
    })

    store.get(route.params.sousTrajetId, { mode: 'show' })

    return {
      form,
      router,
      route,
      currentRoute,
    }
  },
  components: {
    Form,
  },
}
</script>
