import Pays from '@/views/config/pays/pages/Pays';

export default [
  {
    path: '/config/pays',
    name: 'config-pays',
    component: Pays,
    meta: {
      auth: true,
    },
  },
];
