<template>
  <v-app>
    <v-data-table
      :headers="store.columns"
      :items="store.items"
      :server-items-length="store.total"
      :options.sync="store.options"
      v-model="store.rowsSelected"
      :single-select="store.singleSelect"
      show-select
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions: store.pageSizes,
      }"
      class="elevation-1"
      fixed-header
    >
      <template v-slot:[`item.statut`]="{ item }">
        <a class="cursor-pointer" @click.prevent="onUpdateStatut(item.id, item.statut.code)">
          <v-icon :color="item.statut.couleur"> mdi-circle </v-icon>
          {{ item.statut.nom }}
        </a>
      </template>
      <template v-slot:[`item.nbre_paiement`]="{ item }">
        <v-icon :color="commonConfig.getStatutClass(item.statut)"> mdi-circle </v-icon>
        {{ item.nbre_paiement > 0 ? `${$t('common.paiement_statut_ok')}` : `${$t('common.paiement_statut_non_ok')}` }}
      </template>
      <template v-slot:[`item.nbre_place`]="{ item }">
        <span class="font-weight-bold">
          {{ item.nbre_place }}
        </span>
      </template>
      <template v-slot:[`item.prix`]="{ item }">
        <span class="font-weight-bold">
          {{ currencyFormtter.formatCurrency(item.prix, item.devise) }}
        </span>
      </template>
      <template v-slot:[`item.montant`]="{ item }">
        <span class="font-weight-bold">
          {{ currencyFormtter.formatCurrency(item.montant, item.devise) }}
        </span>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <router-link
          :title="$t('common.table.tooltipApercu')"
          :to="{
            name: 'reservation-show',
            params: { id: item.id },
          }"
        >
          <v-icon color="primary"> mdi-eye </v-icon>
        </router-link>

        <router-link
          v-if="item.statut.code !== CONSTANT.RESERVATION_CONFIRME && item.nbre_paiement === 0"
          :title="$t('common.table.tooltipModifier')"
          class="ml-4"
          :to="{
            name: 'reservation-edit',
            params: { id: item.id },
          }"
        >
          <v-icon color="primary"> mdi-clipboard-edit-outline </v-icon>
        </router-link>

        <!-- v-if="item.nbre_paiement !== 0" -->

        <a
          :title="$t('common.table.tooltipImprimerTicket')"
          class="ml-4"
          :href="`${store.URL_PRINT_TICKET}/${item.id}`"
          target="_blank"
          rel="noopener noreferrer"
        >
          <v-icon color="primary"> fa-solid fa-cash-register </v-icon>
        </a>

        <a
          v-if="item.nbre_paiement === 0"
          :title="$t('common.table.tooltipPaiement')"
          class="ml-4 cursor-pointer"
          @click.prevent="onPaiement(item.id)"
        >
          <v-icon color="primary"> fa-regular fa-money-bill-1 </v-icon>
        </a>

        <a
          v-if="item.statut.code !== CONSTANT.RESERVATION_CONFIRME && item.nbre_paiement === 0"
          :title="$t('common.table.tooltipSupprimer')"
          class="ml-4 cursor-pointer"
          @click.prevent="onDelete(item.id)"
        >
          <v-icon color="error"> mdi-delete </v-icon>
        </a>
      </template>
      <template v-slot:top>
        <v-text-field
          v-model="store.search"
          append-icon="md-magnify"
          clearable
          :label="$t('common.recherche')"
          single-line
          class="shrink mx-4 mt-4"
          @keyup.enter="store.getData({ clearSearch: false })"
        ></v-text-field>

        <v-divider class="mt-0 mb-0"></v-divider>
      </template>
    </v-data-table>
  </v-app>
</template>
<script>
import { useReservationStore } from '@/store'
import { useCommon, useCurrencyFormatter } from '@/utils'
import CONSTANT from '@/utils/Constant'
import { onMounted, watch } from 'vue'

export default {
  setup(_props, { emit }) {
    const store = useReservationStore()
    const commonConfig = useCommon()
    const currencyFormtter = useCurrencyFormatter()

    watch(
      () => store.options,
      (_currentValue, _oldValue) => {
        store.getData({ clearSearch: false })
      },
    )

    const onDelete = id => {
      emit('on-delete', [id])
    }

    const onPaiement = id => {
      emit('on-paiement', id)
    }

    const onUpdateStatut = (id, statutCode) => {
      emit('open-modal-statut', id, statutCode)
    }

    onMounted(() => {
      store.getData({ clearSearch: true })
    })

    return {
      store,
      commonConfig,
      onDelete,
      onUpdateStatut,
      onPaiement,
      currencyFormtter,
      CONSTANT,
    }
  },
}
</script>
