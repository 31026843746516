import URL from '@/http/reservation'
import i18n from '@/plugins/i18n'
import { useCommon } from '@/utils'
import moment from 'moment'
import { defineStore } from 'pinia'
import Form from 'vform'
import { reactive, ref } from 'vue'
const commonConfig = useCommon()

export const useReservationStore = defineStore({
  id: 'reservation',
  state: () => ({
    form: reactive(
      new Form({
        date: moment(new Date()).format('YYYY-MM-DD'),
        heure: moment(new Date()).format('HH:mm'),
        nom: null,
        telephone: null,
        voyageur: null,
        voyage_id: null,
        envoyer_sms: false,
        places: [],
      })
    ),
    URL_PRINT_TICKET: `${process.env.VUE_APP_URL_FILE}/${URL.PRINT_TICKET}`,
    currentVoyage: ref(null),
    voyageurs: ref([]),
    columns: [
      {
        text: i18n.t("reservation.table.date"),
        align: "start",
        sortable: true,
        value: "date",
      },
      {
        text: i18n.t("reservation.table.reference"),
        align: "start",
        sortable: true,
        value: "reference",
      },
      {
        text: i18n.t("reservation.table.nom"),
        align: "start",
        sortable: true,
        value: "nom",
      },
      {
        text: i18n.t("reservation.table.telephone"),
        align: "start",
        sortable: true,
        value: "telephone",
      },
      {
        text: i18n.t("reservation.table.depart"),
        align: "start",
        sortable: true,
        value: "depart",
      },
      {
        text: i18n.t("reservation.table.arrivee"),
        align: "start",
        sortable: true,
        value: "arrivee",
      },
      {
        text: i18n.t("reservation.table.nbre_places"),
        align: "start",
        sortable: true,
        value: "nbre_place",
      },
      {
        text: i18n.t("reservation.table.prix"),
        align: "start",
        sortable: true,
        value: "prix",
      },
      {
        text: i18n.t("reservation.table.montant"),
        align: "start",
        sortable: true,
        value: "montant",
      },
      {
        text: i18n.t("reservation.table.voyageur"),
        align: "start",
        sortable: true,
        value: "voyageur",
      },
      {
        text: i18n.t("reservation.table.paiement_statut"),
        sortable: false,
        value: "nbre_paiement",
      },
      {
        text: i18n.t("reservation.table.reservation_statut"),
        sortable: false,
        value: "statut",
      },
      {
        text: i18n.t("common.table.action"),
        align: "center",
        sortable: false,
        value: "id",
      },
    ],
    search: '',
    items: ref([]),
    total: 0,
    singleSelect: false,
    rowsSelected: ref([]),
    pageSizes: commonConfig.defaultPageSizes,
    options: {
      itemsPerPage: commonConfig.defaultPageSize,
      page: 1,
      sortBy: ['id'],
      sortDesc: [true],
    },
  }),

  actions: {
    getData({ clearSearch = false }) {
      if (clearSearch) {
        this.search = null
      }

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.rowsSelected = []
      this.items = []

      window.axios.get(URL.RESERVATION, {
        params: {
          search: this.search,
          sortBy: sortBy.length > 0 ? sortBy[0] : 'id',
          sortDesc: sortDesc.length > 0 ? sortDesc[0] : true,
          page: page,
          perPage: itemsPerPage,
        },
      })
        .then(resp => {
          this.items = resp.data.data
          this.total = itemsPerPage === -1 ? resp.data.length : resp.data.meta.total
        })
        .catch(() => { })
    },


    get(id, params = { mode: 'show' }) {
      if (params.mode == 'edit') {
        window.axios
          .all([
            window.axios.get(`${URL.REQUIREMENTS}`),
            window.axios.get(`${URL.RESERVATION}/${id}`, {
              params: params
            }),
          ])
          .then(
            window.axios.spread((resp1, resp2) => {
              this.voyageurs = resp1.data.voyageurs
              this.form = commonConfig.reactiveAssign(this.form, resp2.data.reservation)
              this.currentVoyage = resp2.data.reservation.voyage
              delete this.form.voyage
            })
          )
          .catch((_err) => { })
      } else {
        window.axios
          .get(`${URL.RESERVATION}/${id}`, {
            params: params
          })
          .then(resp => {
            this.form = commonConfig.reactiveAssign(this.form, resp.data.reservation)
            this.currentVoyage = resp.data.reservation.voyage
            delete this.form.voyage
          })
          .catch((_err) => { })
      }
    },

    voyageToReservation(voyageId) {
      window.axios
        .all([
          window.axios.get(`${URL.REQUIREMENTS}`),
          window.axios.get(`${URL.RESERVATION_VOYAGE}/${voyageId}`),
        ])
        .then(
          window.axios.spread((resp1, resp2) => {
            this.voyageurs = resp1.data.voyageurs
            this.currentVoyage = resp2.data.voyage

          })
        )
        .catch((_err) => { })
    },

    requirements() {
      this.voyageurs = [];

      window.axios
        .get(`${URL.REQUIREMENTS}`)
        .then(resp => {
          this.voyageurs = resp.data.voyageurs
        })
        .catch(() => { })
    },

    async store() {
      this.form.voyage_id = this.currentVoyage.id
      return await this.form.post(URL.RESERVATION)
    },

    async update() {
      return await this.form.put(`${URL.RESERVATION}/${this.form.id}`)
    },

    async delete(ids) {
      return await window.axios.delete(URL.RESERVATION, {
        params: {
          ids: ids,
        },
      })
    },
  }
})
