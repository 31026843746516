import URL from '@/http/trajet'
import i18n from '@/plugins/i18n'
import { useSousTrajetStore } from '@/store/sous-trajet.store'
import { useCommon } from '@/utils'
import { defineStore } from 'pinia'
import Form from 'vform'
import { reactive, ref } from 'vue'

const commonConfig = useCommon()
export const useTrajetStore = defineStore({
  id: 'trajet',
  state: () => ({
    form: reactive(
      new Form({
        agence: null,
        depart: null,
        arrivee: null,
        distance: null,
        duree: null,
        prix: null,
        arrets: [],
        embarquements: [],
        descentes: [],
        nbre_sous_trajets: 0
      })
    ),
    destinations: ref([]),
    departs: ref([]),
    arrivees: ref([]),
    agences: ref([]),
    embarquements: ref([]),
    descentes: ref([]),
    arrets: ref([]),
    columns: [
      {
        text: i18n.t("trajet.table.agence"),
        align: "start",
        sortable: true,
        value: "agence",
      },
      {
        text: i18n.t("trajet.table.depart"),
        align: "start",
        sortable: true,
        value: "depart",
      },
      {
        text: i18n.t("trajet.table.arrivee"),
        align: "start",
        sortable: true,
        value: "arrivee",
      },
      {
        text: i18n.t("trajet.table.distance"),
        align: "start",
        sortable: true,
        value: "distance",
      },
      {
        text: i18n.t("trajet.table.duree"),
        align: "start",
        sortable: true,
        value: "duree",
      },
      {
        text: i18n.t("trajet.table.prix"),
        align: "start",
        sortable: true,
        value: "prix",
      },
      {
        text: i18n.t("common.table.action"),
        align: "center",
        sortable: false,
        value: "id",
      },
    ],
    search: '',
    items: ref([]),
    total: 0,
    singleSelect: false,
    rowsSelected: ref([]),
    pageSizes: commonConfig.defaultPageSizes,
    options: {
      itemsPerPage: commonConfig.defaultPageSize,
      page: 1,
      sortBy: ['id'],
      sortDesc: [true],
    },
  }),

  actions: {
    getData({ clearSearch = false }) {
      if (clearSearch) {
        this.search = null
      }

      const { sortBy, sortDesc, page, itemsPerPage } = this.options

      this.rowsSelected = []
      this.items = []

      window.axios.get(URL.TRAJET, {
        params: {
          search: this.search,
          sortBy: sortBy.length > 0 ? sortBy[0] : 'id',
          sortDesc: sortDesc.length > 0 ? sortDesc[0] : true,
          page: page,
          perPage: itemsPerPage,
        },
      })
        .then(resp => {
          this.items = resp.data.data
          this.total = itemsPerPage === -1 ? resp.data.length : resp.data.meta.total
        })
        .catch(() => { })
    },

    onChangeAgence(agenceUuid) {
      this.form.depart = null
      const obj = window._.find(this.agences, a => a.uuid === agenceUuid)
      if (obj) {
        this.onChangeDepart(obj.destination.uuid)
        this.form.depart = obj.destination.uuid
      }
    },

    onChangeDepart(destinationUuid) {
      if (destinationUuid === this.form.arrivee) this.form.arrivee = null
      this.arrivees = window._.filter(this.destinations, d => d.uuid !== destinationUuid)

      const obj = window._.find(this.destinations, d => d.uuid === destinationUuid)
      this.embarquements = obj ? obj.embarquement_descentes : []
    },

    onChangeArrivee(destinationUuid) {
      if (destinationUuid === this.form.depart) this.form.depart = null
      this.departs = window._.filter(this.destinations, d => d.uuid !== destinationUuid)

      const obj = window._.find(this.destinations, d => d.uuid === destinationUuid)
      this.descentes = obj ? obj.embarquement_descentes : []
    },

    updatePointArretes() {
      let arrets = JSON.parse(JSON.stringify(this.destinations))
      if (this.form.depart) {
        this.form.arrets = window._.filter(this.form.arrets, pa => pa !== this.form.depart)
        const obj = window._.find(arrets, pa => pa.uuid === this.form.depart)
        Object.assign(obj, { disabled: true })
      }
      if (this.form.arrivee) {
        this.form.arrets = window._.filter(this.form.arrets, pa => pa !== this.form.arrivee)
        const obj = window._.find(arrets, pa => pa.uuid === this.form.arrivee)
        Object.assign(obj, { disabled: true })
      }

      this.arrets = arrets
    },

    get(id, params = { mode: 'show' }) {
      const sousTrajet = useSousTrajetStore()

      this.reset()
      if (params.mode == 'edit') {
        window.axios
          .all([
            window.axios.get(`${URL.REQUIREMENTS}`),
            window.axios.get(`${URL.TRAJET}/${id}`, {
              params: params
            }),
          ])
          .then(
            window.axios.spread((resp1, resp2) => {
              this.destinations = resp1.data.destinations
              this.departs = this.destinations
              this.arrivees = this.destinations
              this.agences = resp1.data.agences
              this.arrets = JSON.parse(JSON.stringify(this.destinations))

              this.form = commonConfig.reactiveAssign(this.form, resp2.data.trajet)
              sousTrajet.currentTrajet = resp2.data.trajet
            })
          )
          .catch((_err) => { })
      } else {
        window.axios
          .get(`${URL.TRAJET}/${id}`, {
            params: params
          })
          .then(resp => {
            this.form = commonConfig.reactiveAssign(this.form, resp.data.trajet)
            sousTrajet.currentTrajet = resp.data.trajet
          })
          .catch((_err) => { })
      }
    },

    requirements() {
      this.reset()
      window.axios
        .get(`${URL.REQUIREMENTS}`)
        .then(resp => {
          this.destinations = resp.data.destinations
          this.departs = this.destinations
          this.arrivees = this.destinations
          this.agences = resp.data.agences
          this.arrets = JSON.parse(JSON.stringify(this.destinations))
        })
        .catch(() => { })
    },

    reset() {
      this.destinations = []
      this.departs = []
      this.arrivees = []
      this.agences = []
      this.arrets = []
      this.embarquements = []
      this.descentes = []
    },

    async store() {
      return await this.form.post(URL.TRAJET)
    },

    async update() {
      return await this.form.put(`${URL.TRAJET}/${this.form.id}`)
    },

    async delete(ids) {
      return await window.axios.delete(URL.TRAJET, {
        params: {
          ids: ids,
        },
      })
    },
  }
})
