var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.store.columns,"items":_vm.store.items,"server-items-length":_vm.store.total,"options":_vm.store.options,"single-select":_vm.store.singleSelect,"show-select":"","footer-props":{
      showFirstLastPage: true,
      itemsPerPageOptions: _vm.store.pageSizes,
    },"fixed-header":""},on:{"update:options":function($event){return _vm.$set(_vm.store, "options", $event)}},scopedSlots:_vm._u([{key:`item.statut`,fn:function({ item }){return [_c('v-icon',{attrs:{"color":_vm.commonConfig.getStatutClass(item.statut)}},[_vm._v(" mdi-circle ")]),_vm._v(" "+_vm._s(_vm.$t('common.table.statut_' + _vm.commonConfig.getStatutTxt(item.statut)))+" ")]}},{key:`item.distance`,fn:function({ item }){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(`${item.distance} Km`)+" ")])]}},{key:`item.duree`,fn:function({ item }){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(`${item.duree} H`)+" ")])]}},{key:`item.prix`,fn:function({ item }){return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.currencyFormtter.formatCurrency(item.prix, item.devise.code))+" ")])]}},{key:`item.id`,fn:function({ item }){return [_c('router-link',{staticClass:"mr-4",attrs:{"title":_vm.$t('common.table.tooltipTransformTrajetToVoyage'),"to":{
          name: 'voyage-create',
          params: { trajetId: item.id },
        }}},[_c('v-icon',{attrs:{"color":"success","size":"20"}},[_vm._v(" fa-share-square ")])],1),_c('router-link',{staticClass:"mr-4",attrs:{"title":_vm.$t('common.table.tooltipApercu'),"to":{
          name: 'trajet-show',
          params: { id: item.id },
        }}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-eye ")])],1),_c('router-link',{staticClass:"mr-4",attrs:{"title":_vm.$t('common.table.tooltipModifier'),"to":{
          name: 'trajet-edit',
          params: { id: item.id },
        }}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-clipboard-edit-outline ")])],1),_c('a',{staticClass:"cursor-pointer",attrs:{"title":_vm.$t('common.table.tooltipSupprimer')},on:{"click":function($event){$event.preventDefault();return _vm.onDelete(item.id)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-delete ")])],1)]}},{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"shrink mx-4 mt-4",attrs:{"append-icon":"md-magnify","clearable":"","label":_vm.$t('common.recherche'),"single-line":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.store.getData({ clearSearch: false })}},model:{value:(_vm.store.search),callback:function ($$v) {_vm.$set(_vm.store, "search", $$v)},expression:"store.search"}}),_c('v-divider',{staticClass:"mt-0 mb-0"})]},proxy:true}],null,true),model:{value:(_vm.store.rowsSelected),callback:function ($$v) {_vm.$set(_vm.store, "rowsSelected", $$v)},expression:"store.rowsSelected"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }