<template>
  <div>
    <v-row>
      <v-col cols="12" class="mb-n2">
        <BreadCrumd :breadCrumbs="breadCrumbs" :title="$t('chauffeur.creation.breadTitre')" />
      </v-col>
    </v-row>
    <div class="mt-4">
      <form @submit.prevent="onSubmit" @keydown="form.onKeydown($event)">
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn color="secondary2" class="mr-4" @click.prevent="router.push({ name: 'chauffeur' }).catch(() => {})">
              {{ $t('chauffeur.listeChauffeurs') }}
            </v-btn>

            <ButtonLoader
              :busy="form.busy"
              type="submit"
              :normal-text="$t('chauffeur.btnCreer')"
              :loader-text="$t('common.btnLoaderText')"
              color="primary"
              class="custom-submit-btn"
            ></ButtonLoader>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card no-body>
              <v-card-title>
                <span class="text-h5"> {{ $t('chauffeur.creation.panelTitre') }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <Form />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </form>
    </div>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import { useChauffeurStore } from '@/store'
import { useCommon, useSwalAlerte } from '@/utils'
import { onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import Form from '../components/form/EditForm.vue'

export default {
  setup() {
    const store = useChauffeurStore()
    const commonConfig = useCommon()
    const alerte = useSwalAlerte()
    const route = useRoute()
    const router = useRouter()

    const form = store.form
    form.reset()
    form.clear()

    const onSubmit = () => {
      store
        .store()
        .then(resp => {
          alerte.showSimpleAlerte({ icon: 'success', text: resp.data.message })
          router.push({
            name: 'chauffeur-show',
            params: { id: resp.data.chauffeur.id },
          })
        })
        .catch(_err => {})
    }
    onMounted(() => {
      store.requirements()
    })

    return {
      form,
      store,
      title: 'chauffeur.creation.pageTitre',
      commonConfig,
      route,
      router,
      breadCrumbs: [
        {
          text: i18n.t('bread.dashboard'),
          disabled: false,
          href: '/',
        },
        {
          text: i18n.t('chauffeur.creation.breadActif'),
          disabled: true,
        },
      ],
      onSubmit,
    }
  },
  metaInfo() {
    return {
      title: this.$t(this.title),
      titleTemplate: `${this.commonConfig.getTitle()} | %s`,
    }
  },
  components: {
    Form,
  },
}
</script>
