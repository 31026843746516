import PaysIndicatif from '@/views/config/pays-indicatif/pages/PaysIndicatif';

export default [
  {
    path: '/config/pays-indicatif',
    name: 'config-pays-indicatif',
    component: PaysIndicatif,
    meta: {
      auth: true,
    },
  },
];
