<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="isDialogVisible" persistent max-width="1500px">
        <form @submit.prevent="onSubmit" @keydown="form.onKeydown($event)">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ titleDialog }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" lg="4" v-if="mode !== 'create'">
                  <v-text-field
                    type="text"
                    v-model="form.reference"
                    :label="$t('paiement.form.reference')"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" lg="4">
                  <v-menu
                    v-model="date_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                    v-if="mode !== 'show'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="computedDateFormatted"
                        :label="$t('reservation.form.date')"
                        clearable
                        @click:clear="form.date = null"
                        :error-messages="form.errors.get('date')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker locale="fr" v-model="form.date" @input="dateHandler" />
                  </v-menu>
                  <v-text-field
                    v-else
                    type="text"
                    v-model="form.date"
                    :label="$t('paiement.form.date') + ' *'"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" lg="4">
                  <v-autocomplete
                    v-if="mode === 'create'"
                    v-model="form.reservation"
                    :items="store.reservations"
                    item-text="reference"
                    item-value="uuid"
                    :label="$t('paiement.form.reservation') + ' *'"
                    :error-messages="form.errors.get('reservation')"
                    :no-data-text="$t('common.autocompleteSearchNoResult')"
                    @change="onChangeReservation"
                  ></v-autocomplete>
                  <v-text-field
                    v-else
                    type="text"
                    v-model="form.reservation"
                    :label="$t('paiement.form.reservation') + ' *'"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" lg="4">
                  <v-text-field
                    type="text"
                    v-model="form.montant"
                    :error-messages="form.errors.get('montant')"
                    :label="
                      currentDevise
                        ? `${$t('paiement.form.montant')} (${currentDevise}) *`
                        : $t('paiement.form.montant') + ' *'
                    "
                    @focus="$event.target.select()"
                    :readonly="mode === 'show'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" lg="4">
                  <v-autocomplete
                    v-if="mode !== 'show'"
                    v-model="form.paiement_mode"
                    :items="store.paiementModes"
                    item-text="nom"
                    item-value="uuid"
                    :label="$t('paiement.form.paiement_mode') + ' *'"
                    :error-messages="form.errors.get('paiement_mode')"
                    :no-data-text="$t('common.autocompleteSearchNoResult')"
                    clearable
                  ></v-autocomplete>
                  <v-text-field
                    v-else
                    type="text"
                    v-model="form.paiement_mode"
                    :label="$t('paiement.form.paiement_mode') + ' *'"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="isDialogVisible = false">
                {{ $t('modal.btnFermer') }}
              </v-btn>

              <v-btn color="primary" class="mr-n2 ml-4" @click="onSubmit" v-if="mode !== 'show'">
                {{ mode === 'create' ? $t('modal.btnCreer') : $t('modal.btnEnregistrer') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import { usePaiementStore } from '@/store'
import { useDateFormatter, useSwalAlerte } from '@/utils'
import moment from 'moment'
import { computed, ref } from 'vue'

export default {
  setup() {
    const store = usePaiementStore()
    const alerte = useSwalAlerte()
    const formatter = useDateFormatter()
    const date_menu = ref(false)

    const form = store.form
    form.reset()
    form.clear()
    const isDialogVisible = ref(false)
    const titleDialog = ref()
    const currentDevise = ref()
    const mode = ref('create')

    const dateHandler = date => {
      date_menu.value = false
      if (typeof date === 'string') date = moment(date).format('YYYY-MM-DD')
      form.date = date
    }

    const computedDateFormatted = computed(() => {
      return formatter.formatDate(form.date, 'DD/MM/YYYY')
    })

    const create = () => {
      mode.value = 'create'
      titleDialog.value = i18n.t('paiement.titreModalCreation')
      form.reset()
      form.clear()
      store.requirements().then(() => {
        isDialogVisible.value = true
      })
    }

    const show = id => {
      titleDialog.value = i18n.t('paiement.titreModalApercu')
      form.reset()
      form.clear()
      mode.value = 'show'
      store
        .get(id)
        .then(() => {
          currentDevise.value = form.devise_nom
          isDialogVisible.value = true
        })
        .catch(() => {})
    }

    const edit = id => {
      titleDialog.value = i18n.t('paiement.titreModalModification')
      form.reset()
      form.clear()
      mode.value = 'edit'
      store
        .get(id, { mode: 'edit' })
        .then(() => {
          isDialogVisible.value = true
        })
        .catch(() => {})
    }

    const onSubmit = () => {
      isDialogVisible.value = false

      if (form.id) {
        store
          .update()
          .then(resp => {
            onSuccess(resp)
          })
          .catch(() => {
            isDialogVisible.value = true
          })
      } else {
        store
          .store()
          .then(resp => {
            onSuccess(resp)
          })
          .catch(() => {
            isDialogVisible.value = true
          })
      }
    }

    const onSuccess = resp => {
      alerte.showSimpleAlerte({ icon: 'success', text: resp.data.message })
      form.reset()
      form.clear()
      store.getData({ clearSearch: true })
    }

    const onChangeReservation = reservationUuid => {
      form.montant = null
      form.paiement_mode = null
      store.paiementModes = []

      const obj = window._.find(store.reservations, r => r.uuid === reservationUuid)
      if (obj) {
        form.montant = obj.montant
        currentDevise.value = obj.devise_nom
        store.paiementModes = obj.paiement_modes
      }
    }

    return {
      form,
      store,
      mode,
      titleDialog,
      isDialogVisible,
      computedDateFormatted,
      date_menu,
      currentDevise,
      onChangeReservation,
      dateHandler,
      onSubmit,
      create,
      show,
      edit,
    }
  },
}
</script>
