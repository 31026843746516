export default {
  NO_VALUE: '###',
  NULL_VALUE: '###',

  ACTIF: true,
  INACTIF: false,

  TXT_ACTIF: 'actif',
  TXT_INACTIF: 'inactif',

  OUI: true,
  NON: false,

  TXT_OUI: 'oui',
  TXT_NON: 'non',

  CUSTOM_PRIMARY_COLOR: "#536476",
  NO_AVATAR: "images/no-avatar.png",

  ROLE_SUPADMIN: "superadmin",
  ROLE_ADMIN: "admin",
  ROLE_MANAGER: "manager",
  ROLE_OPERATEUR: "operator",

  VOYAGE_ATTENTE: "attente",
  VOYAGE_CONFIRME: "confirme",
  VOYAGE_ANNULE: "annule",

  RESERVATION_ATTENTE: "attente",
  RESERVATION_CONFIRME: "confirme",
  RESERVATION_ANNULE: "annule",
}
