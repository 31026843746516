/** Layouts */
import EditLayout from '@/views/trajet/layouts/EditLayout.vue';
import ShowLayout from '@/views/trajet/layouts/ShowLayout.vue';

import Create from '@/views/sous-trajet/pages/Create.vue';
import Edit from '@/views/sous-trajet/pages/Edit.vue';
import List from "@/views/sous-trajet/pages/List.vue";
import Show from '@/views/sous-trajet/pages/Show.vue';


export default [
  {
    path: '/trajet/edit/:id/sous-trajet',
    component: EditLayout,
    children: [
      {
        path: '',
        name: 'trajet-edit-sous-trajet',
        component: List,
        meta: {
          auth: true,
        },
      },
      {
        path: 'create',
        name: 'trajet-edit-sous-trajet_create',
        component: Create,
        meta: {
          auth: true,
        },
      },
      {
        path: 'edit/:sousTrajetId',
        name: 'trajet-edit-sous-trajet_edit',
        component: Edit,
        meta: {
          auth: true,
        },
      },
      {
        path: 'show/:sousTrajetId',
        name: 'trajet-edit-sous-trajet_show',
        component: Show,
        meta: {
          auth: true,
        },
      },
    ]
  },
  {
    path: '/trajet/show/:id/sous-trajet',
    component: ShowLayout,
    children: [
      {
        path: '',
        name: 'trajet-show-sous-trajet',
        component: List,
        meta: {
          auth: true,
        },
      },
      {
        path: 'create',
        name: 'trajet-show-sous-trajet_create',
        component: Create,
        meta: {
          auth: true,
        },
      },
      {
        path: 'edit/:sousTrajetId',
        name: 'trajet-show-sous-trajet_edit',
        component: Edit,
        meta: {
          auth: true,
        },
      },
      {
        path: 'show/:sousTrajetId',
        name: 'trajet-show-sous-trajet_show',
        component: Show,
        meta: {
          auth: true,
        },
      },
    ]
  }
];
