<template>
  <div>
    <v-row>
      <v-col cols="12" class="mb-n2">
        <BreadCrumd :breadCrumbs="breadCrumbs" :title="$t('vehicule.creation.breadTitre')" />
      </v-col>
    </v-row>
    <div class="mt-4">
      <form @submit.prevent="onSubmit" @keydown="form.onKeydown($event)">
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn color="secondary2" class="mr-4" @click.prevent="router.push({ name: 'vehicule' }).catch(() => {})">
              {{ $t('vehicule.listeVehicules') }}
            </v-btn>

            <ButtonLoader
              :busy="form.busy"
              type="submit"
              :normal-text="$t('vehicule.btnCreer')"
              :loader-text="$t('common.btnLoaderText')"
              color="primary"
              class="custom-submit-btn"
            ></ButtonLoader>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <Form />
          </v-col>
        </v-row>
      </form>
    </div>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import { useVehiculeStore } from '@/store'
import { useCommon, useSwalAlerte } from '@/utils'
import { useRoute, useRouter } from 'vue-router/composables'
import Form from '../components/form/EditForm.vue'

export default {
  setup() {
    const store = useVehiculeStore()
    const commonConfig = useCommon()
    const alerte = useSwalAlerte()
    const route = useRoute()
    const router = useRouter()

    const form = store.form
    form.reset()
    form.clear()

    const onSubmit = () => {
      store
        .store()
        .then(resp => {
          alerte.showSimpleAlerte({ icon: 'success', text: resp.data.message })
          router.push({
            name: 'vehicule-show',
            params: { id: resp.data.vehicule.id },
          })
        })
        .catch(_err => {})
    }

    store.requirements()

    return {
      form,
      store,
      title: 'vehicule.creation.pageTitre',
      commonConfig,
      route,
      router,
      breadCrumbs: [
        {
          text: i18n.t('bread.dashboard'),
          disabled: false,
          href: '/',
        },
        {
          text: i18n.t('vehicule.creation.breadActif'),
          disabled: true,
        },
      ],
      onSubmit,
    }
  },
  metaInfo() {
    return {
      title: this.$t(this.title),
      titleTemplate: `${this.commonConfig.getTitle()} | %s`,
    }
  },
  components: {
    Form,
  },
}
</script>
