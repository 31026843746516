<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="6" lg="3">
          <v-text-field type="text" v-model="form.nom" :label="$t('config.agence.form.nom') + ' *'"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field type="text" v-model="form.pays" :label="$t('config.agence.form.pays') + ' *'"></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            type="text"
            v-model="form.indicatif"
            :label="$t('config.agence.form.indicatif') + ' *'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            type="text"
            v-model="form.destination"
            :label="$t('config.agence.form.destination') + ' *'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            type="text"
            v-model="form.devise"
            :label="$t('config.agence.form.devise') + ' *'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field
            type="text"
            v-model="form.telephone"
            :label="$t('config.agence.form.telephone') + ' *'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-text-field type="email" v-model="form.email" :label="$t('config.agence.form.email')"></v-text-field>
        </v-col>

        <v-col cols="12" md="6" lg="3">
          <v-textarea
            v-model="form.adresse"
            :label="$t('config.agence.form.adresse')"
            :placeholder="$t('config.agence.form.adresse')"
            counter
            maxlength="150"
            rows="5"
            clear-icon="mdi-close-circle"
            no-resize
          ></v-textarea>
        </v-col>
        <v-col cols="12" md="6" lg="3">
          <v-switch
            v-model="form.statut"
            :label="form.statut ? $t('common.switch_statut_actif') : $t('common.switch_statut_inactif')"
            class="custom-select"
            inset
          ></v-switch>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { useConfigAgenceStore } from '@/store'

export default {
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useConfigAgenceStore()

    const form = store.form

    return {
      store,
      form,
    }
  },
}
</script>
