<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="12" md="12" lg="4">
          <v-text-field
            type="text"
            v-model="form.nom"
            :error-messages="form.errors.get('nom')"
            :label="$t('agent.form.nom') + ' *'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field
            type="text"
            v-model="form.prenom"
            :error-messages="form.errors.get('prenom')"
            :label="$t('agent.form.prenom') + ' *'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field
            type="text"
            v-model="form.num_cmi_passeport"
            :error-messages="form.errors.get('num_cmi_passeport')"
            :label="$t('agent.form.num_cmi_passeport')"
            counter
            maxlength="15"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field
            type="email"
            v-model="form.email"
            :error-messages="form.errors.get('email')"
            :label="$t('agent.form.email') + ' *'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field
            type="text"
            v-model="form.telephone"
            :error-messages="form.errors.get('telephone')"
            :label="$t('agent.form.telephone') + ' *'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-text-field
            type="text"
            v-model="form.role"
            :error-messages="form.errors.get('role')"
            :label="$t('agent.form.role') + ' *'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="12" lg="4">
          <v-switch
            v-model="form.statut"
            :label="form.statut ? $t('common.switch_statut_actif') : $t('common.switch_statut_inactif')"
            class="custom-select"
            inset
          ></v-switch>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { useAgentStore } from '@/store'

export default {
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useAgentStore()

    const form = store.form

    return {
      store,
      form,
    }
  },
}
</script>
