import URL from '@/http/auth'
import { useCommon } from '@/utils'
import { defineStore } from 'pinia'
import Form from 'vform'
import { reactive } from 'vue'
import { useFlashMsgStore } from './flash-msg.store'


const commonConfig = useCommon()
export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    isAuthenticated: false,
    user: null,
    token: null,
    urlPhoto: null,
    form: reactive(
      new Form({})
    ),
  }),

  actions: {
    hasRole(roles) {
      let userRole = this.user ? this.user.role_code : null
      return roles.length === 0 ? true : roles.includes(userRole)
    },

    async login() {

      try {
        const resp = await this.form.post(URL.LOGIN)
        this.isAuthenticated = true
        this.user = resp.data.user
        this.token = resp.data.token

        return resp
      } catch (err) {
        this.isAuthenticated = false
        this.user = null
        this.token = null

        throw err
      }
    },

    async forgetPassword() {
      const store = useFlashMsgStore()
      store.resetMessage()
      try {
        const resp = await this.form.post(URL.FORGET_PASSWORD)
        store.updateMessage("success", resp.data.message)
        this.form.reset()
        this.form.clear()
        return resp
      } catch (err) { }
    },

    async resetPassword() {
      const store = useFlashMsgStore()
      store.resetMessage()
      try {
        const resp = await this.form.post(URL.RESET_PASSWORD)
        store.updateMessage("success", resp.data.message)
        this.form.reset()

        return resp
      } catch (err) {
        store.updateMessage("error", err.response.data.message)
        throw err
      }
    },

    async refreshToken() {
      try {
        const resp = await window.axios.get(URL.REFRESH_TOKEN)
        this.isAuthenticated = true
        this.user = resp.data.user
        this.token = resp.data.token

        return resp
      } catch (err) {
        this.isAuthenticated = false
        this.user = null
        this.token = null

        throw err
      }
    },

    async logout() {
      try {
        const resp = await window.axios.get(URL.LOGOUT)
        this.isAuthenticated = false
        this.user = null
        this.token = null

        return resp
      } catch (err) {
        throw err
      }
    },

    getProfil() {
      window.axios
        .get(URL.MY_PROFILE)
        .then(resp => {
          this.form = commonConfig.reactiveAssign(this.form, resp.data.user)
          this.urlPhoto = this.form.url_photo
        })
        .catch((_err) => { })
    },

    async updateProfil() {
      try {
        const resp = await this.form.put(URL.MY_PROFILE)
        this.user = resp.data.user
        return resp

      } catch (err) {
        throw err
      }
    },

  }
})
