<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card no-body>
          <v-card-title>
            <span class="text-h5">
              {{
                edit
                  ? $t('config.vehicule_type.modification.panelTitre')
                  : $t('config.vehicule_type.creation.panelTitre')
              }}
            </span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-row class="d-flex justify-lg-center">
                  <v-col cols="12" md="6" lg="3">
                    <v-text-field
                      type="text"
                      v-model="form.nom"
                      :error-messages="form.errors.get('nom')"
                      :label="$t('config.vehicule_type.form.nom') + ' *'"
                      counter
                      maxlength="50"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" lg="3">
                    <v-file-input
                      show-size
                      @change="onChangeFileChange"
                      v-model="form.fichier"
                      :placeholder="$t('config.vehicule_type.form.fichier')"
                      :label="$t('config.vehicule_type.form.fichier') + ' *'"
                      accept=".svg"
                      prepend-icon="mdi-paperclip"
                      :error-messages="form.errors.get('fichier')"
                    >
                      <template v-slot:selection="{ text }">
                        <v-chip small label color="primary">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col cols="12" md="6" lg="2">
                    <v-switch
                      v-model="form.statut"
                      :label="form.statut ? $t('common.switch_statut_actif') : $t('common.switch_statut_inactif')"
                      class="custom-select"
                      inset
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="form.errors.has('svg_error_message')">
      <v-col cols="12">
        <v-card>
          <v-alert dismissible dense outlined shaped border="left" color="error" elevation="2">
            <p>{{ form.errors.get('svg_error_message') }}</p>
            <template #close>
              <v-icon size="35" color="error" role="button" aria-hidden="true" @click="clearFormError">
                mdi-close-octagon
              </v-icon>
            </template>
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card no-body>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div class="d-flex justify-center align-center" id="svg-container">
                  <div
                    v-if="store.svgContent"
                    v-html="store.svgContent"
                    class="svg-content"
                    id="svg-content"
                    @click="onSvgClick"
                  />
                  <div v-else>
                    <p class="text-h4 error--text">{{ $t('config.vehicule_type.noSeletedFileMsg') }}</p>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <ModalSeatNumber ref="modalSeatNumber" @on-save-number="onSaveNumber" />
  </div>
</template>

<script>
import { useConfigVehiculeTypeStore } from '@/store'
import { ref } from 'vue'
import ModalSeatNumber from '../ModalSeatNumber.vue'

export default {
  props: {
    edit: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const store = useConfigVehiculeTypeStore()
    const form = store.form

    const currentElement = ref(null)
    const modalSeatNumber = ref()

    const onChangeFileChange = file => {
      store.svgContent = null
      if (file && file.type === 'image/svg+xml') {
        const reader = new FileReader()
        reader.onload = e => {
          store.svgContent = e.target.result
        }

        reader.readAsText(file)
      }
    }

    const onSvgClick = e => {
      const clickedElement = e.target
      const parentElement = clickedElement.parentNode
      const hasSeatAttribute = parentElement.hasAttribute('data-seat-number')

      if (hasSeatAttribute) {
        const containerElement = document.getElementById('svg-container')
        const childElements = containerElement.querySelectorAll('g')

        if (parentElement.classList.contains('seat-active')) {
          // deselection
          for (const childElement of childElements) {
            childElement.classList.remove('seat-active')
          }
          currentElement.value = null
        } else {
          for (const childElement of childElements) {
            childElement.classList.remove('seat-active')
          }
          //selection
          parentElement.classList.add('seat-active')
          currentElement.value = parentElement
          const dataSiegeValue = parentElement.getAttribute('data-seat-number')
          modalSeatNumber.value.show(dataSiegeValue)
        }
      }
    }

    const onSaveNumber = value => {
      if (currentElement.value) {
        currentElement.value.setAttribute('data-seat-number', value)
        const childText = currentElement.value.querySelector('text')
        if (childText) {
          childText.textContent = value
        }
        currentElement.value = null
      }
    }

    const clearFormError = () => {
      const errors = form.errors
      errors.clear('svg_error_message')
    }

    return {
      store,
      form,
      currentElement,
      modalSeatNumber,
      clearFormError,
      onChangeFileChange,
      onSvgClick,
      onSaveNumber,
    }
  },
  components: {
    ModalSeatNumber,
  },
}
</script>
