<template>
  <div>
    <v-row justify="center">
      <v-dialog v-model="isDialogVisible" persistent max-width="1500px">
        <form @submit.prevent="onSubmit" @keydown="form.onKeydown($event)">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ titleDialog }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="12" lg="4">
                  <v-menu
                    v-model="date_menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="computedDateFormatted"
                        :label="$t('reservation.form.date')"
                        clearable
                        @click:clear="form.date = null"
                        :error-messages="form.errors.get('date')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker locale="fr" v-model="form.date" @input="dateHandler" />
                  </v-menu>
                </v-col>
                <v-col cols="12" md="12" lg="4">
                  <v-text-field
                    type="text"
                    v-model="store.currentReservation.reference"
                    :label="$t('paiement.form.reservation') + ' *'"
                    :error-messages="form.errors.get('reservation')"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" lg="4">
                  <v-text-field
                    type="text"
                    v-model="form.montant"
                    :error-messages="form.errors.get('montant')"
                    :label="
                      currentDevise
                        ? `${$t('paiement.form.montant')} (${currentDevise}) *`
                        : $t('paiement.form.montant') + ' *'
                    "
                    @focus="$event.target.select()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12" lg="4">
                  <v-autocomplete
                    v-model="form.paiement_mode"
                    :items="store.paiementModes"
                    item-text="nom"
                    item-value="uuid"
                    :label="$t('paiement.form.paiement_mode') + ' *'"
                    :error-messages="form.errors.get('paiement_mode')"
                    :no-data-text="$t('common.autocompleteSearchNoResult')"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" @click="isDialogVisible = false">
                {{ $t('modal.btnFermer') }}
              </v-btn>

              <v-btn color="primary" class="mr-n2 ml-4" @click="onSubmit">
                {{ $t('modal.btnEnregistrer') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n'
import { usePaiementStore } from '@/store'
import { useDateFormatter, useSwalAlerte } from '@/utils'
import moment from 'moment'
import { computed, ref } from 'vue'

export default {
  setup(_props, { emit }) {
    const store = usePaiementStore()
    const alerte = useSwalAlerte()
    const formatter = useDateFormatter()
    const date_menu = ref(false)

    const form = store.form
    form.reset()
    form.clear()
    const isDialogVisible = ref(false)
    const titleDialog = ref()
    const currentDevise = ref()

    const dateHandler = date => {
      date_menu.value = false
      if (typeof date === 'string') date = moment(date).format('YYYY-MM-DD')
      form.date = date
    }

    const computedDateFormatted = computed(() => {
      return formatter.formatDate(form.date, 'DD/MM/YYYY')
    })

    const create = reservationId => {
      titleDialog.value = i18n.t('paiement.titreModalReservationPaiement')
      form.reset()
      form.clear()
      store.requirements(reservationId).then(resp => {
        currentDevise.value = resp.data.reservation.devise_nom
        form.reservation = resp.data.reservation.uuid
        form.montant = resp.data.reservation.montant
        isDialogVisible.value = true
      })
    }

    const onSubmit = () => {
      isDialogVisible.value = false
      store
        .store()
        .then(resp => {
          onSuccess(resp)
        })
        .catch(() => {
          isDialogVisible.value = true
        })
    }

    const onSuccess = resp => {
      alerte.showSimpleAlerte({ icon: 'success', text: resp.data.message })
      form.reset()
      form.clear()
      emit('on-success')
    }

    return {
      form,
      store,
      titleDialog,
      isDialogVisible,
      computedDateFormatted,
      date_menu,
      currentDevise,
      dateHandler,
      onSubmit,
      create,
    }
  },
}
</script>
